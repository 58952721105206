.CustomDropdown {
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
  min-height: 48px;
  padding: 0 8px;
  border: 1px solid var(--border);
  border-radius: 8px;
  background-color: transparent;
  color: var(--text-secondary9);
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  appearance: none;
}
.Prefix {
  margin-right: 4px;
  color: var(--text-primary1);
  font-weight: 600;
}
.IconArrow {
  position: absolute;
  top: 50%;
  right: 20px;
  display: inline-flex;
  color: var(--icon-color);
  transform: translateY(-50%);
}
