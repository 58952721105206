.ContainerPeer {
  position: absolute;
  top: 0;
  right: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  transition: right 0.25s cubic-bezier(0.16, 1, 0.3, 1);
}
