@import '../../../styles/commonStyles.scss';
.WrapperItemControl {
  position: relative;
  display: flex;
  flex: 0 1 auto;
  justify-content: center;
  align-items: baseline;
  min-width: 80px !important;
  padding: 4px 12px;
  text-align: center;
  &:hover {
    border-radius: 8px;
    background: var(--background12);
    cursor: pointer;
  }
}
.wrapperItemControlBadge {
  min-width: 0;
  text-align: left;
}
.ItemControlIcon {
  position: relative;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.IconMain {
  position: relative;
  padding-right: 2px;
}
.ItemControlChildOverLap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
}
.ItemControlIconOverlap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
}
.ItemControlText {
  color: var(--text-secondary);
  font-size: 12px;
  line-height: 18px;
}
.ItemBadegeNumber {
  margin-top: 2px;
  margin-left: 3px;
  color: var(--text-secondary);
  font-weight: 600;
  font-size: 11px;
  line-height: 17px;
}
.IconArrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  border-radius: 16px;
  background: var(--grey5);
  color: var(--icon-color);
  transform: rotate(90deg);
  &:hover {
    border-radius: 16px;
    background: var(--grey5);
    cursor: pointer;
  }
  &:active {
    background: var(--grey19);
  }
}
.IconRecord {
  color: var(--icon-color1);
}
.iconRecordActive {
  color: $status-error;
}

.iconBottomFontActive {
  color: var(--green);
}
