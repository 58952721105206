@import '../../../../styles/commonStyles.scss';
.RecentItemSelected {
  border: 2px solid var(--text-link);
  background: rgba(117, 137, 255, 0.2) !important;
}
.RecentItemWarning {
  border: 2px solid $status-color-warning;
  background: rgba(255, 182, 0, 0.2) !important;
}
.RecentItemPublishing {
  border: 2px solid var(--status-error) !important;
}
.RecentItem {
  position: relative;
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: center;
  height: 64px;
  padding: 16px;
  border-radius: 8px;
  background: var(--grey3);
  cursor: pointer;
  &:hover {
    .BtnMore {
      display: flex;
    }
  }
}
.BtnMoreVisible {
  display: flex !important;
}
.BtnMore {
  display: none;
  justify-content: center;
  align-items: center;
  width: 24px !important;
  height: 24px !important;
  padding: 0 !important;
  border-radius: 50% !important;
  color: var(--icon-color1);
}
.RecentItemMenu {
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  margin-top: 6px;
  margin-right: 6px;
}
.RecentItemMenuVisible {
  display: flex;
}

.RecentItemName {
  overflow: hidden;
  color: var(--text-primary1);
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-word;
}

.DestinationAvatar {
  position: relative;
  width: 32px;
  min-width: 32px;
  height: 32px;
  min-height: 32px;
  border-radius: 50%;
  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
    border-radius: inherit;
  }
  .Loading {
    position: absolute;
    top: 6px;
    left: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}
.SocialIcon {
  position: absolute;
  right: -8px;
  bottom: -8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #ffffff;
  img {
    width: 16px;
    height: 16px;
    border-radius: 0px;
  }
}
.TypeText {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  border-radius: 4px;
  background: #2656c9;
  color: #ffffff;
  font-weight: 500;
  font-size: 10px;
}
.RecentItemNameContainer {
  display: flex;
}
.ItemTooltip {
  z-index: 1200;
}
.WarningIcon {
  position: absolute;
  top: -9px;
  right: -9px;
  // transform: translate(50%, 50%);
  border-radius: 50%;
  background: #ffffff;
}
