@import '../../../styles/commonStyles.scss';

.GraphicContainer {
  padding-right: 0;
  padding-bottom: 80px;
  padding-left: 0;
  composes: RightTabBarContainer from './RightSiderCommon.module.scss';
}
.GraphicHeader {
  padding: 0 24px;
  composes: RightTabBarHeader from './RightSiderCommon.module.scss';
}
.GraphicHeaderTitle {
  composes: RightTabBarHeaderTitle from './RightSiderCommon.module.scss';
}
