.Container {
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 40px;
  border-radius: 40px;
  background: rgba(0, 0, 0, 0.8);
}
.Action {
  display: flex;
  gap: 8px;
  align-items: center;
  height: 100%;
  padding: 0px 12px;
  border-right: 1px solid rgba(255, 255, 255, 0.16);
  &:hover {
    background: rgba(0, 0, 0, 0.8);
    .TextStyleIcon {
      fill: #2656c9 !important;
    }
  }
}
.ActionSelected {
  background: rgba(0, 0, 0, 0.8);
}
.BorderNone {
  border: none;
}
.Input {
  width: 40px;
  margin: 0px 4px;
  padding: 0px !important;
  padding-left: 8px !important;
  border: none !important;
  border-radius: none !important;
  background: transparent !important;
  &:hover {
    border: none !important;
  }
  &:focus-within {
    border: none !important;
    box-shadow: none !important;
    &:hover {
      border: none !important;
    }
  }
}
.SelectColor {
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: center;
}
.ColorSelected {
  width: 14px;
  height: 5px;
  border: 1px solid #ffffff;
  background: rgba(0, 0, 0, 1);
}
.SettingTextItemSelected {
  background: rgba(178, 217, 249, 1);
}

.SettingTextItem {
  display: flex;
  padding: 4px 12px;
  border-radius: 38px;
  &:hover {
    background: rgba(178, 217, 249, 1);
  }
}
.Text {
  color: rgba(225, 225, 233, 1);
  font-size: 13px;
}
.SelectContent {
  width: 240px;
  padding: 8px;
}
.ContentContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 8px;
}
.FontTitle {
  color: #808080;
  font-size: 12px;
}

.ListFont {
  display: flex;
  flex-direction: column;
  padding-left: 8px;
  color: #0f0f0f;
  font-size: 13px;
}

.FontItem {
  display: flex;
  padding: 8px;
  cursor: pointer;
  span {
    flex: 1;
  }
}
.TextStyleIcon {
  fill: rgba(225, 225, 233, 1) !important;
}
.TextStyleIconSelected {
  fill: #2656c9 !important;
}
