@import '../../../styles/commonStyles.scss';
.Container {
  color: var(--text-primary);
  text-align: right;
}
.Time {
  margin-bottom: 4px;
  font-size: 15px;
  font-family: Tahoma;
  line-height: 150%;
}

.TimeZone {
  color: var(--text-secondary);
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}
