.Wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 264px;
  height: 452px;
  padding: 8px 12px;
  border-radius: 8px;
  background: var(--background-qrcode);
}

.Title {
  margin-top: 4px;
  color: var(--text-primary);
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  text-align: center;
}

.SubTitle {
  margin-top: 4px;
  color: var(--text-secondary8);
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  line-height: 150%;
  text-align: center;
}

.QRCode {
  margin-top: 24px;
  border-radius: 8px;
}

.ButtonCopy {
  width: 200px;
  margin-top: 24px;
}

.ButtonToken {
  width: 200px;
  margin-top: 12px;
}

.CopyIcon {
  margin-right: 8px;
}
