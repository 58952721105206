@value ItemChatContainer from './LiveStreamSelectedChat.module.scss';

.LivestreamChatContainer {
  overflow: hidden;
  margin-left: 8px;
  padding: 16px 1px 1px 0;
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    border: 1px solid rgba(255, 255, 255, 0.6);
    border-radius: 40px;
    background: var(--background7);
  }
}

.LiveStreamChatContent {
  margin-right: 5px;
}
