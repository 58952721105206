@import '../../../styles/commonStyles.scss';

.ChangePlanContainer {
  overflow-y: auto;
  width: 100vw;
  height: 100vh;
  padding: 32px 116px !important;
  border-radius: 0px !important;
  background: var(--background);
}

.ChangePlanWrapper {
  border-radius: 0px !important;
}

.Title {
  padding: 32px 0;
  color: var(--text-primary1);
  font-size: $font-size-h3;
}

.ActionBox {
  display: flex;
  justify-content: center;
  align-items: stretch;
}

.Divider {
  margin: 4px 8px;
  border-left: 1px solid var(--border2);
}

.Bdl {
  border-left: 1px solid var(--border7);
}

.Bdr {
  border-right: 1px solid var(--border7);
}

.Bdt {
  border-top: 1px solid var(--border7);
}

.Bdb {
  border-bottom: 1px solid var(--border7);
}

.Row {
  display: flex;
  align-items: stretch;
}

.Flex2 {
  flex: 2 !important;
}

.Flex1 {
  flex: 1 !important;
}

.Cell {
  display: flex;
  flex: 1;
  color: var(--text-secondary3);
}

.VerticalCenter {
  align-items: center;
}

.Center {
  justify-content: center !important;
  align-items: center !important;
}

.bgLv2 {
  background: var(--background);
}

.CellTitle {
  color: var(--text-primary1);
  font-size: $font-size-h4;
}

.Header {
  position: sticky;
  top: -32px;
  min-height: 210px;
  background-color: var(--background3);
}

.Header .Cell {
  align-items: stretch;
}

.FeatureName {
  display: flex;
  align-items: center;
  height: 100px;
  color: var(--text-primary1);
  font-weight: 500;
  font-size: $font-size-h4;
}

.Ph {
  padding: 0 16px;
}

.SpaceBetween {
  justify-content: space-between;
}

.Flex {
  display: flex;
}

.TablePlanInfo {
  margin-top: 32px;
}

.PlanInfo {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 26px 0;
}

.InfoText {
  margin-bottom: 16px;
  padding-right: 24px;
  padding-left: 24px;
}

.Name {
  margin-bottom: 8px;
  color: var(--text-primary1);
  font-size: $font-size-h5;
  text-align: center;
}

.Description {
  color: var(--text-secondary);
  font-size: 12px;
  text-align: center;
  white-space: pre-line;
}

.OldPrice {
  margin-top: 8px;
  color: var(--text-secondary);
  font-size: 12px;
  text-align: center;
  text-decoration: line-through;
  white-space: pre-line;
}

.PriceAction {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.Price {
  position: relative;
  color: var(--text-primary1);
  font-size: $font-size-h3;
}

.PriceMonth {
  margin-left: 4px;
  color: var(--text-secondary);
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
}

.FeatureInfo {
  height: 50px;
}

.Opacity0 {
  opacity: 0;
}

.BtnCurrent {
  opacity: 0.5;
  pointer-events: none;
}

.IconCheck {
  color: var(--text-link);
}

// Fix css
.modelContentTitle {
  position: relative;
  font-weight: 500;
}

.CurrencyType {
  width: 45px !important;
}

.ButtonClose {
  position: absolute;
  top: 38px;
  right: -21px;
  color: var(--text-secondary5);
  cursor: pointer;
}

.AppTab {
  width: max-content !important;

  &.Min {
    :global(.ant-tabs-tab) {
      width: 45px;
      padding: 10px 18px !important;

      :global(.ant-tabs-tab-btn) {
        font-size: 13px;
      }
    }
  }
}

.PanelCurrency {
  width: 45px;
  padding: 10px 18px !important;
}
.IconClose {
  color: var(--text-secondary11);
}
