.Wrapper {
  background: var(--button-grey);
  color: var(--text-primary);

  cursor: pointer;
  &:hover {
    background: var(--button-grey-hover);
  }
  &:active {
    background: var(--button-grey-active);
  }
}
.WrapperNotButton {
  &:hover {
    background: var(--grey8) !important ;
  }
  &:active {
    background: var(--grey8) !important;
  }
}
