.Wrapper {
  position: relative;
}

.Button {
  position: absolute;
  right: 3px;
  bottom: 0px;
  width: 65px;
  height: 65px;
}

.AidaImage {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.Menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  width: 400px;
  height: 560px;
  border-radius: 16px;
  background-color: var(--background-modal);
  box-shadow: 0px 1px 4px 0px rgba(1, 2, 11, 0.24);
}

.MenuHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 16px 16px 0 16px;
  color: var(--text-primary1);
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
}

.IconClose {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: var(--border6);
  cursor: pointer;
}

.PopoverWrapper {
  :global(.ant-popover-inner) {
    border-radius: 16px;
  }
}

.InputText {
  display: flex;
  width: 100%;
  border-top: 1px solid rgba(4, 16, 27, 0.06);
}

.Input {
  flex: 1;
  height: 48px;
  padding: 12px;
  border: none;
  &:hover {
    border: none;
  }
  &:focus {
    border: none;
  }
  &::placeholder {
    color: #808080;
    font-weight: 400;
    font-style: normal;
    font-size: 13px;
  }
}

.SendIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-right: 12px;
  color: #2656c9;
  cursor: pointer;
  &.SendIconDisable {
    opacity: 0.5;
  }
}
