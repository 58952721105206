.popover-custom {
  .ant-popover-content {
    .ant-popover-inner {
      background-color: transparent;
      box-shadow: none;
    }
    .ant-popover-inner-content {
      padding: 0;
    }
  }
  .ant-popover-arrow-content {
    display: none;
  }
}
