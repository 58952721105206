.AlignIndicatorLine {
  position: absolute;
  z-index: 1003;
  background: #ff8ff4;
}
.AlignIndicatorLineIcon {
  position: absolute;
  z-index: 10000;
  display: inline-flex;
  align-items: center;
  color: #ff8ff4;
  transform: translateY(15%) translateX(-10%);
}
