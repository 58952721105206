@import '../../../styles/commonStyles.scss';

.Wrapper {
  position: absolute;
  width: 1920px;
  height: 1080px;
}
.Container {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

.CropContainer {
  position: absolute;
  z-index: 1;
  pointer-events: none;
}

.MediaViewPeerName {
  position: absolute;
  z-index: 500;
  overflow: hidden;
  width: calc(100% - 5px);
  color: $text-level1;
  font-weight: 500;
  line-height: 150%;
  text-shadow:
    0px 0px 2px rgba(0, 0, 0, 0.25),
    0px 0px 8px #000000;
}

.DropArea {
  composes: DropArea from './SlotCommon.module.scss';
}
// .DropAreaRounded {
//   composes: DropAreaRounded from './SlotCommon.module.scss';
// }
.dropAreaOver {
  composes: dropAreaOver from './SlotCommon.module.scss';
}
