.MediaTabBarContainer {
  display: flex;
  justify-content: space-between;
  padding-right: 24px;
  padding-left: 24px;
  border-bottom: 1px solid var(--border);
  :global(.ant-tabs-tab) {
    height: 34px;
  }
}
