.LiveContainer {
  padding-right: 0;
  padding-bottom: 80px;
  padding-left: 0;
  composes: RightTabBarContainer from './RightSiderCommon.module.scss';
}
.LiveHeader {
  padding: 0 24px;
  composes: RightTabBarHeader from './RightSiderCommon.module.scss';
}
.LiveHeaderTitle {
  composes: LiveHeaderTitle from './RightSiderCommon.module.scss';
}
