@import '../../../styles/commonStyles.scss';
.TableWrapper {
  flex: 1;
  &::-webkit-scrollbar {
    height: 0px !important;
  }
  :global(.ant-table) {
    background: transparent;
  }
  :global(.ant-table-thead > tr > th) {
    padding-top: 11px;
    padding-bottom: 11px;
    border-top: 1px solid var(--border);
    border-bottom: 1px solid var(--border);
    background: $background-level4 !important;
    color: var(--text-primary1);
  }
  :global(.ant-table-tbody > tr > td) {
    border-bottom: 1px solid var(--border);
  }
  :global(.ant-table-placeholder) {
    pointer-events: none;
    td {
      color: var(--text-primary1) !important;
    }
  }
  :global(.ant-empty-description) {
    color: var(--text-primary1) !important;
  }
  :global(.ant-table-column-sort) {
    background: transparent;
  }
  :global(.ant-table-tbody > tr.ant-table-row > .ant-table-cell-row-hover) {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.1),
        rgba(255, 255, 255, 0.1)
      ),
      rgba(255, 255, 255, 0.1) !important;
    cursor: pointer;
  }

  :global(.ant-table-row:hover > td) {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.1),
        rgba(255, 255, 255, 0.1)
      ),
      rgba(255, 255, 255, 0.1) !important;
  }

  :global(.ant-table-body) {
    overflow: hidden;
  }
  :global(td.ant-table-column-sort) {
    background: transparent !important;
  }
}
.TableStyled {
  background: transparent;
}
