@import '../../../styles/commonStyles.scss';

.Form {
  display: flex;
  flex-direction: column;
  gap: 24px;

  :global(.ant-form-item-explain-error) {
    margin-bottom: 0 !important;
  }
  :global(.ant-space-item) {
    display: flex;
    align-items: center;
  }
}
