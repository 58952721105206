@import './commonStyles.scss';
.ButtonPrimary {
  display: block;
  height: auto;
  padding: 10px 42px;
  border: none;
  border-radius: 8px;
  background: var(--blue-contained);
  color: var(--blue-on-contained);
  font-weight: 500;
  font-size: $font-size-body1;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
}
.CustomSwitch {
  width: 28px;

  min-width: 28px;
  height: 18px;
  margin: 0 8px;
  box-shadow: none;
  :global(.ant-switch-handle) {
    top: 1px;
    left: 2px;
    width: 14px;
    height: 14px;
    &::before {
      background-color: $text-level1;
    }
  }
}
.customSwitchChecked {
  :global(.ant-switch-handle) {
    left: calc(100% - 14px - 2px);
  }
}
