.LeftActionsWrapper {
  position: absolute;
  left: 18px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  height: 100%;
}
