.Wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 68px;
  padding: 0px 24px 0 24px;
  background-color: #fff;
  box-shadow: 0px 0px 3px 0px rgba(1, 2, 11, 0.24);
}

.Left {
  display: flex;
  align-items: center;
  color: #0f0f0f;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
}

.ArrowLeft {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-right: 24px;
  border-radius: 20px;
  background: #f3f2f2;
  color: #0f0f0f;
  font-size: 16px;
  font-family: 'icomoon';
  cursor: pointer;
}

.Middle {
  display: flex;
  gap: 4px;
  align-items: center;
}

.Item {
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  align-items: center;
  width: 72px;
  height: 52px;
  padding: 4px 12px;
  border-radius: 8px;
  color: #606060;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  &:hover {
    background: linear-gradient(
        0deg,
        rgba(8, 12, 49, 0.04),
        rgba(8, 12, 49, 0.04)
      ),
      #f3f2f2;
  }
}

.ItemSelected {
  background: #c9e6fd;
  opacity: 1;
  cursor: pointer;
  .Icon {
    color: #0e4077;
  }
}

.ItemDisabled {
  opacity: 0.8;
  cursor: not-allowed;
  &:hover {
    background: transparent;
  }
}

.Icon {
  color: #606060;
}

.shareButton {
  padding: 10px 16px;
  font-size: 13px;
}
.OverlayWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border-radius: 8px;
  background: var(--background13);
  box-shadow:
    0px 1px 4px 0px rgba(1, 2, 11, 0.24),
    0px 4px 12px 0px rgba(1, 2, 11, 0.12);
}
.SectionWrapper {
  padding-bottom: 16px;
  border-bottom: 1px solid var(--border5);

  &:last-child {
    padding-bottom: 0;
    border-bottom: 0;
  }
}
.sectionTitleWrapper {
  margin-bottom: 4px;
}
.sectionTitle {
  font-weight: 500;
}
.ContentContainer {
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-left: 8px;
}
.content {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  &:hover {
    background: var(--background-option-hover);
  }
  &:active {
    background: var(--background-option-pressed);
  }
}

.contentDisabled {
  opacity: 0.7;
  cursor: not-allowed;
  &:hover {
    background: transparent;
  }
}

.downloadIcon {
  color: var(--icon-color1);
}

.IconEdit {
  display: inline-flex;
  margin-left: 10px;
  color: var(--text-secondary);
  cursor: pointer;
}
