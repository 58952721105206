@import '../../../styles/commonStyles.scss';
.ChatHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 24px 0px 24px;
}
.ChatHeaderSwitch {
  display: flex;
  gap: 8px;
  align-items: center;
  color: var(--text-secondary3);
  font-size: $font-size-body1;
  line-height: 150%;
  button {
    &:focus {
      box-shadow: none;
    }
  }
}
.ChatWrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}
.ChatContainer {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  scrollbar-width: none;
  p {
    margin-block-start: 0px !important;
    margin-block-end: 0px !important;
  }
}
.ChatContainerContent {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  padding: 24px;
}

.ChatHeaderTitle {
  color: var(--text-primary1);
  font-size: 18px;
}
.FilterContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 24px;
  padding: 0px 24px;
  border-bottom: 1px solid var(--border6);
}
.FilterItem {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 8px;
  justify-content: flex-end;
  align-items: center;
  color: var(--text-primary);
}
.FilterItemSelected {
  margin-bottom: 0px !important;
  color: var(--text-link);
  font-weight: 600;
}
.FilterItemTop {
  display: flex;
  gap: 8px;
  margin-bottom: 12px;
  cursor: pointer;
}
.FilterItemBottom {
  width: 38px;
  height: 4px;
  border-radius: 4px 4px 0px 0px;
  background: var(--blue-contained);
}
.ChatCount {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 25px;
  padding: 0px 4px;
  border-radius: 50%;
  background: var(--green-contained);
  color: var(--text-primary);
  font-weight: 600;
  font-size: 11;
}
