.DropDownClassName {
  //  padding: 5px 0 5px 0;
  padding: 8px 0 8px 0;
  border-radius: 8px;
  background: var(--background41) !important;
  :global(
      .ant-select-item-option-active:not(.ant-select-item-option-disabled)
    ) {
    background-color: var(--background12) !important;
  }
  :global(
      .ant-select-item-option-selected:not(.ant-select-item-option-disabled)
    ) {
    background-color: var(--background12) !important;
  }
  :global(.ant-select-item) {
    padding: 8px 16px 8px 16px;
    color: var(--text-primary1);
    line-height: 100%;
  }
}

.Select {
  :global(.ant-select-selector) {
    align-items: center;
    height: 40px !important;
    border: 1px solid var(--border7) !important;
    border-radius: 8px !important;
    border-radius: 8px;
    background: var(--background2) !important;
    color: var(--text-primary1);
  }
  :global(.ant-select-arrow) {
    color: var(--text-primary1) !important;
  }
}
.ArrowIcon {
  color: var(--text-secondary6);
}

:global(
    .ant-select-disabled.ant-select:not(.ant-select-customize-input)
      .ant-select-selector
  ) {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.05),
      rgba(255, 255, 255, 0.05)
    ),
    rgba(0, 0, 0, 0.2) !important;
  color: var(--grey20);
}
