@import '../../../styles/commonStyles.scss';
.ContainerLogo {
  position: absolute;
  z-index: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: right 0.25s cubic-bezier(0.16, 1, 0.3, 1);
  &:hover {
    .ImageLogoCrop {
      display: flex;
    }
  }
}
.LogoNotParticipant {
  cursor: pointer;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    border: 3px solid $status-success;
  }
  &:hover {
    &::before {
      display: block;
    }
  }
}
.ImageLogo {
  flex: 0 1 auto;
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}
.ImageLogoCrop {
  position: absolute;
  top: -5px;
  right: -5px;
  display: none;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--grey3);
  color: var(--icon-color1);
  cursor: pointer;
}
