@import '../../../styles/commonStyles.scss';
.Transcript {
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 100%;
}
.Divider {
  height: 2px;
  background-color: $border-light-darker4;
  cursor: ns-resize;
}

.HorizontalDivider {
  position: fixed;
  left: 400px;
  width: 4px;
  height: 100%;
  background-color: $border-light-darker4;
  cursor: ew-resize;
}

.Note {
  display: flex;
  flex: 1;
}
.MediaSelected {
  width: 400px;
  height: 230px;
}
.Container {
  display: flex;
  width: 100%;
  height: 100%;
}
