.EditTranscript {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 392px;
  width: 320px;
  padding: 16px;
  border-radius: 8px;
  background-color: var(--background3);
  box-shadow:
    0px 1px 4px 0px rgba(1, 2, 11, 0.24),
    0px 4px 12px 0px rgba(1, 2, 11, 0.12);
}
.Title {
  font-weight: 500;
}
.EditInput {
  width: 100%;
  height: 228px;
  margin-top: 8px;
  padding: 8px;
  border: 1px solid #04101b1f;
  border-radius: 8px;
}
.Buttons {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-top: 8px;
}
