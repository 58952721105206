.Container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 320px;
  padding: 16px;
  border-radius: 8px;
  background-color: var(--background3);
  box-shadow:
    0px 1px 4px 0px rgba(1, 2, 11, 0.24),
    0px 4px 12px 0px rgba(1, 2, 11, 0.12);
}
.Title {
  font-weight: 500;
}
.WrapperReplace {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.RadioGroup {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.divider {
  height: 1px;
  background-color: var(--border7);
}
.Buttons {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-top: 8px;
}
