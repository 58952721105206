.NoteWrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}
.NoteContainer {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  scrollbar-width: none;
}
.NoteHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 24px 0px 24px;
}
.NoteHeaderTitle {
  color: var(--text-primary);
  font-size: 18px;
}
.NoteContent {
  display: flex;
  flex: 1;
  overflow: hidden;
}
