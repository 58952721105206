@import '../../../styles/commonStyles.scss';
.CalendarContainer {
  :global(
      .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
      .ant-picker-cell-in-view.ant-picker-cell-range-start
        .ant-picker-cell-inner,
      .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner
    ) {
    background-color: transparent;
    color: #2fd87c !important;
  }
  :global(.date-item-seleted) {
    background-color: $text-primary !important;
  }
  :global(.date-item) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 28px;
    padding: 5px;
    border-radius: 4px;
    background: var(--border6);
    :global(.date-value) {
      position: relative;
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }

    :global(.date-value-selected::before) {
      content: '';
      position: absolute;
      bottom: 0;
      left: 6px;
      width: 4px;
      height: 4px;
      border-radius: 100%;
      background-color: $basic-color-danger1;
    }
  }

  .today {
    border: 2px solid var(--border-today-cell);
    background: var(--background32);
  }
  :global(.not-in-month) {
    border: 1px solid var(--border8);
    background: var(--background32);
  }

  :global(.ant-picker-calendar .ant-picker-panel) {
    border-top: none;
  }
  :global(.ant-picker-cell .ant-picker-cell-inner) {
    position: initial;
    border-radius: 4px;
  }
  :global(.ant-picker-calendar-mini) {
    background: transparent;
  }
  :global(.ant-picker-panel) {
    background: transparent;
  }
  :global(.ant-picker-calendar-date-value) {
    flex: 1;
    font-size: 13px !important;
  }
  :global(.ant-picker-cell-in-view) {
    color: $basic-color-white !important ;
  }

  :global(.ant-picker-content) {
    height: auto;
    tbody::before {
      content: '';
      display: block;
      height: 4px;
    }
  }
  :global(.ant-picker-body) {
    background: transparent;
    color: $basic-color-white;
  }
  :global(
      .ant-picker-cell-in-view.ant-picker-cell-today
        .ant-picker-cell-inner::before
    ) {
    border: 2px solid rgba(255, 255, 255, 0.24);
    border-radius: 4px;
  }
  :global(.ant-picker-content) {
    th {
      color: var(--text-secondary);
    }
  }
  :global(
      .ant-picker-cell:hover:not(.ant-picker-cell-in-view)
        .ant-picker-cell-inner,
      .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(
          .ant-picker-cell-range-start
        ):not(.ant-picker-cell-range-end):not(
          .ant-picker-cell-range-hover-start
        ):not(.ant-picker-cell-range-hover-end)
        .ant-picker-cell-inner
    ) {
    background: #565656 !important;
  }
}
.CalendarStyled {
  .CalendarHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0px 8px 8px;
    background: transparent;
    color: var(--text-primary1);
  }
  .HeaderRight {
    display: flex;
    gap: 30px;
  }
  :global(.month-style) {
    color: var(--text-secondary9);
    font-weight: 500;
    font-size: 13px;
  }
  :global(.year-style) {
    color: var(--text-secondary9);
    font-weight: 700;
    font-size: 13px;
  }
  .btnNav {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
  }
  .todayBtn {
    display: none;
  }
}

.beamText {
  color: #ffffff;
}

.textToday {
  color: rgba(47, 216, 124, 1);
}

.inMonth {
  color: var(--icon-color1);
}

.notInMonth {
  color: var(--text-secondary4);
}
