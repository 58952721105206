.Volume {
  position: absolute;
  top: -8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 3px;
  border-radius: 7px;
  background: var(--border6);
  pointer-events: none;
}
.Bar {
  position: absolute;
  height: 100%;
  border-radius: 7px;
  transition:
    height 0.25s,
    background-color 0.25s;
}
