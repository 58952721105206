@import '../../../../styles/commonStyles.scss';
.ModalContent {
  border-radius: 8px;
  background: var(--background13);
}
.SelectDestinationWrapper {
  display: flex;
  flex-direction: column;
  width: 570px;
  min-height: 400px;
  max-height: 605px;
  padding: 24px;
  border-radius: 8px;
  background: var(--background-modal);
}

.DesTitle {
  color: var(--text-primary1);
  font-weight: 500;
  font-size: $font-size-h6;
}
.DesHeader {
  display: flex;
  flex-direction: column;
}
.DesRecordCheckTitle {
  color: var(--text-primary) !important;
}
.SwRecord {
  display: flex;
  margin-top: 24px;
}
.SwRecordText {
  margin-left: 8px;
}
.RecentWrapper {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  margin-bottom: 40px;
}
.Recents {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.DesFooter {
  display: flex;
  justify-content: flex-end;
}
.DesContent {
  flex: 1;
  // overflow: auto;
}
.BtnClose {
  display: flex;
  align-items: center;
  padding: 9px;
  padding-right: 20px;
  padding-left: 20px;
  border-color: transparent;
  border-radius: 32px !important;
}
.BtnStart {
  width: 160px;
  margin-left: 8px;
  border-color: transparent;
  border-radius: 32px;
  color: white;
}

.Loading {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
}
