.WarmnessContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.WarmnessSlider {
  height: 120px;
  margin-bottom: 10px;
  :global(.ant-slider-rail) {
    width: 7px;
    border-radius: 6px;
    background: linear-gradient(
      180deg,
      #ffdf8d 0%,
      #ffffff 69.79%,
      #b3edff 100%
    );
  }
  :global(.ant-slider-handle) {
    margin-left: -6px !important;
  }
}

.WarmnessIcon {
  display: flex;
  align-items: center;
  padding: 4px 12px;
  border-radius: 75px;
  color: var(--text-secondary);
  cursor: pointer;
  &:hover {
    background: var(--background-menu);
    color: var(--active-menu) !important;
  }
}

.WarmnessDividerLeft {
  position: absolute;
  top: 99px;
  left: 16px;
  z-index: 2;
  width: 6px;
  height: 1px;
  border: 1px solid var(--border5);
}
.WarmnessDividerRight {
  position: absolute;
  top: 100px;
  right: 13px;
  z-index: 2;
  width: 6px;
  height: 1px;
  border: 1px solid var(--border5);
}
