.ImageEditorWrapper {
  width: 750px;
  padding: none;
}
.Header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #ffffff29;
}
.HeaderTitle {
  flex: 1;
  color: var(--text-primary1);
  font-size: 16px;
}
.Content {
  position: relative;
  width: 100%;
  height: 400px;
  background: '#333';
}
.Actions {
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: flex-end;
  align-items: center;
  padding: 16px;
  border-top: 1px solid #ffffff29;
}
.ActionsItem {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 16;
}
.ActionItemHeader {
  display: flex;
  color: var(--text-primary1);
  font-size: 13px;
}
.ActionsItemText {
  flex: 1;
}
.Footer {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  padding: 16px;
  border-top: 1px solid #ffffff29;
}

.AvatarError {
  position: absolute;
  bottom: 16px;
  left: 16px;
  z-index: 10;
  display: flex;
  justify-content: center;
  margin-top: 72px;
  color: #ff6565;
  font-size: 13px;
}
.CropCom {
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
}
.LoadImage {
  position: absolute;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #2e292999;
}
.ChangeButton {
  flex: 1;
}
