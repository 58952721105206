@import '../../../styles/commonStyles.scss';
.VideoElement {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.ContainerVideo {
  position: absolute;
  top: 0;
  right: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  transition: right 0.25s cubic-bezier(0.16, 1, 0.3, 1);
}
.Loading {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0.6;
}
