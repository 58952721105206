.LineTime {
  width: 100%;
  height: 13px;
  border-radius: 2px;
  background: linear-gradient(
    180deg,
    rgba(223, 227, 231, 0.48) 0%,
    rgba(159, 170, 180, 0.48) 100%
  );
}
.LineTimeBlurSelected {
  background: #c9e6fd;
  outline: 2px solid #0e4077 !important;
}

.LineTimeMuteSelected {
  outline: 2px solid #ea4335 !important;
}

.LineTimeMute {
  background: rgba(234, 67, 53, 0.6);
}
.TimeLineWrapper {
  position: relative;
  width: 100%;
  height: 100%;
}
.TooltipWrapper {
  :global(.ant-tooltip-inner) {
    background-color: var(--background) !important;
  }
}
