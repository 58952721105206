@import '../../../styles/commonStyles.scss';
.Header {
  width: 600px;
  margin: 0px auto 64px auto;
}
.Title {
  margin-bottom: 24px;
  color: var(--text-primary);
  font-size: $font-size-h2;
  text-align: center;
}
.Subtitle {
  color: var(--text-secondary);
  font-size: $font-size-h5;
  text-align: center;
}
.Content {
  width: 100vw;
}
.Row {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: center;
  align-items: center;
}
.BtnSubmit {
  width: 100%;
  height: 48px !important;
}
.Player {
  position: relative;
  overflow: hidden;
  width: 528px;
  height: 297px;
  border-radius: 4px;
  background: var(--background12);
  box-shadow:
    0px 2px 2px rgba(0, 0, 0, 0.25),
    inset 0px -1px 1px rgba(255, 255, 255, 0.1);
}
.NoCam {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  color: var(--text-primary1);
  font-size: 20px;
}
.Control {
  position: absolute;
  bottom: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.Settings {
  @extend .Control;
  right: 16px;
  justify-content: flex-end;
  width: fit-content;
}

.Form {
  width: 468px;
}
.FormItem {
  margin-bottom: 24px;
  & > div {
    border: 1px solid var(--border7);
  }
}
.PopoverNotify {
  width: 402px;
  padding: 16px;
  border-radius: 8px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.1)
    ),
    rgba(23, 23, 29, 0.85);
  /* Dropdown Effect */

  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.4);
}
.PopoverTitle {
  color: var(--text-primary1);
  font-size: $font-size-body4;
}
.PopoverSubtitle {
  color: $text-level2;
  font-size: $font-size-body1;
}
.ControlItem {
  position: relative;
  margin: 0 4px;
}
.PopoverIconButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.8);
  cursor: pointer;
}
.PopoverImgError {
  position: absolute;
  right: -5px;
  bottom: -5px;
  display: inline-flex;
}
.iconButtonDisabled {
  background: rgba(255, 255, 255, 0.08);
  cursor: not-allowed;
  user-select: none;
}
.iconButtonSelected {
  background: #085ae1;
}
