@import '../../../styles/commonStyles.scss';

.AudioPlayerItemLink {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.AudioPlayerTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: var(--text-primary1);
}
.AudioPlayerTopIcon {
  margin-right: 12px;
}
.AudioPlayerName {
  font-size: 13px;
  line-height: 150%;
}
.AudioPlayerMainProgress {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.AudioPlayerProgress {
  width: 80px;
  :global(.ant-slider-rail) {
    background: var(--border);
  }
}
.audioPlayerProgressOnAir {
  width: 65%;
}
.AudioPlayerBottom {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.AudioPlayerVolumeInfo {
  display: flex;
  align-items: center;
}
.AudioPlayerMute {
  z-index: 10;
  margin-right: 10px;
  color: var(--text-primary1);
  cursor: pointer;
}
.AudioPlayerIcon {
  position: relative;
  z-index: 10;
  margin-right: 10px;
  color: var(--text-primary1);
  cursor: pointer;
}
.AudioPlayerVolumeSwitch {
  width: 64px;
  margin: 0;
  :global(.ant-slider-rail) {
    background: var(--border);
  }
}
.AudioPlayerPlay {
  display: inline-flex;
  margin-left: 20px;
  color: var(--text-primary1);
  cursor: pointer;
}
.AudioPlayerCustomAction {
  display: flex;
  align-items: center;
  color: var(--text-secondary3);
}
.AudioPlayerDuration {
  color: var(--text-secondary);
  font-size: 13px;
  line-height: 150%;
}
.AudioPlayerIcon {
  display: inline-flex;
  margin-right: 8px;
  padding: 6px 8px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    border-radius: 50%;
    background-color: var(--background23);
    color: var(--icon-color1);
  }
}
.AudioPlayerRepeat {
  display: inline-flex;
  margin-right: 0;
  padding: 4px 6px;
  background-color: transparent;
  color: var(--text-secondary3);
  cursor: pointer;
  &:hover {
    border-radius: 50%;
    background-color: var(--background23);
    color: var(--icon-color1);
  }
}
.audioPlayerRepeated {
  border-radius: 50%;
  background-color: var(--green-contained) !important;
  color: var(--green-on-contained) !important;
  // &:hover {
  //   background-color: $level-dark1;
  //   color: $status-success;
  // }
}
.ButtonOnAir {
  z-index: 20;
  display: flex;
  gap: 3px;
  align-items: center;
  padding: 2px 6px 2px 8px;
  border-radius: 24px;
  background: var(--red-contained);
  color: var(--red-on-contained);
  font-weight: 500;
}
