@import '../../../styles/commonStyles.scss';
.MediaContainer {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: inherit;
  height: 100%;
  padding-right: 0;
  padding-bottom: 0px !important;
  padding-left: 0;
  composes: RightTabBarContainer from './RightSiderCommon.module.scss';
}
.MediaHeaderFixTop {
  position: relative;
}

.MediaListContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  margin: 0;
  padding-top: 16px;
  padding-bottom: 80px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  &::placeholder,
  &::-webkit-input-placeholder {
    color: var(--text-secondary4);
  }
  &:focus {
    box-shadow: none;
    outline: none;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 4px;
    background-color: var(--scrollbar);
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 40px;
    background: var(--scrollbar-thumb);
  }
}
.MediaListContent {
  display: none;
  flex-wrap: wrap;
  padding-right: 24px;
  padding-left: 24px;
}
.mediaListContentSelected {
  display: flex;
}

.HideTabBarMedia {
  position: absolute;
  z-index: -100;
  width: 100%;
}
