@import '../../../styles/commonStyles.scss';

.RightTabBarContainer {
  padding: 24px;
}

.RightTabBarTitle {
  color: var(--text-secondary);
  font-weight: 400;
  font-size: 10px;
  line-height: 150%;
  text-transform: capitalize;
}
.RightTabBarHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--text-primary1);
  font-size: 18px;
}
.RightTabBarHeaderTitle {
  color: var(--text-primary1);
  font-size: 18px;
  line-height: 24px;
}

.SwitchModeList img {
  cursor: pointer;
}
.SettingModePopover {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px 0px;
  border-radius: 8px;
}
.SettingModeTitle {
  width: 240px;
  padding: 0px 16px;
  font-weight: 500;
  font-size: 14px;
}
.settingModeItem {
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 0px 16px 0px 32px;
  font-size: 13px;
  cursor: pointer;
}
.ChatBadge {
  position: absolute;
  top: 16px;
  right: 13px;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 23px;
  height: 17px;
  padding: 0px 4px;
  border: 2px solid #ffffff;
  border-radius: 24px;
  background: var(--green-contained);
  color: var(--text-primary) !important;
  font-weight: 600;
  font-size: 11px;
}
// fix css
.rightTabBarList {
  flex: 1;
  width: 100%;
}
