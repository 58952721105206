@import '../../../styles/commonStyles.scss';
.MediaImageIconGIF {
  position: absolute;
  top: 0px;
  right: 5px;
  color: $text-level1;
}
.MediaImageItemContainer {
  display: flex;
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  composes: MediaItemHorizontalContainer from './MediaCommon.module.scss';
  &:hover {
    background-color: rgba(4, 16, 27, 0.04);
  }
}
.mediaItemHorizontalContainerDrophover {
  composes: mediaItemHorizontalContainerDrophover from './MediaCommon.module.scss';
}

.mediaItemHorizontalContainerDropbottom {
  composes: mediaItemHorizontalContainerDropbottom from './MediaCommon.module.scss';
}
.mediaItemHorizontalContainerDragSelected {
  composes: mediaItemHorizontalContainerDragSelected from './MediaCommon.module.scss';
}
.mediaItemWrapperOnAir {
  composes: mediaItemWrapperOnAir from './MediaCommon.module.scss';
}
.mediaItemWrapperDragging {
  composes: mediaItemWrapperDragging from './MediaCommon.module.scss';
}
.MediaImageTag {
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
  margin: auto;
  border-radius: 8px;
  background: var(--background10);
}
.LoadingSocial {
  composes: LoadingSocial from './MediaCommon.module.scss';
}
.MediaImageButtonOnAir {
  //  composes: MediaButtonOnAir from './MediaCommon.module.scss';

  position: absolute;
  top: 12px;
  left: 15px;
  z-index: 20;
  display: flex;
  align-items: center;
  padding: 1px 8px;
  border-radius: 14px;
  background: var(--red-contained);
  color: var(--red-on-contained);
  font-weight: 500;
  font-size: 12px;
}
.MediaImageDragging {
  border-radius: 8px;
  background: var(--grey14) !important;
  composes: MediaItemDragging from './MediaCommon.module.scss';
}
.MediaImagePreview {
  display: flex !important;
  width: 280px;
  padding: 8px;
  border-radius: 8px;
  background: var(--background);
  box-shadow: 0px 0px 3px 0px rgba(1, 2, 11, 0.24);
  composes: MediaItemPreview from './MediaCommon.module.scss';
}
.MediaItemWrapper {
  position: relative;
  min-width: 100px;
  height: 56px;
  margin-right: 12px;
  padding: 0;
  composes: MediaItemWrapper from './MediaCommon.module.scss';
}
.mediaItemWrapperOnAir {
  composes: mediaItemWrapperOnAir from './MediaCommon.module.scss';
}
.MediaItemWrapperPreview {
  border: none !important;
}
.MediaPreviewTotalItem {
  composes: MediaPreviewTotalItem from './MediaCommon.module.scss';
}
.MediaListNotFound {
  margin: 0;
  color: var(--text-secondary3);
}
.MediaPreviewTotalItem {
  composes: MediaPreviewTotalItem from './MediaCommon.module.scss';
}

.MediaImageItemInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.MediaImageItemInfoTitle {
  display: -webkit-box;
  overflow: hidden;
  width: 116px;
  color: var(--text-primary);
  font-size: 13px;
  white-space: pre-wrap;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
