.StyledSlot {
  composes: StyledSlot from './SlotCommon.module.scss';
}
.styleSlotInvisible {
  composes: styleSlotInvisible from './SlotCommon.module.scss';
}
.styledSlotWithOtherTransparent {
  composes: styledSlotWithOtherTransparent from './SlotCommon.module.scss';
}
.styledSlotWithLayoutOther1 {
  composes: styledSlotWithLayoutOther1 from './SlotCommon.module.scss';
}
.styledSlotLayoutStyleRounded {
  composes: styledSlotLayoutStyleRounded from './SlotCommon.module.scss';
}
.styledSlotLayoutStyleRounded {
  composes: styledSlotLayoutStyleRounded from './SlotCommon.module.scss';
}
.styledSlotOnAir {
  composes: styledSlotOnAir from './SlotCommon.module.scss';
}
.styledSlotSmall {
  border-radius: 8px;
}
.styledSlotEmptyOtherViewAndDragging {
  composes: styledSlotEmptyOtherViewAndDragging from './SlotCommon.module.scss';
}
.SlotSmallPreview {
  composes: SlotSmallPreview from './SlotCommon.module.scss';
}
.styledSlotLayoutStyleModern {
  composes: styledSlotLayoutStyleModern from './SlotCommon.module.scss';
}
.Indicator {
  position: absolute;
  top: 4px;
  left: 4px;
  z-index: 2;
  display: flex;
}
.Label {
  position: absolute;
  top: -1px;
  left: -1px;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 7px;
  border-bottom-right-radius: 7px;
  background: var(--label-status-background);
  color: var(--label-status);
  font-weight: 500;
  font-size: 8px;
}
