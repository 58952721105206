.ButtonStyled {
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  padding: var(--pButtonStyled);
  border-radius: var(--borderRadiusButtonStyled);
  color: var(--text-primary);
  font-size: var(--fontSizeButtonStyled);
  cursor: pointer;
  &:hover {
    color: var(--text-primary);
  }
}

.ButtonPrimary {
  border: var(--background18);
  background: var(--background18);
  color: #ffffff;

  &:hover {
    border: var(--background-hover-primary);
    background: var(--background-hover-primary);
    color: #ffffff;
  }

  &:active {
    border: var(--background-active-primary);
    background: var(--background-active-primary);
    color: #ffffff;
  }
  &:focus {
    border: var(--background18);
    background: var(--background18);
    color: #ffffff;
  }
  &:disabled {
    border: var(--background-active-primary);
    background: var(--background-active-primary) !important;
    color: #ffffff;
  }
}

.ButtonDark {
  border: var(--button-dark) !important;
  background: var(--button-dark);
  color: #e1e1e9;

  &:hover {
    border: var(--button-dark-hover) !important;
    background: var(--button-dark-hover) !important;
    color: #e1e1e9;
  }

  &:active {
    border: var(--button-dark-active);
    background: var(--button-dark-active);
    color: #e1e1e9;
  }

  &:focus {
    border: var(--button-dark);
    background: var(--button-dark);
    color: #e1e1e9;
  }

  &:disabled {
    border: var(--button-dark);
    background: var(--button-dark) !important;
    color: #e1e1e9;
  }
}

.ButtonGrey {
  border: var(--button-grey);
  background: var(--button-grey);
  color: var(--text-primary);
  &:hover {
    border: var(--button-grey-hover);
    background: var(--button-grey-hover);
  }

  &:active {
    border: var(--button-grey-active);
    background: var(--button-grey-active);
  }

  &:disabled {
    border: var(--button-grey-disabled);
    background: var(--button-grey-disabled) !important;
    color: var(--text-primary1);
  }
  &:focus:not(:active) {
    border: var(--button-grey);
    background: var(--button-grey);
  }
  &:focus {
    color: var(--text-primary);
  }
}

.ButtonOnlyContent {
  border: var(--none);
  background: var(--none);
  color: var(--text-primary);
  box-shadow: none !important;
  &:hover {
    border: var(--button-grey-hover);
    background: var(--button-grey-hover);
  }

  &:active {
    border: var(--button-grey-active);
    background: var(--button-grey-active);
  }

  &:disabled {
    border: var(--button-grey-disabled);
    background: var(--button-grey-disabled);
    color: var(--text-primary1);
  }
  &:focus:not(:active) {
    border: var(--none);
    background: var(--none);
  }
  &:focus {
    border: var(--none);
    background: var(--none);
    color: var(--text-primary);
  }
}

.ButtonWhite {
  border: var(--background40);
  background: var(--background40);
  color: var(--text-primary);
  &:hover {
    background: var(--button-grey-hover);
  }

  &:active {
    background: var(--button-grey-active);
  }

  &:disabled {
    background: var(--button-grey-disabled) !important;
    color: var(--text-primary1);
  }
  &:focus:not(:active) {
    background: var(--button-grey);
  }
  &:focus {
    color: var(--text-primary);
  }
}

.ButtonDanger {
  border: var(--button-danger);
  background: var(--button-danger);
  color: #ffffff;

  &:hover {
    border: var(--button-danger-hover);
    background: var(--button-danger-hover);
    color: #ffffff;
  }

  &:active {
    border: var(--button-danger-active) !important;
    background: var(--button-danger-active) !important;
    color: #ffffff;
  }

  &:focus {
    border: var(--button-danger);
    background: var(--button-danger);
  }

  &:disabled {
    border: var(--button-danger) !important;
    background: var(--button-danger) !important;
    color: #ffffff;
  }
}
