@import '../../../styles/commonStyles.scss';
.WrapperPopoverContent {
  width: auto;
  min-width: 250px;
  max-width: 330px;
  padding: 16px 16px 4px;
  box-shadow: none;
}
.WrapperPopoverTitle {
  margin-bottom: 16px;
  color: #1f1f1f;
  font-size: 14px;
  line-height: 21px;
}
.WrapperPopoverList {
  padding: 0 0 0 16px;
}
.WrapperPopoverItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  color: var(--text-primary1);
  font-size: $font-size-body1;
  line-height: 19px;
  cursor: pointer;

  .IconCheck {
    min-width: 16px;
    height: 19px;
    color: #2656c9;
    & > * {
      display: none !important;
    }
  }

  &.Dark {
    color: rgba(225, 225, 233, 0.75);

    .IconCheck {
      color: var(--text-primary1);
    }
  }

  &.Selected {
    cursor: default;

    .IconCheck > * {
      display: inline-block !important;
    }
  }
}
