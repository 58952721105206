@import '../../../styles/commonStyles.scss';

.BackgroundImageStyle {
  background-image: var(--urlBackgroundImageStyle) !important;
}
.LiveStreamLayout {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: none no-repeat scroll 0 0 transparent;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.liveStreamLayoutModern {
  padding: 0 0;
}
.liveStreamLayoutFullStream {
  justify-content: space-between;
}
.LiveOverlayTitle {
  position: absolute;
  inset: 0;
  z-index: 250;
}

.LiveStreamOverlayDnD {
  position: absolute;
  inset: 0;
  z-index: -1;
  &::after {
    content: '';
    position: absolute;
    inset: 0;
    display: none;
    border: 4px solid transparent;
    background-color: transparent;
  }
}

.liveStreamOverlayDnDDragging {
  z-index: 9999;
}
.liveStreamOverlayDnDHoverDrop {
  &::after {
    display: block;
    border-color: var(--text-link);
    background-color: rgba(117, 137, 255, 0.6);
  }
}
.LiveStreamMixerDisable {
  position: absolute;
  inset: 0;
  z-index: 200;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}
.IconLoading {
  display: block;
  width: 100%;
  font-size: 50;
}

.EditLayoutBg {
  background: var(--background3);
}
