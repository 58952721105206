@import '../../../styles/commonStyles.scss';

.PopoverBuyMore {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  border-radius: 8px;
  background: $background-buy-more-price-popover;
}

.PopoverBuyMoreItem {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-self: start;
  width: 100%;
}

.ItemTitle {
  color: $dark-text-body700;
  font-weight: 500;
}

.ValueWrapper {
  display: flex;
  gap: 10px;
  align-items: flex-start;
  align-self: stretch;
  padding: 8px 16px;
  border-radius: 8px;
  background: $level-dark3;
}

.StorageValueWrapper {
  flex-direction: column;
  gap: 4px;
  padding: 16px 16px;
  composes: ValueWrapper;
}

.ValueText {
  color: $text-level1;
  font-weight: 500;
}

.OverlayWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Row {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.Amount {
  width: 100px;
}

.Monthly {
  width: 58px;
}

.Annual {
  width: 58px;
}

.Unit {
  width: 68px;
}

.Divider {
  width: 100%;
  border-bottom: $border4;
}

.Data {
  font-weight: 400;
}
