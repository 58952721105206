.MediaAudioTextDelete {
  display: none;
  color: var(--text-primary1);
  font-size: 13px;
  line-height: 150%;
  cursor: pointer;
}
.MediaListNotFound {
  composes: MediaListNotFound from './MediaCommon.module.scss';
}
.MediaAudioNote {
  composes: MediaItemNote from './MediaCommon.module.scss';
}

.MediaAudioItemContainer {
  width: 100%;
  margin-bottom: 8px;
  padding: 0 4px;
  composes: MediaItemVerticalContainer from './MediaCommon.module.scss';
  &::after {
    top: -2px;
    bottom: -2px;
    border-radius: 10px;
  }
}
.MediaAudioItemContainer:hover .MediaAudioTextDelete {
  display: block;
}

.MediaAudioItemContainer:hover .MediaAudioDuration {
  display: none;
}

.mediaItemVerticalContainerDrophover {
  composes: mediaItemVerticalContainerDrophover from './MediaCommon.module.scss';
}
.mediaItemVerticalContainerDrophover {
  composes: mediaItemVerticalContainerDrophover from './MediaCommon.module.scss';
}

.mediaItemVerticalContainerDragSelected {
  composes: mediaItemVerticalContainerDragSelected from './MediaCommon.module.scss';
}
.mediaAudioItemContainerPlaying:hover .MediaAudioTextDelete {
  display: none;
}
.mediaAudioItemContainerPlaying:hover .MediaAudioDuration {
  display: block;
}
.mediaAudioItemContainerDropbottom {
  &::before {
    top: calc(100% + 2px);
  }
}

.MediaAudioDuration {
  color: var(--text-secondary);
  font-size: 13px;
  line-height: 150%;
}
.MediaAudioItemWrapper {
  position: relative;
  height: auto;
  min-height: 0;
  padding: 8px 12px;
  border-radius: 8px;
  // background: var(--background5);
}
.mediaItemWrapperOnAir {
  composes: mediaItemWrapperOnAir from './MediaCommon.module.scss';
}
.mediaItemWrapperDragging {
  composes: mediaItemWrapperDragging from './MediaCommon.module.scss';
}

.MediaAudioTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--text-primary1);
}
.MediaAudioName {
  max-width: 140px;
  font-size: 13px;
  line-height: 150%;
}
.MediaAudioTopIcon {
  margin-right: 12px;
}
.MediaButtonOnAir {
  composes: MediaButtonOnAir from './MediaCommon.module.scss';
}
.MediaAudioDragging {
  border-radius: 8px;
  background: var(--grey14) !important;
  composes: MediaItemDragging from './MediaCommon.module.scss';
}
.mediaItemDraggingOnAir {
  border: none;
}
.MediaAudioPreview {
  width: 294px;
  composes: MediaItemPreview from './MediaCommon.module.scss';
}
.MediaAudioItemWrapperPreview {
  box-shadow: var(--box-shadow-preview);
}
.MediaButtonOnAir {
  composes: MediaButtonOnAir from './MediaCommon.module.scss';
}
.MediaPreviewTotalItem {
  composes: MediaPreviewTotalItem from './MediaCommon.module.scss';
}
