@import '../../../styles/commonStyles.scss';
.SwitchContainer {
  :global(.ant-switch) {
    width: 28px !important;
    min-width: 0px !important;
    height: 18px !important;
    border: 1px solid rgba(255, 255, 255, 0.25);
    background: rgba(255, 255, 255, 0.24) !important;
  }
  :global(.ant-switch-handle) {
    top: 3px !important;
    width: 10px !important;
    height: 10px !important;
    &::before {
      background-color: rgba(255, 255, 255, 0.36) !important;
    }
  }
  :global(.ant-switch-checked .ant-switch-handle) {
    top: 2px !important;
    left: calc(100% - 18px + 2px);
    width: 14px !important;
    height: 14px !important;
    &::before {
      background-color: #1a380a !important;
    }
  }
  :global(.ant-switch-checked) {
    border: none !important;
    background: var(--green-contained) !important;
  }
}
.SwitchContainerLight {
  :global(.ant-switch) {
    width: 28px !important;
    min-width: 0px !important;
    height: 18px !important;
    border: 1px solid rgba(4, 16, 27, 0.24) !important;
    background: #ffffff;
  }
  :global(.ant-switch-handle) {
    top: 3px !important;
    width: 10px !important;
    height: 10px !important;
    &::before {
      background-color: #acb4ba !important;
    }
  }
  :global(.ant-switch-checked .ant-switch-handle) {
    top: 2px !important;
    left: calc(100% - 18px + 2px);
    width: 14px !important;
    height: 14px !important;
    &::before {
      background-color: #005916 !important;
    }
  }
  :global(.ant-switch-checked) {
    border: none !important;
    background: #b5ff9b !important;
  }
}
