@import '../../../../styles/commonStyles.scss';
.DestinationActionContainer {
  right: 0px !important;
  width: 460px;
  border-radius: 8px;
  box-shadow: none !important;
  &::before {
    display: none !important;
  }
}

.Form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.DesTitle {
  color: var(--text-primary1);
  font-weight: 500;
  font-size: $font-size-h6;
}
.Content {
  margin-top: 20px;
}
.Actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}

.BtnSave {
  width: 120px;
  margin-left: 8px;
  border-color: transparent;
  border-radius: 32px;
}

.ContentWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.FormLabel {
  margin-bottom: 0 !important;
  color: var(--text-secondary1);
  font-weight: 500;
  font-size: 13px;
}
