.StyledSlotOnLive {
  composes: StyledSlotOnLive from './SlotCommon.module.scss';
}
.styledSlotWithOtherTransparent {
  composes: styledSlotWithOtherTransparent from './SlotCommon.module.scss';
}
.styledSlotLayoutStyleRounded {
  composes: styledSlotLayoutStyleRounded from './SlotCommon.module.scss';
}
.styledSlotLayoutStyleModern {
  composes: styledSlotLayoutStyleModern from './SlotCommon.module.scss';
}
.styleSlotInvisible {
  composes: styleSlotInvisible from './SlotCommon.module.scss';
}
.styledSlotFullStream {
  display: block;
  border-radius: 0px !important;
}
.styledSlotEmpty {
  z-index: unset;
  opacity: 0;
}
.styledSlotEmptyOtherViewAndDragging {
  composes: styledSlotEmptyOtherViewAndDragging from './SlotCommon.module.scss';
}
.DropArea {
  composes: DropArea from './SlotCommon.module.scss';
}
.DropAreaRounded {
  composes: DropAreaRounded from './SlotCommon.module.scss';
}
.dropAreaOver {
  composes: dropAreaOver from './SlotCommon.module.scss';
}
.slotDivPosition {
  position: absolute;
  width: 100%;
  height: 100%;
}
.StyledSlot {
  composes: StyledSlot from './SlotCommon.module.scss';
}
.styledSlotWithLayoutOther1 {
  composes: styledSlotWithLayoutOther1 from './SlotCommon.module.scss';
}
.SlotPreview {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: auto;
  height: auto;
  pointer-events: none;
}
