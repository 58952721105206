@import '../../../styles/commonStyles.scss';
.PopoverDeleteWrapper {
  padding: 16px;
  border-radius: 8px;
}
.PopoverDeleteTitle {
  margin-bottom: 8px;
  color: $text-level1;
  font-weight: 500;
}
.PopoverDeleteTitleLight {
  color: #0f0f0f;
}
.PopoverDeleteText {
  display: block;
  color: $text-level2;
  font-size: 13px;
  line-height: 150%;
}
.PopoverDeleteTextLight {
  color: #404040;
}
.PopoverDeleteAction {
  display: flex;
  margin-top: 24px;
}
.PopoverDeleteButton {
  border: none;
  border-radius: 32px;
  box-shadow: none;
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
  text-align: center;
  cursor: pointer;
}
.PopoverDeleteNo {
  margin-right: 8px;
  padding: 6px 30px;
  background-color: rgba(255, 255, 255, 0.1);
  color: $text-level1;
  composes: PopoverDeleteButton;
}
.PopoverDeleteOk {
  padding: 6px 40px;
  background-color: #ff6565;
  color: #fff;
  composes: PopoverDeleteButton;
}

.PopoverDeleteArrow {
  position: absolute;
  top: 15px;
  right: -5px;
  width: 16px;
  height: 20px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.1)
    ),
    rgba(23, 23, 29, 0.85);
  transform: rotate(45deg);
}
.PopoverDeleteArrowLight {
  background: #ffffff;
}
.PopoverDeleteNoLight {
  background: #f3f2f2;
  color: #0f0f0f;
}

.PopoverActionWrapper {
  padding: 16px 8px;
}
.PopoverActionItem {
  display: flex;
  align-items: center;
  padding: 0 20px 0 0;
  cursor: pointer;
  .PopOverActionIcon {
    margin-right: 8px;
    margin-left: 8px;
  }
}
.PopoverActionDownload {
  margin-bottom: 10px;
  padding-bottom: 10px;
  color: #fff;
  composes: PopoverActionItem;
}
.PopoverActionDownloadLight {
  color: #0f0f0f;
}
.PopoverActionDownloadHasBorder {
  border-bottom: 1px solid var(--border6);
}
.PopoverActionDelete {
  color: var(--status-error2);
  composes: PopoverActionItem;
}
.PopoverActionMediaIcon {
  top: 50%;
  right: 8px;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  padding: 0;
  border-radius: 12px;
  background-color: rgba(4, 16, 27, 0.04);
  transform: translateY(-50%);
  composes: PopoverActionMediaIcon from './MediaCommon.module.scss';
}
