.LiveControlInvite {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  margin-top: 8px;
  margin-right: 0;
  margin-left: 10px;
  border-radius: 8px;
}
.liveControlInviteIcon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  margin-right: 5px;
  margin-left: 5px;
  border-radius: 24px;
  background-color: rgba(86, 86, 86, 0.84);
  box-shadow: 0 0 2px black;
  &:hover {
    border: solid 1px #ffffff;
    box-shadow: 0 0 2px white;
    cursor: pointer;
  }
}
