.MediaItemDragging {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
}
.MediaListNotFound {
  composes: MediaListNotFound from './MediaCommon.module.scss';
}
.mediaItemHorizontalContainerDrophover {
  composes: mediaItemHorizontalContainerDrophover from './MediaCommon.module.scss';
}
.mediaItemHorizontalContainerDropbottom {
  composes: mediaItemHorizontalContainerDropbottom from './MediaCommon.module.scss';
}
.MediaVideoDragging {
  top: 3px;
  right: 7px;
  bottom: 3px;
  left: 7px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  background: var(--grey14) !important;
  composes: MediaItemDragging;
}

.MediaVideoIconPlay {
  top: 2px;
  right: 2px;
  filter: drop-shadow(0px 0px 11px rgba(0, 0, 0, 0.8));
  composes: MediaItemIconPlay from './MediaCommon.module.scss';
}
.MediaVideoItemContainer {
  display: flex;
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  composes: MediaItemHorizontalContainer from './MediaCommon.module.scss';
  &:hover {
    background-color: rgba(4, 16, 27, 0.04);
  }
}
.MediaVideoItemContainerSelected {
  background-color: rgba(4, 16, 27, 0.04);
}

.MediaVideoItemWrapper {
  position: relative;
  min-width: 100px;
  height: 56px;
  margin-right: 12px;
  padding: 0;
  composes: MediaItemWrapper from './MediaCommon.module.scss';
}

.MediaVideoItemPlayer {
  display: block;
  object-fit: contain;
  width: 100%;
  height: 100%;
  max-height: 150px;
  border-radius: 6px;
  pointer-events: none;
}
.MediaVideoItemDuration {
  color: var(--text-secondary4);
  font-size: 12px;
}
.mediaItemHorizontalContainerDragSelected {
  composes: mediaItemHorizontalContainerDragSelected from './MediaCommon.module.scss';
}
.mediaItemWrapperOnAir {
  //  composes: mediaItemWrapperOnAir from './MediaCommon.module.scss';
}
.mediaItemWrapperDragging {
  composes: mediaItemWrapperDragging from './MediaCommon.module.scss';
}
.LoadingSocial {
  composes: LoadingSocial from './MediaCommon.module.scss';
}
.MediaVideoButtonOnAir {
  //  composes: MediaVideoButtonOnAir from './MediaCommon.module.scss';
  position: absolute;
  top: 12px;
  left: 36px;
  z-index: 20;
  display: flex;
  align-items: center;
  padding: 1px 8px;
  border-radius: 14px;
  background: var(--red-contained);
  color: var(--red-on-contained);
  font-weight: 500;
  font-size: 12px;
  & > svg {
    padding-right: 3px;
  }
}
.MediaItemWrapperPreview {
  border: none !important;
}
.MediaVideoPreview {
  display: flex !important;
  width: 280px;
  padding: 8px;
  border-radius: 8px;
  background: var(--background);
  box-shadow: 0px 0px 3px 0px rgba(1, 2, 11, 0.24);
  composes: MediaItemPreview from './MediaCommon.module.scss';
}

.MediaPreviewTotalItem {
  composes: MediaPreviewTotalItem from './MediaCommon.module.scss';
}

.MediaVideoItemInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.MediaVideoItemInfoTitle {
  display: -webkit-box;
  overflow: hidden;
  width: 116px;
  color: var(--text-primary);
  font-size: 13px;
  white-space: pre-wrap;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.VideoPlayIsRepeat {
  position: absolute;
  top: 12px;
  left: 12px;
  z-index: 11;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  border-radius: 50%;
  background-color: var(--green-contained) !important;
  color: var(--green-on-contained) !important;
}

.VideoPreviewContainer {
  display: block;
  width: 100%;
  width: 280px;
  height: 260px;
  padding: 8px;
  border-radius: 8px;
  background-color: var(--background-input-disabled1);
  composes: MediaItemPreview from './MediaCommon.module.scss';
}

.VideoPlayerSlider {
  top: 0;
  margin: -4px 0 0 0;
  padding: 0;
  :global(.ant-slider-rail) {
    background-color: var(--slider);
  }

  :global(.ant-slider-handle) {
    box-shadow:
      0px 1px 4px 0px rgba(1, 2, 11, 0.24),
      0px 4px 12px 0px rgba(1, 2, 11, 0.08),
      0px 4px 8px 0px rgba(1, 2, 11, 0.12);
  }
}

.VideoPlayerController {
  display: flex;
  align-items: center;
  margin-top: 4px;
}

.VideoPlayerVolume {
  display: flex;
  align-items: center;
  width: 88px;
}
.VideoPlayerMute {
  z-index: 10;
  display: inline-flex;
  color: var(--icon-color1);
  cursor: pointer;
}

.VideoPlayerVolumeSwitch {
  display: none;
  width: 64px;
  margin: 6px 0 0 8px;
  padding: 0;
  :global(.ant-slider-rail) {
    background-color: var(--slider);
  }
  :global(.ant-slider-handle) {
    box-shadow:
      0px 1px 4px 0px rgba(1, 2, 11, 0.24),
      0px 4px 12px 0px rgba(1, 2, 11, 0.08),
      0px 4px 8px 0px rgba(1, 2, 11, 0.12);
  }
}

.VideoPlayerIconPlay {
  display: flex;
  margin-left: 32px;
  color: var(--icon-color1);
  font-size: 24px;
  cursor: pointer;
}
.VideoPlayerInfo {
  display: flex;
  flex: 1;
  flex-direction: row-reverse;
  align-items: center;
}

.VideoPlayRepeat {
  display: inline-flex;
  margin-left: 5px;
  padding: 4px 6px;
  border-radius: 4px;
  background-color: transparent;
  color: var(--text-secondary3);
  font-size: 19px;
  cursor: pointer;

  &:hover {
    border-radius: 50%;
    background-color: var(--background23);
    cursor: pointer;
  }
}

.VideoPlayIsRepeatPreViewControl {
  border-radius: 50%;
  background-color: var(--green-contained) !important;
  color: var(--green-on-contained) !important;
}

.VideoPlayerName {
  display: -webkit-box;
  overflow: hidden;
  margin-top: 8px;
  color: var(--text-primary);
  font-weight: 500;
  font-size: 13px;
  white-space: pre-wrap;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.VideoPlayerOtherInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
}

.VideoPlayerDuration {
  color: var(--text-secondary4);
  font-weight: 400;
  font-size: 12px;
}

.VideoPlayerOnAir {
  padding: 1px 8px;
  border-radius: 20px;
  background-color: var(--red-contained);
  color: var(--red-on-contained);
  cursor: pointer;
}
.InsightEngineIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 48px;
}
.VideoTranscribed {
  background-color: var(--background18);
}
.IconTranscribed {
  fill: var(--text-primary) !important;
}
.Column {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.MoreIcon {
  position: relative !important;
  top: 0px;
  right: 0px;
  display: flex;
  transform: none;
}

.DisableButton {
  opacity: 0.3;
  pointer-events: none;
}
