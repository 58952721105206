.ql-mention-list-container {
  z-index: 9001;
  overflow: auto;
  width: 270px;
  border-radius: 4px;
  background-color: var(--background-dropdown);
  box-shadow: var(--box-shadow-preview);
}

.ql-mention-loading {
  vertical-align: middle;
  padding: 0 20px;
  font-size: 16px;
  line-height: 44px;
}

.ql-mention-list {
  max-height: 300px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.ql-mention-list-item {
  display: flex;
  vertical-align: middle;
  gap: 8px;
  align-items: center;
  padding: 0 16px;
  color: var(--text-primary1);
  font-size: 13px;
  line-height: 44px;
  cursor: pointer;
}
.ql-mention-list-item img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
.ql-mention-list-item.disabled {
  cursor: auto;
}

.ql-mention-list-item.selected {
  background-color: var(--grey9);
  text-decoration: none;
}

.mention {
  margin-right: 2px;
  padding: 3px 8px;
  border-radius: 47px;
  background: var(--green-contained);
  font-weight: 500;
  user-select: all;
}

.mention > span {
  margin: 0 3px;
  line-height: 20px !important;
}

.mention-all {
  font-weight: 500;
  font-size: 13px;
}
.mention-all-value {
  color: var(--text-secondary4);
  font-weight: 400;
  font-size: 13px;
}
.mention-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: white;
  font-weight: 600;
  font-size: 11px;
}
