@import '../../../styles/commonStyles.scss';
.EditLayoutButton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 8px;
  border-left: 1px solid var(--grey12);
}
.HandleButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  font-family: 'icomoon';
}
.EditLayoutIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 49px;
  height: 49px;
  color: var(--icon-color3);
  font-size: 12;
  cursor: pointer;
  &:hover {
    border-radius: 2px;
    background: var(--background21);

    color: var(--icon-color5);
  }
  &:active {
    background: var(--background21);
    color: var(--icon-color5);
  }
}
.SaveLayout {
  @extend .HandleButton;
  background: var(--blue-contained);
  color: var(--blue-on-contained);

  &:hover {
    background: var(--blue-container-hover);
  }
  &:active {
    background: var(--blue-container-active);
  }
}
.CloseEditLayout {
  @extend .HandleButton;
  margin-left: 8px;
  background: var(--grey16);
  color: var(--icon-color1);
  &:hover {
    background: var(--background12) !important;
  }
  &:active {
    background: var(--background19) !important;
  }
}
