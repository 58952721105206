.Wrapper {
  position: absolute;
  top: 0;
  bottom: 68px;
  left: 24px;
  z-index: 1400;
  display: none;
  flex-direction: column-reverse;
  gap: 16px;
  align-items: center;
  overflow-y: scroll;
  min-height: 400px;
  padding: 24px 8px;
  pointer-events: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
.WrapperItem {
  display: none;
  flex-direction: column;
  border-radius: 16px;
  background: #ffffff;
  box-shadow:
    0px 4px 12px rgba(1, 2, 11, 0.08),
    0px 1px 4px rgba(1, 2, 11, 0.24);
  pointer-events: all;
  animation-name: fadeInLeft;
  animation-duration: 0.5s;
}

.WrapperDarkItem {
  display: none;
  flex-direction: column;
  border-radius: 16px;
  background: black;
  box-shadow:
    0px 4px 12px rgba(1, 2, 11, 0.08),
    0px 1px 4px rgba(1, 2, 11, 0.24);
  pointer-events: all;
  animation-name: fadeInLeft;
  animation-duration: 0.5s;
}
.showNotification {
  display: flex;
}
.Container {
  position: relative;
  display: flex;
  align-items: center;
  width: 320px;
  height: 100%;
  min-height: 84px;
  padding: 10px 16px;
  border-radius: 16px;
  background: var(--bgDarkContainer);
}

.Avatar {
  position: relative;
  margin-right: 16px;
}

.AvatarImage {
  object-fit: cover;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.StatusWrapperWhiteBorder {
  position: absolute;
  right: -6px;
  bottom: -6px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 11px;
  background: #fff;
}

.StatusWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: #b5ff9b;
}

.TextName {
  color: #0f0f0f;
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
}

.TextNameDark {
  color: var(--text-primary1);
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
}

.TextMessage {
  margin-top: 4px;
  color: #0f0f0f;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  white-space: pre-line;
}

.TextMessageDark {
  margin-top: 4px;
  color: rgba(225, 225, 233, 0.75);
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  white-space: pre-line;
}

.CloseWrapper {
  position: absolute;
  top: -6px;
  right: -6px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  padding: 6px;
  border-radius: 12px;
  background: #f3f2f2;
  cursor: pointer;
}
@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.ContentContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
}
.Header {
  color: var(--text-primary1);
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
}
.ToastAction {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-top: 16px;
}
.ToastJRContainer {
  flex-direction: column;
  gap: 8px;
  align-items: start;
  padding: 16px;
}
.Content {
  display: flex;
  gap: 8px;
  align-items: center;
}
.JRAvatar {
  position: relative;
  width: 28px;
  height: 28px;
  border-radius: 8px;
}
.JRAvatarImage {
  object-fit: cover;
  width: 28px;
  height: 28px;
  border-radius: 50%;
}
.TextContainer {
  margin-top: 0;
  line-height: 150%;
  word-break: break-word;
}
.JRTextName {
  color: var(--text-primary1);
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  word-break: break-word;
}
.JRTextRole {
  color: var(--green);
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  word-break: break-word;
}
.SubText {
  padding-left: 36px;
  color: var(--text-primary1);
  font-size: 13px;
}

// .PreventContainer {
//   :global(.ant-checkbox) + span {
//     font-size: 13px;
//   }
// }
.TextBold {
  color: var(--text-primary1);
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
}
.DeclineBtn {
  width: 80px;
}
.AcceptBtn {
  width: 100px;
}
.ToastResContainer {
  flex-direction: column;
  gap: 8px;
  align-items: start;
  min-height: 0px;
  padding: 16px;
}
.CheckIconWrapper {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #b5ff9b;
}
.WaitingIconWrapper {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #ffb287;
}
.JRResAvatar {
  position: relative;
  width: 34px;
  height: 34px;
  padding: 0 6px 6px 0;
  border-radius: 17px;
}
