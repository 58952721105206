.TitleOnCanvas {
  display: flex;
  align-items: center;
  overflow: hidden;
  height: inherit;
  border-radius: inherit;
  user-select: none !important;
}
.QuillEditor {
  display: flex;
  flex: 1;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 100%;
  color: #ffffff;

  :global(.ql-editor.ql-blank::before) {
    color: var(--text-secondary4) !important;
  }
  :global(.ql-container) {
    display: flex;
    align-items: center;
    border: none !important;
    font-family: 'Poppins', sans-serif !important;
    :global(.ql-snow) {
      border: none !important;
    }
    :global(.ql-editor) {
      height: auto !important;
      padding: 0px !important;
      text-justify: center;
    }
    :global(.ql-editor.ql-blank::before) {
      padding-left: 12px;
      font-style: normal;
    }
  }
}
