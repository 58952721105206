.NoteEditor {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  margin-top: 8px;
  :global(.ql-toolbar) {
    display: flex;
    gap: 4px;
    width: 100%;
    padding: 0px 24px 12px 24px !important;
    border: none !important;
    border-bottom: 1px solid var(--border9) !important;
    font-family: 'Poppins', sans-serif !important;
    :global(.ql-active) {
      background: var(--background-menu) !important;
    }
  }
}

.QuillEditor {
  flex: 1;
  width: 200;
  :global(.ql-editor.ql-blank::before) {
    color: var(--text-secondary4) !important;
  }
  :global(.ql-container) {
    width: 342px;
    border: none !important;
    color: var(--text-primary);
    font-size: 13px;
    font-family: 'Poppins', sans-serif !important;
    :global(.ql-snow) {
      border: none !important;
    }
    :global(.ql-editor) {
      padding: 12px 24px 12px 28px !important;
    }
    :global(.ql-editor.ql-blank::before) {
      padding-left: 12px;
      font-style: normal;
    }
  }
}
.ToolbarItemCustom {
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 48px !important;
  height: 32px !important;
  border-radius: 58px;
  background: var(--grey8) !important;
  color: var(--text-primary) !important;
}
.divider {
  height: 100%;
  border-left: 1px solid var(--border9);
}
.EditorContent {
  position: relative;
  display: flex;
  flex: 1;
  overflow: hidden;
}
.EditorContentOverlay {
  position: absolute;
  z-index: 1;
  display: none;
  width: 100%;
  height: 100%;
  border: 4px solid #4098ff;
  border-radius: 0px 0px 0px 16px;
  background: rgba(38, 86, 201, 0.6);
}
.Loading {
  position: absolute;
  z-index: 1;
  display: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 4, 12, 0.9);
}
.editorContentOverlayShow {
  display: flex;
}
