@import '../../../styles/commonStyles.scss';
.TimeSelect {
  display: flex;
  input {
    color: rgba(225, 225, 233, 0.5);
    font-size: $font-size-body1;
  }
}
.TimeSelectWrapper {
  flex: 1;
}
.TimeSelectItem {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 8px;
}
// fix css
.scheduleDatePicker {
  width: 100%;
}

.MenuDropdownItem {
  display: flex;
  align-items: center;
  :global(.flex-1) {
    flex: 1;
  }
}

.IconColor {
  color: gray;
}

.CheckedIcon {
  color: #2656c9;
  &.Dark {
    color: var(--text-primary1);
  }
}
