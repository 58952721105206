@import '../../../styles/commonStyles.scss';

.ModalOutContent {
  padding: 32px 16px;
  color: var(--text-primary);
}

.ModalOutTitle {
  margin: 8px 0 16px;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
}
.ModalOutText {
  margin-bottom: 32px;
  color: var(--text-secondary);
  font-size: 13px;
  line-height: 150%;
  text-align: center;
  strong {
    color: $text-level1;
  }
}
.Footer {
  display: flex;
  gap: 16px;
  justify-content: center;
}
