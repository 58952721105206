.CancelPlan {
  width: 482px;
  height: 268px;
  padding: 16px;
}

.Main {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.Content {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  color: rgba(225, 225, 233, 0.72);
  font-weight: 400;
  font-size: 13px;

  .Text {
    margin-bottom: 8px;
  }

  .Highlight {
    color: #e1e1e9;
  }
}

.Title {
  color: #e1e1e9;
  font-size: 16px;
}

.Actions {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}

.ButtonYes {
  width: 100px;
  height: 40px;
}

.ButtonClose {
  width: 140px;
  height: 40px;
  margin-left: 8px;
}
