.addImage {
  position: relative;
  display: flex;
  padding: 8px;
  border: 3px solid transparent;
  // cursor: pointer;
  border-radius: 8px;
  background: var(--border5);

  span {
    display: flex;
    gap: 8px;
  }
}
.addImage:hover {
  border: 3px solid var(--text-link);
  border-radius: 8px !important;
  .remove-button {
    display: flex !important;
  }
}
.addImage-only {
  position: relative;
  border: 3px solid transparent;
  line-height: 0px;
}
.addImage-only:hover {
  border: 3px solid var(--text-link);
  border-radius: 8px !important;
  .remove-button {
    top: -10px;
    right: -10px;
    display: flex !important;
  }
}

.remove-button {
  position: absolute;
  top: -12px;
  right: -12px;
  z-index: 20;
  display: none;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: var(--button-grey);
  color: var(--text-primary1);
  font-size: 20px;
  font-family: 'icomoon';
  cursor: pointer;
  &::before {
    content: '\e909';
    font-size: 8px;
  }

  &:hover {
    background: var(--button-grey-hover);
  }
  &:active {
    background: var(--button-grey-active);
  }
  &:disabled {
    background: var(--button-grey-disabled);
    color: var(--text-primary1);
  }
}
.image-custom {
  object-fit: cover;
  width: 100px !important;
  height: 56px;
  border: none !important;
  border-radius: 0px !important;
  &:hover {
    border: none !important;
  }
}
.image-custom-only {
  object-fit: cover;
  width: 100% !important;
  height: 157px;
  border-radius: 8px !important;
}

.custom-image-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: var(--text-primary);
  .custom-image-title {
    font-weight: 500;
  }
  .custom-image-time {
    display: flex;
    gap: 4px;
    align-items: center;
    padding: 2px 8px;
    border-radius: 24px;
    background: var(--background42);
    color: #ffffff;
    font-weight: 500;
    font-size: 13px;
  }
  .custom-image-time-icon {
    color: white;
    font-size: 20px;

    font-family: 'icomoon';
    &::before {
      content: '\e953';
    }
  }
}
.avatar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100% !important;
  height: 157px;
  border-radius: 8px !important;
  background: var(--border5);
}
.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75px;
  height: 75px;
  border-radius: 50%;
}
.name {
  color: #ffffff;
  font-weight: 500;
  font-size: 24px;
  text-transform: uppercase;
}
.avatar-container-small {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px !important;
  height: 56px;
  background: var(--grey8);
}
.avatar-small {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.name-small {
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
}
