@import '../../../styles/commonStyles.scss';

.ModalFieldEmail {
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
  padding: 0 16px;
}
.FormInviteObserver {
  flex: 1;
}

.ModalFormItem {
  height: 40px;
  margin-bottom: 0;
}
.ActionBtn {
  display: flex;
  gap: 8px;
  align-items: center;
  padding-right: 15px;
  padding-left: 15px;
  border-radius: 20px;
  color: var(--text-primary1);
  font-weight: 500;
}
.ModalUserListWrapper {
  overflow-y: scroll;
  max-height: 216px;
  margin-left: 16px;
}
