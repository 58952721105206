.UploadTranscript {
  display: flex;
  flex-direction: column;
  gap: 64px;
  justify-content: center;
  align-items: center;
  height: calc(100% - 100px);
  margin-top: -80px;
}
.UploadTranscriptHide {
  display: none;
  visibility: hidden;
}
.UploadVideo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 500px;
  input {
    display: none;
  }
}
.UploadVideoLeft {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}
.Title {
  font-weight: 500;
  font-size: 15px;
}
.Row {
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;
}
.IconButton {
  fill: #ffffff;
}
.divider {
  width: 100%;
  width: 350px;
  height: 2px;
  background: var(--border11);
}
.Description {
  color: var(--text-secondary);
}
.meetIcon {
  width: 16px;
  height: 16px;
}
.Flex1 {
  flex: 1;
}
.Toast {
  display: flex;
  flex-direction: column;
  gap: 4px;
  text-align: left;
}
.mainDescription {
  color: var(--text-secondary3) !important;
}
.subDescription {
  color: var(--text-primary) !important;
  font-weight: 400 !important;
}
.ModalAction {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
}
.confirmBtn {
  width: 120px;
}
.WidthContainer {
  width: 500px;
}
.UploadMeeting {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.Uploading {
  margin-right: 16px;
  color: var(--text-secondary);
  font-size: 13px;
}
