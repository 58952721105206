@value ItemAccordionContainer from './ItemAccordion.module.scss';
@value ItemAccordionContents from './ItemAccordion.module.scss';

.LiveItemContainer .ItemAccordionContainer {
  padding-bottom: 0;
}
.LiveItemContainer .ItemAccordionContents {
  justify-content: center;
  padding: 0 24px;
}
.LiveItemAccordionContent {
  width: 100%;
  padding-right: 5px;
}
.ListItemLive {
  position: relative;
  display: block;
}

.NotYetText {
  color: var(--text-secondary);
  font-size: 13px;
}
