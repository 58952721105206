@import '../../../styles/commonStyles.scss';
.Wrapper {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  width: 100%;
  padding: 6px 8px;
  border: 1px solid transparent;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    &:not(.SelectedCard) {
      background-color: $background-light-darker5;
    }
  }
}
.SelectedCard {
  border: 1px solid $light-material-blue;
  background-color: $background-light-material-blue;
  .VideoName {
    color: $light-material-blue;
  }
}

.VideoThumbnailWrapper {
  display: 'flex';
  justify-content: 'center';
  align-items: 'center';
}
.VideoThumbnail {
  object-fit: cover;
  object-fit: cover;
  width: 24px;
  height: 13px;
  border: $border2;
  border-radius: 2px;
}

.VideoNameWrapper {
  flex: 1;
  overflow: hidden;
}
.VideoName {
  display: block;
  overflow: hidden;
  color: var(--text-primary);
  text-overflow: ellipsis;
  white-space: nowrap;
}
.VideoName::first-letter {
  text-transform: uppercase !important;
}

.VideoDuration {
  color: var(--text-secondary);
}

.HoverIcon {
  display: flex;
  gap: 8px;
}

.IconDefault {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.RefreshIcon {
  color: #2656c9;
  &::after {
    content: '\e950';
    font-size: 16px;
    font-family: 'icomoon';
  }
}

.LoadingIcon {
  @extend .IconDefault;
}
