@import '../../../styles/commonStyles.scss';
.ContentPopoverOut {
  max-width: 280px;
  padding: 16px 10px;
  border-radius: 8px;
  background-color: $background-dropdown;
}
.TitlePopoverOut {
  margin-bottom: 8px;
  color: $text-level1;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
}
.DescPopoverOut {
  color: $text-level2;
}
.MediaHeader {
  display: block;
  margin-bottom: 12px;
  padding-bottom: 0;
  composes: RightTabBarHeader from './RightTabBar.module.scss';
}

.MediaHeaderTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  padding-right: 24px;
  padding-left: 24px;
}
.MediaHeaderTitle {
  margin-bottom: 0;
  composes: RightTabBarHeaderTitle from './RightTabBar.module.scss';
}
.MediaHeaderStorage {
  color: var(--text-secondary1);
  font-size: 13px;
  line-height: 150%;
}

.MediaHeaderOutOfStorage {
  display: inline-flex;
  align-items: center;
  color: $status-warning;
}
.MeaderHeaderPopover {
  :global(.ant-popover-inner) {
    padding: 0;
    border-radius: 8px;
    background-color: #333339;
    box-shadow: none;
  }
  :global(.ant-popover-arrow-content) {
    background-color: #333339;
    box-shadow: none;
  }
}

.MediaHeaderIconWarning {
  margin-right: 6px;
  cursor: pointer;
}
.MediaHeaderLinkUpgrade {
  padding-left: 8px;
  color: var(--text-link);
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
}

.MediaUpload {
  margin-bottom: 17px;
  padding-right: 24px;
  padding-left: 24px;
}
.MediaUploadButton {
  display: block;
  width: 100%;
  padding: 8px 6px;
  border: none;
  border-radius: 68px;
  box-shadow: none;
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
  text-align: center;
  cursor: pointer;
}
.MediaUploadInput {
  display: none;
}
.IconUpload {
  margin-right: 9px;
  color: var(--icon-color1);
}
.MediaUploadStatus {
  position: relative;
  display: flex;
  margin: 0 24px;
  margin-bottom: 17px;
}
.ProgressPopover {
  overflow-y: auto;
  width: 360px;
  height: auto;
  max-height: 480px;
  padding: 16px;
  color: var(--text-primary1);
  font-size: 13px;
  line-height: 150%;
}
.ProgressPopoverTitle {
  margin-bottom: 25px;
  color: var(--text-primary1);
  font-weight: 500;
  font-size: 14px;
}
.ProgressPopoverItem {
  position: relative;
  display: flex;
  align-items: center;
  padding: 13px 60px 13px 0;
  border-bottom: 1px solid $level-lighter3;
}
.ProgressPopoverType {
  margin-right: 10px;
}
.ProgressPopoverStatus {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.ProgressStatusRetry {
  display: inline-block;
  margin-right: 10px;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
}

.ProgressStatus {
  margin-left: 8px;
}
.ProgressStatusError {
  margin: 0;
  color: $status-error;
  font-size: 12px;
  line-height: 150%;
}
.ProgressAction {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
}
.MediaUploadActionText {
  margin: 0;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  cursor: pointer;
}
.MediaUploadCancel {
  margin-right: 13px;
  color: var(--text-secondary1);
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
  composes: MediaUploadActionText;
}
.MediaUploadView {
  color: var(--text-link);
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
  composes: MediaUploadActionText;
}

.ProgressPercent {
  color: var(--text-primary1);
}
.ProgressStatusSize {
  margin: 0;
  color: var(--text-secondary1);
  font-size: 12px;
  line-height: 150%;
}

.ProgressStatusLabel {
  margin-bottom: 4px;
  color: var(--text-primary1);
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
}
.MessageToView {
  color: $text-level1;
  text-decoration: underline;
  cursor: pointer;
}
