@import '../../../styles/commonStyles.scss';
.ColorPickerContent {
  margin-bottom: 24px;
  :global(.react-colorful) {
    width: 310px;
    height: 310px;
  }
  :global(.react-colorful__saturation) {
    margin-bottom: 12px;
    border: none;
    border-radius: 0;
    box-shadow: none;
  }
  :global(.react-colorful__pointer) {
    width: 14px;
    height: 14px;
    box-shadow: none;
  }
  :global(.react-colorful__hue) {
    height: 14px;
    margin: 0 17px;
    border-radius: 4px;
  }
  :global(.react-colorful__alpha) {
    height: 14px;
    margin: 8px 17px 0;
    border-radius: 4px;
  }
}
.ColorPickerHexInput {
  padding-right: 10px;
  input {
    max-width: 100px;
  }
}
.LabelPicker {
  display: block;
  margin-bottom: 2px;
  color: $text-level3;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  text-transform: uppercase;
}
.LabelPickerLight {
  color: #606060;
}

.ColorPickerInput {
  display: flex;
  margin: 0 -2px;
  padding: 0 17px 22px;
  input {
    width: auto;
    padding: 10px;
    border: 1px solid var(--border2);
    border-radius: 8px;
    background: var(--background31);
    color: var(--text-primary1);
  }
}
