.WrapperPopoverContent {
  composes: WrapperPopoverContent from './BottomPopoverMedia.module.scss';
}
.WrapperMic {
  margin-bottom: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
}
.wrapperMicLight {
  border-bottom: 1px solid rgba(4, 16, 27, 0.08);
}
.WrapperPopoverTitle {
  composes: WrapperPopoverTitle from './BottomPopoverMedia.module.scss';
}

.wrapperPopoverTitleDark {
  color: var(--text-primary1);
}
.WrapperPopoverList {
  composes: WrapperPopoverList from './BottomPopoverMedia.module.scss';
}
.WrapperPopoverItem {
  composes: WrapperPopoverItem from './BottomPopoverMedia.module.scss';
}

.WrapperSpeaker {
  margin-bottom: 0;
  padding-bottom: 0;
  border: none;
  composes: WrapperMic;
}
.IconCheck {
  composes: IconCheck from './BottomPopoverMedia.module.scss';
}
.Selected {
  composes: Selected from './BottomPopoverMedia.module.scss';
}
.Dark {
  composes: Dark from './BottomPopoverMedia.module.scss';
}
