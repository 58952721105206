@import '../../styles/commonStyles.scss';

.custom-modal {
  .overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
  .ant-modal-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    background-color: transparent;
    box-shadow: unset;
    .ant-modal-body {
      overflow: auto;
      padding: 0 !important;
      border-radius: 0px !important;
      box-shadow:
        0 3px 6px -4px rgb(0 0 0 / 12%),
        0 6px 16px 0 rgb(0 0 0 / 8%),
        0 9px 28px 8px rgb(0 0 0 / 5%);
    }
    .ant-modal-close {
      display: none;
    }
    .custom-modal-content {
      position: relative;
      height: 100%;
      border-radius: 8px;
      background: var(--background13);
      .modal-container {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        padding: 16px;
        border-radius: 8px;
        background: var(--background-modal);
        // background: $background-level2;
      }
      .modal-close {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 10;
        padding: 0;
        border: 0;
        background: transparent;
        color: rgba(0, 0, 0, 0.45);
        outline: 0;
        font-weight: 700;
        line-height: 1;
        text-decoration: none;
        cursor: pointer;
        transition: color 0.3s;
        &.fullscreen {
          margin-right: 56px;
        }
      }
      .modal-close-x {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 56px;
        height: 56px;
        color: var(--text-secondary5);
      }
    }
  }
  .ant-modal-header {
    border: none;
    background: none;
  }
  .ant-modal-footer {
    border: none;
    background: none;
  }
  .ant-modal-close {
    color: $text-level2;
  }
}
.ModalStyled {
  border-radius: 8px;
  .fullscreen {
    height: 100vh;
    padding-top: 24px;
  }
}
.ItemControlIconOverlap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
}
