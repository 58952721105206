@import '../../../styles/commonStyles.scss';

.ContactContainer {
  width: 907px;
}

.Wrapper {
  background: url('../../../assets/images/contactbg2.png');
  background-blend-mode: overlay;
}

.Title {
  margin-top: 16px;
  padding: 0 24px;
  color: $text-level1;
  font-size: $font-size-h3;
}

.Subtitle {
  margin-top: 8px;
  padding: 0 24px;
  color: $text-level2 !important;
  font-weight: 400;
  font-size: $font-size-h5;
  font-family: var(--font-family);
}

.Email {
  font-weight: 400;
  font-family: var(--font-family) !important;
  text-decoration: underline;
}

.EmailLabel {
  margin: 0px 4px 0px 8px;
  color: $text-level3;
}

.Mail {
  margin-top: 40px;
  padding: 0 24px;
  font-size: $font-size-body1;

  img {
    vertical-align: baseline;
  }
}

.Left {
  /* border-right: ${CONSTANTS.border.border1}; */
  /* margin: 80px 0; */
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  box-sizing: border-box;

  p {
    color: $text-level1;
  }
}

.ContentLeft {
  width: 100%;
  height: 100%;
  padding: 0 16px;
}

.BtnCancel {
  width: 100px;
  margin-right: 8px;
  border-color: transparent;
}

.BtnSend {
  width: 140px;
  border-color: transparent;
}

.Right {
  flex: 1;
  background-color: var(--background);
}

.ContentRight {
  padding: 16px 24px;
}

.Footer {
  display: flex;
  gap: 4px;
  justify-content: flex-end;
  align-items: center;
  margin-top: 16px;
  padding: 16px 24px;
}
