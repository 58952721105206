@import '../../../../styles/commonStyles.scss';
.SelectDestination {
  width: 414px;
}
.Destinations {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 16px;
}
.DestinationIcon {
  width: 16px;
  height: 16px;
}
.RecentItem {
  position: relative;
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: center;
  height: 64px;
  padding: 16px;
  border-radius: 8px;
  background: var(--grey3);
  cursor: pointer;
}

.RecentItemName {
  overflow: hidden;
  color: var(--text-primary1);
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-word;
}
.RecentItemNameContainer {
  display: flex;
}
.DesTitle {
  color: var(--text-primary1);
  font-weight: 500;
  font-size: $font-size-h6;
}
