@value VideoPlayerWrapper from './VideoPlayerSlot.module.scss';
@value VideoPlayerContent from './VideoPlayerSlot.module.scss';

.CssLayoutsChildren1 {
  &:nth-child(1) {
    grid-area: 1 / 1 / 2 / 2;
  }
}
.CssLayoutsChildren2 {
  &:nth-child(1) {
    grid-area: 1 / 1 / 2 / 2;
  }
  &:nth-child(2) {
    grid-area: 1 / 2 / 2 / 3;
  }
}
.CssLayoutsChildren3 {
  &:nth-child(1) {
    grid-area: 1 / 1 / 2 / 2;
  }
  &:nth-child(2) {
    grid-area: 1 / 2 / 2 / 3;
  }
  &:nth-child(3) {
    grid-area: 1 / 3 / 2 / 4;
  }
}
.CssLayoutsChildren4 {
  &:nth-child(1) {
    grid-area: 1 / 1 / 2 / 2;
  }
  &:nth-child(2) {
    grid-area: 1 / 2 / 2 / 3;
  }
  &:nth-child(3) {
    grid-area: 1 / 3 / 2 / 4;
  }
  &:nth-child(4) {
    grid-area: 1 / 4 / 2 / 5;
  }
}

.CssLayoutsChildren5 {
  &:nth-child(1) {
    grid-area: 1 / 1 / 3 / 3;
  }
  &:nth-child(2) {
    grid-area: 1 / 3 / 2 / 4;
  }
  &:nth-child(3) {
    grid-area: 1 / 4 / 2 / 5;
  }
  &:nth-child(4) {
    grid-area: 2 / 3 / 3 / 4;
  }
  &:nth-child(5) {
    grid-area: 2 / 4 / 3 / 5;
  }
}

.CssLayoutsChildren6 {
  &:nth-child(1) {
    grid-area: 1 / 1 / 3 / 2;
  }
  &:nth-child(2) {
    grid-area: 1 / 2 / 3 / 3;
  }
  &:nth-child(3) {
    grid-area: 1 / 3 / 2 / 4;
  }
  &:nth-child(4) {
    grid-area: 1 / 4 / 2 / 5;
  }
  &:nth-child(5) {
    grid-area: 2 / 3 / 3 / 4;
  }
  &:nth-child(6) {
    grid-area: 2 / 4 / 3 / 5;
  }
}

.CssLayoutsChildren7 {
  &:nth-child(1) {
    grid-area: 1 / 1 / 2 / 2;
  }
  &:nth-child(2) {
    grid-area: 1 / 2 / 2 / 3;
  }
  &:nth-child(3) {
    grid-area: 1 / 3 / 2 / 4;
  }
  &:nth-child(4) {
    grid-area: 1 / 4 / 2 / 5;
  }
  &:nth-child(5) {
    grid-area: 2 / 1 / 3 / 2;
  }
  &:nth-child(6) {
    grid-area: 2 / 2 / 3 / 3;
  }
  &:nth-child(7) {
    grid-area: 2 / 3 / 3 / 4;
  }
  &:nth-child(8) {
    grid-area: 2 / 4 / 3 / 5;
  }
}

.CssLayoutsChildren8 {
  &:nth-child(1) {
    grid-area: 1 / 1 / 3 / 2;
  }
  &:nth-child(2) {
    grid-area: 1 / 2 / 3 / 5;
  }
}
.CssLayoutsChildren9 {
}
.CssLayoutsChildren10 {
  &:nth-child(1) {
    grid-area: 1 / 1 / 2 / 2;
  }
  &:nth-child(2) {
    grid-area: 1 / 2 / 2 / 3;
  }
  &:nth-child(3) {
    grid-area: 2/1/2/2;
  }
  &:nth-child(4) {
    grid-area: 2/2/2/2;
  }
}
.StyledSlotOnLive {
  composes: StyledSlotOnLive from './SlotCommon.module.scss';
}
.styledSlotWithLayoutOther1 {
  composes: styledSlotWithLayoutOther1 from './SlotCommon.module.scss';
}
.styledSlotLayoutStyleModern {
  composes: styledSlotLayoutStyleModern from './SlotCommon.module.scss';
}
.styledSlotLayoutStyleRounded {
  composes: styledSlotLayoutStyleRounded from './SlotCommon.module.scss';
}
.styledSlotFullStream {
  display: block;
  border-radius: 0px !important;
}
.DropArea {
  composes: DropArea from './SlotCommon.module.scss';
}
.DropAreaRounded {
  composes: DropAreaRounded from './SlotCommon.module.scss';
}
.dropAreaOver {
  composes: dropAreaOver from './SlotCommon.module.scss';
}
.LiveStremChildTitle {
  composes: LiveStremChildTitle from './SlotCommon.module.scss';
}
.StyledSlot {
  composes: StyledSlot from './SlotCommon.module.scss';
}
.StyledSlotVideoContainer {
  border-radius: inherit !important;
  composes: StyledSlotMediaContainer from './SlotCommon.module.scss';
  video {
    height: 100%;
    min-height: 0;
    border-radius: inherit;
  }
}
.StyledSlotVideoContainer .VideoPlayerWrapper {
  height: 100%;
  border-radius: inherit;
}
.StyledSlotVideoContainer .VideoPlayerContent {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  height: 100%;
  border-radius: inherit;
}
.StyledSlotImageContainer {
  border-radius: inherit;
  img {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    object-fit: cover;
    width: 100%;
    max-width: 100%;
    height: 100%;
    border-radius: inherit;
  }
}

.SlotPreview {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: auto;
  height: auto;
  pointer-events: none;
}
.styleSlotInvisible {
  composes: styleSlotInvisible from './SlotCommon.module.scss';
}
.styledSlotEmpty {
  z-index: unset;
  opacity: 0;
}

.Loading {
  position: absolute;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0.6;
}
