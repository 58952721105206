@value SlotIconAction from './SlotIconAction.module.scss';

.StyledSlot {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 400;
}
.StyledSlot:hover > .SlotIconAction {
  display: flex;
}
.styleSlotInvisible {
  position: fixed;
  right: 100%;
  bottom: 100%;
  width: 1px;
  height: 1px;
  opacity: 0;
  pointer-events: none;
}
.styledSlotWithOtherTransparent {
  background-color: #2e303252;
}
.styledSlotLayoutStyleRounded {
  overflow: hidden;
  border-radius: 12px;
}
.styledSlotLayoutStyleModern {
  box-shadow: none;
}
.styledSlotOnAir {
  &::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -3px;
    z-index: 100;
    display: block;
    width: 119px;
    height: 67px;
    border: 3px solid var(--status-error) !important;
    border-radius: 8px;
    pointer-events: none;
  }
}
.styledSlotEmptyOtherViewAndDragging:hover {
  z-index: 400;
  opacity: 1;
}
.SlotSmallPreview {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 400;
  width: 115px;
  height: 64px;
  pointer-events: none;
}
.StyledSlotOnLive {
  width: 100%;
  height: 100%;
  border-radius: inherit;
}
.StyledSlotOnLive:nth-of-type(3) {
  overflow: hidden;
}
.DropArea {
  position: absolute;
  inset: -1px;
  z-index: -1;
  border: 5px solid var(--text-link);
  border-radius: inherit;
  background: rgba(117, 137, 255, 0.6);
  opacity: 0;
  pointer-events: none;
}
.DropAreaRounded {
  border-radius: 12px;
}
.dropAreaOver {
  z-index: 1000;
  opacity: 1;
}
.LiveStremChildTitle {
  position: absolute;
  z-index: 1000;
}
.StyledSlotMediaContainer {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  &:hover {
    cursor: pointer;
  }
}
