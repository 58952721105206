.Wrapper {
  position: relative;
  right: -8px;
  border-radius: 8px;
  background: var(--background13);
  box-shadow: 0px 0px 8px rgba(1, 2, 11, 0.56);

  ::-webkit-scrollbar-track {
    background-color: var(--scrollbar);
  }
  ::-webkit-scrollbar {
    display: flex;
    justify-items: center;
    width: 6px !important;
    height: 6px !important;
    background-color: var(--scrollbar);
  }
  ::-webkit-scrollbar-thumb {
    height: 50px;
    border-radius: 5px;
    background-color: var(--scrollbar-thumb);
  }
  &::before {
    content: '';
    position: absolute;
    top: calc(50% - 18px);
    left: -18px;
    width: 36px;
    height: 36px;
    background-color: var(--background-modal1);
    box-shadow: inherit;
    transform: rotate(45deg);
    clip-path: polygon(0 0, 0% 100%, 100% 100%);
  }
}
.Container {
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
  padding: 24px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 8px;
  background: var(--background-modal1);
}

:global(.ant-popover-placement-left) {
  .Wrapper {
    right: 8px;
    &::before {
      right: -18px;
      left: auto;
      clip-path: polygon(0 0, 100% 100%, 100% 0);
    }
  }
}
