.MenuContent {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 8px;
  overflow-y: scroll;
  width: 100%;
  margin-top: 16px;
  padding: 0 16px 8px 16px;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.NewChatBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.NewChatBoxAidaImage {
  width: 65px;
  height: 65px;
  margin-bottom: -15px;
}

.NewChatBoxGreet {
  margin-top: 16px;
  color: #0f0f0f;
  font-weight: 400;
  font-size: 16px;
}

.NewChatBoxAsk {
  margin-top: 8px;
  color: #808080;
  font-weight: 400;
  font-size: 13px;
}
