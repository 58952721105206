@import '../../../styles/commonStyles.scss';
.ScaleButton {
  position: absolute;
  z-index: 1002;
  width: fit-content;
  height: 8px;
}

.roundedInfo {
  position: absolute;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 8px;
  border-radius: 20px;
  background: var(--blue-contained);
  color: rgba(225, 225, 233, 1);
  font-weight: 500;
  font-size: 11px;
}

.SlotActionsOutSide {
  position: absolute;
  top: -32px;
  z-index: 999;
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 20px;
}
.EditSizeContainer {
  display: flex;
  flex-direction: row;
  gap: 4px;
}
.EditSizeLeft {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  padding: 3px 8px;
  border: 1px solid var(--blue-contained);
  border-radius: 12px;
  background: $background-level1;
}
.EditSizeButton {
  padding: 2px 8px;
  border: 1px solid var(--blue-contained);
  border-radius: 12px;
  background: $background-level1;

  &:hover {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.1),
        rgba(255, 255, 255, 0.1)
      ),
      rgba(0, 0, 0, 0.8);
  }
  &:active {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.2),
        rgba(255, 255, 255, 0.2)
      ),
      rgba(0, 0, 0, 0.8);
  }
}
.ResizeOutsideIcon {
  color: $text-level1;
}
.EditSizeLeftTy {
  color: $input-label;
  font-weight: 400;
  font-size: 11px;
  line-height: 150%;
}
.EditRowInfo {
  display: flex;
  align-items: center;
  input {
    max-width: 30px;
    margin-left: 8px;
    border: none;
    color: $text-level1;
    font-weight: 500;
    font-size: 11px;
    line-height: 150%;
  }
}
.divider {
  width: 1px;
  height: 16px;
  border: 1px solid var(--border2);
}

.SlotTopSizeInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  border-radius: 20px;
  background: var(--blue-contained);
  color: rgba(225, 225, 233, 1);
  font-weight: 500;
  font-size: 11px;
  cursor: pointer;
}
.ScaleButtonTopLeft {
  top: -11px;
  left: -9px;
  cursor: se-resize !important;
}
.ScaleButtonTopRight {
  top: -11px;
  right: -9px;
  cursor: sw-resize !important;
}
.ScaleButtonBottomLeft {
  bottom: -11px;
  left: -9px;
  cursor: sw-resize !important;
}
.ScaleButtonBottomRight {
  right: -9px;
  bottom: -11px;
  cursor: se-resize !important;
}
.ScaleEdgeTop {
  width: 100%;
  height: 20px;
  cursor: n-resize !important;
}
.ScaleEdgeLeft {
  width: 20px;
  height: 100%;
  cursor: e-resize !important;
}
.ScaleEdgeBottom {
  width: 100%;
  height: 20px;
  cursor: n-resize !important;
}
.ScaleEdgeRight {
  width: 20px;
  height: 100%;
  cursor: e-resize !important;
}
.RoundedButtonTopLeft {
  top: 14px;
  left: 14px;
  cursor: se-resize !important;
}
.RoundedButtonTopRight {
  top: 14px;
  right: 14px;
  cursor: sw-resize !important;
}
.RoundedButtonBottomLeft {
  bottom: 14px;
  left: 14px;
  cursor: ne-resize !important;
}
.RoundedButtonBottomRight {
  right: 14px;
  bottom: 14px;
  cursor: nw-resize !important;
}
.transparenEdge {
  background-color: transparent;
}
