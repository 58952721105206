.Container {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  width: 100%;
  height: 90px;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
.Loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
