@import '../../../styles/commonStyles.scss';
.WrapperModal {
  padding-bottom: 0;
  border: none;
  border-radius: 0;
  background-color: transparent;
  :global(.ant-modal-content) {
    border-radius: 0;
    background-color: transparent;
    box-shadow: none;
  }
  :global(.ant-modal-title) {
    color: #fff;
  }
  :global(.ant-modal-close) {
    color: #fff;
  }
  ModalIconClose :global(.ant-modal-body) {
    padding: 0;
    color: $text-level1;
  }
  :global(.ant-modal-header) {
    border: none;
    border-radius: 0;
    background-color: transparent;
  }
}
.ModalIconClose {
  color: var(--text-primary);
}
.ModalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  border-radius: 8px;
  background: $background-level2;
  text-align: center;
}
.ModalImg {
  margin: 24px 0;
}
.ModalText {
  margin-bottom: 40px;
  color: #ffffff;
  font-size: 16px;
}
.ModalButton {
  width: 100%;
  composes: ButtonPrimary from '../../../styles/styleForm.module.scss';
}
