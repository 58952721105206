.SlotContainer {
  position: relative;
  width: 100%;
  height: 100%;
}
.SlotContainerIsEdit {
  background: linear-gradient(
      0deg,
      rgba(9, 12, 32, 0.24),
      rgba(9, 12, 32, 0.24)
    ),
    rgba(255, 255, 255, 0.08);
  box-shadow:
    0px 0px 2px rgba(0, 0, 0, 0.25),
    inset 0px -1px 1px rgba(255, 255, 255, 0.1);
}
.Slot {
  position: absolute;
}
.SlotContainerIsEditHover:hover {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.08),
      rgba(255, 255, 255, 0.08)
    ),
    linear-gradient(0deg, rgba(9, 12, 32, 0.24), rgba(9, 12, 32, 0.24)),
    rgba(255, 255, 255, 0.08);
}
.BorderOnEdit {
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  border: 2px solid #4098ff;
  pointer-events: none;
}
