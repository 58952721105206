@import '../../../styles/commonStyles.scss';

.content {
  margin-bottom: 20px;
  color: $text-level1;
}

.list {
  margin: 10px 0;
}
.txtNote {
  margin-bottom: 5px;
}
.footer {
  display: flex;
  gap: 16px;
}
