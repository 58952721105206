.wrapper {
  display: flex;
  gap: 8px;
  align-items: center;
  .btnItem {
    height: 38px;
  }
}

.input {
  width: 200px;
}
