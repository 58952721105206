@import '../../../styles/commonStyles.scss';
.container {
  width: 420px;
  max-width: calc(100vw - 16px * 2);
  padding: 16px;
  background-color: $background-level2;
  color: $text-level1;
}
.message {
  color: $text-level1;
  font-size: 16px;
}
.close {
  color: $text-level1;
}
