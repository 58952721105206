@import '../../../styles/commonStyles.scss';

.InputContainer {
  :global(.ant-input-affix-wrapper) {
    &:hover {
      border-color: var(--bdclAntInputAffixWrapperHover);
    }
    &:focus {
      border: 1px solid var(--bdAntInputAffixWrapperFocus);
      box-shadow: 0px 0px 4px var(--bsdntInputAffixWrapperFocus);
    }
  }
  :global(.ant-input) {
    &:disabled {
      color: #7a7a7a !important;
    }
  }
  &::after {
    content: attr(data-after-content);
    float: right;
    padding-top: 4px;
    color: var(--text-secondary);
    font-size: 13px;
    white-space: nowrap;
    pointer-events: none;
  }
  :global(.toggle-password) {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  :global(.ant-input-affix-wrapper-focused) {
    box-shadow: none !important;
  }
}

.InputStyled {
  height: var(--hInputStyled);
  border: 1px solid var(--border7);
  border-radius: 8px;
  background: var(--background2) !important;
  color: var(--text-primary1) !important;
  &:focus-within {
    border: 1px solid var(--blue-contained) !important;
    box-shadow: 0px 0px 4px rgba(66, 85, 197, 0.5) !important;
    &:hover {
      border: 1px solid var(--blue-contained) !important;
    }
  }
  &:hover {
    border: 1px solid var(--border7) !important;
  }

  :global(.ant-input::placeholder) {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--text-secondary2) !important;
    font-size: $font-size-body1 !important;
    opacity: 1; /* Firefox */
  }
  :global(.ant-input:-ms-input-placeholder) {
    /* Internet Explorer 10-11 */
    color: var(--text-secondary2) !important;
    font-size: $font-size-body1 !important;
  }
  :global(.ant-input::-ms-input-placeholder) {
    /* Microsoft Edge */
    color: var(--text-secondary2) !important;
    font-size: $font-size-body1 !important;
  }
  :global(.ant-input::-ms-input-placeholder) {
    /* Microsoft Edge */
    color: var(--text-secondary2) !important;
    font-size: $font-size-body1 !important;
  }

  input {
    background: transparent;
    color: var(--text-primary1);

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 1000px #1f273f inset !important;
      font-size: $font-size-body1;
      -webkit-text-fill-color: $text-level1 !important;
    }
  }

  &:global(.ant-input-affix-wrapper-disabled) {
    background: var(--background-input-disabled) !important;
    & + .PostFixContainer {
      background: var(--background-input-disabled) !important;
      cursor: not-allowed;
    }
  }
}

.InputWithPostfixContainer {
  display: flex;
  flex-direction: row;
}
.InputWithPostFix {
  height: auto;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.PostFixContainer {
  padding: 10px 16px;
  border: 1px solid var(--border7);
  border-left: 0;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  background: var(--background2) !important;
}
