.VideoPlayerTag {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.VideoPlayerWrapper {
  position: relative;
  width: 100%;
  height: auto;
  max-height: 100%;
}
.VideoPlayerContent {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 200;
  display: flex;
  flex-direction: column;
  height: 60px;
}

.VideoPlayerOverlay {
  position: absolute;
  inset: 0;
  border-radius: inherit;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.4) 0%,
    rgba(0, 0, 0, 0) 26.04%
  );
}
