.Wrapper {
  padding: 12px 0 12px 0;
  &:hover {
    .EditButton {
      display: flex;
    }
  }
}

.Header {
  display: flex;
  align-items: center;
}
.HeaderLeft {
  display: flex;
  flex: 1;
  align-items: center;
}
.Avatar {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  border-radius: 16px;
  background-color: green;
}

.Text {
  margin-right: 3px;
  color: var(--text-secondary3);
  font-weight: 400;
  font-size: 12px;
}

.Dropdown {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 16px;
  color: rgba(96, 96, 96, 1);
  cursor: pointer;
}

.Dot {
  width: 4px;
  height: 4px;
  margin: 0 8px 0 8px;
  border-radius: 2px;
  background-color: #a1a1a1;
}

.Body {
  margin-left: 28px;
  padding-right: 5px;
  color: var(--text-primary);
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  line-height: 175%;
  user-select: text !important;
  ::selection {
    background: #c9e6fd;
    color: #0e4077;
  }
}
.Highlight {
  background: #c9e6fd;
  color: #0e4077;
}

.EditIcon {
  font-family: 'icomoon';
  &::after {
    content: '\e997';
    font-size: 12px;
  }
}
.EditButton {
  display: flex;
  display: none;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  padding: 0px;
  border-radius: 50%;
}
.editButtonShow {
  display: flex;
}
