@import '../../../styles/commonStyles.scss';

.MediaItemDelete {
  position: absolute;
  top: 4px;
  right: 8px;
  z-index: 20;
  display: block;
  padding: 7px 14px;
  border-radius: 0px 6px;
  background: $level-dark1;
  color: $text-level1;
  cursor: pointer;
}
.MediaItemPreview {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: auto;
  pointer-events: none;
}
.MediaItemDragging {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
}
.MediaItemIconPlay {
  position: absolute;
  top: 9px;
  right: 13px;
  z-index: 20;
  color: #ffffff;
}

.MediaButtonOnAir {
  z-index: 20;
  display: flex;
  align-items: center;
  padding: 2px 6px 2px 8px;
  border-radius: 14px;
  background: var(--red-contained);
  color: var(--red-on-contained);
  font-weight: 500;
  & > svg {
    padding-right: 3px;
  }
}
.MediaItemWrapper {
  position: relative;
  z-index: 10;
  width: 100px;
  height: 56px;
  padding: 3px;
  border: 1px solid var(--border1);
  border-radius: 6px;
}
.MediaItemDuration {
  position: absolute;
  right: 8px;
  bottom: 8px;
  display: block;
  padding: 4px 6px;
  border-radius: 4px;
  background: #303537;
  color: #ffffff;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
}
.MediaListNotFound {
  margin: 0;
  color: var(--text-secondary3);
}
.MediaItemContainer {
  position: relative;
  z-index: 10;
  width: 100%;
  margin-bottom: 8px;
  padding: 0 4px;
  cursor: pointer;
  user-select: none;
}
.MediaItemContainer:hover .PopoverActionMediaIcon {
  display: flex !important;
}
.PopoverActionMediaIcon {
  position: absolute;
  top: 4px;
  right: 8px;
  z-index: 20;
  display: none;
  padding: 15px 8px;
  border-radius: 0px 6px;
  background: $level-dark1;
  color: $text-level1;
  cursor: pointer;
}
.mediaItemHorizontalContainerDrophover {
  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: -1px;
    width: 2px;
    height: 100%;
    border-radius: 2px;
    background-color: var(--text-link);
  }
}
.mediaItemHorizontalContainerDropbottom {
  &::before {
    left: calc(100% - 1px);
  }
}
.mediaItemHorizontalContainerDragSelected {
  border: 2px solid var(--text-link) !important;
}
.mediaItemWrapperOnAir {
  border: 2px solid var(--status-error) !important;
}
.mediaItemWrapperDragging {
  border: none;
  color: transparent;
}
.LoadingSocial {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 20px;
  height: 20px;
  margin: -10px 0 0 -10px !important;
}
.MediaVideoButtonOnAir {
  position: absolute;
  top: 8px;
  right: 70px;
  z-index: 20;

  composes: MediaButtonOnAir;
}
.MediaPreviewTotalItem {
  position: absolute;
  top: -16px;
  right: -16px;
  z-index: 100;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: var(--status-error);
  color: #fff;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
}
.MediaItemHorizontalContainer {
  composes: MediaItemContainer;
}
.MediaItemHorizontalContainer:hover .MediaImageIconGIF {
  display: none;
}
.MediaItemVerticalContainer {
  composes: MediaItemContainer;
}

.mediaItemVerticalContainerDrophover {
  &::before {
    content: '';
    position: absolute;
    top: -5px;
    right: 0;
    left: 0;
    width: 100%;
    height: 2px;
    border-radius: 2px;
    background-color: var(--text-link);
  }
}
.mediaItemVerticalContainerDropbottom {
  &::before {
    top: calc(100% - 6px);
  }
}
.mediaItemVerticalContainerDragSelected {
  &::after {
    content: '';
    position: absolute;
    top: 0px;
    right: 1px;
    bottom: 0;
    left: 1px;
    border: 2px solid var(--text-link);
    border-radius: 8px;
  }
}
