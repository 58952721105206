@import '../../../styles/commonStyles.scss';

@value ItemAttachmentContent from "./Chat.module.scss";
@value ItemAttachmentDetailImage from "./Chat.module.scss";

.ItemChatContainer {
  position: relative;
  display: flex;
  margin-bottom: 12px;
}
.ItemChatInfo {
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
  color: var(--text-secondary);
  font-size: var(--fItemChatContent);
}
.ItemChatContent {
  display: flex;
  flex-direction: column;
  width: calc(100% - 35px);
  margin-left: 7px;
  padding: 12px;
  border-radius: 16px;
  background-color: var(--background5);
  font-size: var(--fItemChatContent);
  line-height: 150%;
}

.ItemChatName {
  color: rgba(255, 255, 255, 0.5);
  line-height: 1;
}
.ItemChatTime {
  color: rgba(255, 255, 255, 0.3);
  line-height: 1;
}
.ItemChatMessage {
  color: white;
  line-height: 1.5;
  white-space: pre-line;
  line-break: anywhere;
}

.ItemChatAttachment {
  height: 220px;
  margin-top: 12px;
}
.ItemChatAttachmentImg {
  object-fit: cover;
  width: 100%;
  height: 220px;
  cursor: pointer;
}
.ContentSliderArrow {
  position: absolute;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: var(--grey11);
}
.ContentSliderNextArrow {
  right: -15px;
  composes: ContentSliderArrow;
}
.ContentSliderPrevArrow {
  left: -15px !important;
  composes: ContentSliderArrow;
}

.LiveStreamSelectedChatContent {
  position: absolute;
  z-index: 100;
  width: auto;
  background: rgba(0, 0, 0, 0.56);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    display: none;
    border: 2px solid $status-success;
  }
}
.liveStreamSelectedChatContentShowDelete:hover {
  &:hover {
    cursor: pointer;
    &::before {
      display: block;
    }
  }
}
.ItemIconDelete {
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  padding: 4px;
  border-radius: 50%;
  background-color: var(--grey11);
  color: $icon-dark;
  box-shadow:
    0px 1px 4px 0px rgba(1, 2, 11, 0.24),
    0px 4px 12px 0px rgba(1, 2, 11, 0.12);
  transform: translate(50%, -50%);
}
.liveStreamSelectedChatContentShowDelete:hover .ItemIconDelete {
  display: flex;
}
.LiveStreamSelectedChatContent .ItemChatContainer {
  margin-bottom: 0;
}
.LiveStreamSelectedChatContent .ItemChatContent {
  width: calc(100% - var(--wItemChatContent));
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  background: transparent;
}
.LiveStreamSelectedChatContent .ItemChatAttachment {
  height: var(--hItemChatAttachment);
  margin-top: var(--mtItemChatAttachment);
}
.LiveStreamSelectedChatContent .ItemAttachmentContent {
  width: 100%;
  margin-top: var(--mtItemAttachmentContent);
  padding-left: 0;
  cursor: pointer;
}
.LiveStreamSelectedChatContent .ItemAttachmentDetailImage {
  object-fit: cover;
  height: auto;
  max-height: var(--mhItemAttachmentDetailImage);
  margin-bottom: 0;
}
.LiveStreamSelectedChatContent .ItemChatAttachmentImg {
  height: var(--hItemChatAttachmentImg);
}
.LiveStreamSelectedChatContent .ContentSliderArrow {
  width: var(--wContentSliderArrow);
  height: var(--hContentSliderArrow);
  &::before {
    font-size: var(--fContentSliderArrow);
    line-height: var(--lhContentSliderArrow);
  }
}
.LiveStreamSelectedChatContent .ContentSliderNextArrow {
  right: var(--rContentSliderNextArrow);
}
.LiveStreamSelectedChatContent .ContentSliderPrevArrow {
  left: var(--lContentSliderPrevArrow);
}

.containerSliderSelectedChat {
  margin-top: 10px;
  border-radius: 8px;
}
.containerSliderSelectedChat img {
  border-radius: 8px;
}
.Arrows {
  position: absolute;
  top: 50%;
  display: flex;
  flex-direction: row;
  width: 100%;
}
.Dots {
  position: absolute !important;
  bottom: -8px;
  display: flex !important;
  flex-direction: row;
  gap: 8px;
  justify-content: center;
  width: 100%;
  list-style-type: none;
  padding-inline-start: 0px;
  margin-block-start: 0px;
}
.Dots li {
  width: 8px;
  height: 8px;
  border: none;
  border-radius: 50%;
  background: var(--background-dot);
  color: transparent;
}

.Dots li > button {
  display: none;
}
.Dots {
  :global(.slick-active) {
    background: var(--background18);
  }
}

// fix css

.selectedChatItemChatInfo {
  color: var(--text-secondary6);
}

.selectedChatItemChatMessage {
  color: rgb(225, 225, 233);
}

.ItemChatInfo {
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
  color: var(--text-secondary);
}
.itemChatContainerOnliveStream {
  margin-bottom: var(--mbItemChatContainerOnLiveStream);
}
