@import '../../../styles/commonStyles.scss';
.DropdownVideoAction {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  margin-top: 4px;
  margin-top: 2px;
  margin-right: 4px;
  border-radius: 24px;
  background: var(--background-button-1);
  color: var(--text-primary);
  cursor: pointer;
  &:hover {
    background: var(--background-hover-dark);
  }
}

.DropdownContainer {
  position: relative;
  display: flex;
}
.DropdownHandler {
  display: flex;
  flex-direction: column;
  padding: 8px 0px;
  border-radius: 8px;
  background: var(--background-dropdown);
  box-shadow: var(--box-shadow-dropdown);
}
.DropdownWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  // width: 300px;
  height: 44px;

  padding: 14px 10px;
  background: var(--background-dropdown);
  box-shadow: var(--box-shadow-dropdown);
}
.DropdownHandlerItem {
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 8px 16px;
  color: var(--text-primary);
  cursor: pointer;
  &:hover {
    background: var(--grey2);
  }
}

.SliderAudioIcon {
  z-index: 10;
  width: 26px;
  margin-right: 5px;
  text-align: center;
  cursor: pointer;
}
.AudioPlayerProgress {
  flex: 1;
  :global(.ant-slider-rail) {
    background: var(--border);
  }
}
.DropdownVideoKickOut {
  margin-right: 6px;
  color: $status-error;
}

.SliderStep {
  display: block;
  width: 35px;
  margin-top: 2px;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
}
.DropdownVideoRecording {
  width: 16px;
  height: 16px;
  margin-top: 0px;
  margin-right: 0px;
  padding: 0;
  background-color: $background-record;
  color: $icon-white;
  &:hover {
    background: $background-record;
  }
}
.DropdownRecordWrapper {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: center;
  align-items: center;
  width: 80px;
  margin-right: 4px;
  padding: 6px 16px;
  border-radius: 32px;
  background-color: $background-record;
  cursor: pointer;
}
.RecordTime {
  color: $text-white;
  font-weight: 500;
  font-size: 13px;
}
.StopRecord {
  color: $text-white;
  font-weight: 500;
  font-size: 13px;
}
// fix css

.onOffAirDropDownSliderWrapper {
  display: flex;
  flex: 1;
  align-items: center;
  width: 120px;
}
