@import '../../../styles/commonStyles.scss';

.Container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  :global(.ant-dropdown-arrow::before) {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.16) 0%,
        rgba(255, 255, 255, 0.16) 100%
      ),
      #000 !important;
  }
  :global(.ant-tooltip-inner) {
    background-color: var(--background-menu-hover) !important;
    color: var(--text-primary) !important;
  }
}
.BlockTitle {
  display: flex;
  align-items: center;
  margin-right: 15px;
}
.Title {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 160px;
  margin: 0 10px 0 0;
  color: var(--text-primary);
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.TextTitleWrapper {
  display: flex;
  align-items: center;
}

.IconBack {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-right: 20px;
  border-radius: 48px;
  background: var(--grey3);
  color: var(--icon-color1);
  font-size: 12px;
  font-family: 'icomoon';
  cursor: pointer;
}
.IconBack:hover {
  background: var(--background-menu-hover);
}

.IconEdit {
  color: var(--text-secondary);
  cursor: pointer;
}

.ContentSubtitle {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-left: 8px;
  color: #808080;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  cursor: pointer;
}
