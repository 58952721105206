.ModalUserList {
  margin-bottom: 16px;
}

.User {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
}
.UserInfor {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 8px;
}
.UserName {
  color: var(--text-primary);
  font-size: 13px;
}

.UserOnwer {
  margin-left: 4px;
  color: var(--text-secondary11);
  font-size: 13px;
}

.UserAvatar {
  width: 28px;
  height: 28px;
  margin-right: 8px;
  border-radius: 14px;
}

.NoUser {
  padding-left: 15px;
  color: var(--text-secondary4);
  font-size: 13px;
}
