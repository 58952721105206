.WrapperPopoverContent {
  width: auto;
  min-width: 250px;
  max-width: 330px;
  padding: 16px 16px 4px;
  box-shadow: none;
}

.WrapperPopoverTitle {
  composes: WrapperPopoverTitle from './BottomPopoverMedia.module.scss';
}

.bottomPopoverVideoh5Light {
  color: #1f1f1f;
}

.bottomPopoverVideoh5Dark {
  color: var(--text-primary1);
}

.WrapperPopoverList {
  composes: WrapperPopoverList from './BottomPopoverMedia.module.scss';
}
.WrapperPopoverItem {
  composes: WrapperPopoverItem from './BottomPopoverMedia.module.scss';
}
.IconCheck {
  composes: IconCheck from './BottomPopoverMedia.module.scss';
}
.Selected {
  composes: Selected from './BottomPopoverMedia.module.scss';
}
.Dark {
  composes: Dark from './BottomPopoverMedia.module.scss';
}
