.SlotIconAction {
  position: absolute;
  z-index: 100000000;
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: var(--wSlotIconAction);
  height: var(--hSlotIconAction);
  border-radius: 50%;
  background: var(--background3);
  color: var(--icon-color1);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.4);
  cursor: pointer;

  &::before {
    line-height: var(--lhSlotIconAction);
  }
}
.SlotIconRemove {
  composes: SlotIconAction;
}
.SlotIconMinimizeScreen {
  right: var(--rSlotIconMinimizeScreen);
  bottom: var(--bSlotIconMinimizeScreen);
  composes: SlotIconAction;
}
.SlotIconFullScreen {
  right: var(--rSlotIconFullScreen);
  bottom: var(--bSlotIconFullScreen);
  composes: SlotIconAction;
}
