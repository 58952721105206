@import '../../../styles/commonStyles.scss';

.AppLayout {
  height: 100vh;
  background: var(--background3);
  background-image: var(--background-image);
  background-position: center;
  background-size: cover;
  color: var(--room-app-color);

  ::-webkit-scrollbar-track {
    background-color: var(--scrollbar);
  }
  ::-webkit-scrollbar {
    display: flex;
    justify-items: center;
    width: 6px !important;
    height: 6px !important;
    background-color: var(--scrollbar);
  }
  ::-webkit-scrollbar-thumb {
    height: 50px;
    border-radius: 5px;
    background-color: var(--scrollbar-thumb);
  }
}
.Bg {
  position: relative;
  display: flex;
  flex: 1;
  width: 100%;
  min-width: 0;
  min-width: 0;
  height: 100%;
}

.AppLayoutContent {
  position: relative;
  flex: 1;
  flex: 1;
  min-width: 0;
  min-width: 0;
  margin: 0;
  background: var(--background);
  transition: flex 0.3s ease;
}
.MainContent {
  display: flex;
  flex: 1;
  flex: 1;
  flex-direction: column;
  height: calc(100% - 68px);
}
.MainContentNotHeader {
  margin-top: 60px;
}
.MainContentPeers {
  display: flex;
  justify-content: center;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  margin: 0 auto;
  padding-top: 64px;
  padding-bottom: 16px;
}
.ScrollPeersSlot {
  display: flex;
  width: auto;
}
.ContainerPeers {
  display: flex;
}
.ContainerPeersSlot {
  display: -webkit-box;
  justify-content: center;
  overflow-x: scroll;
  overflow-y: hidden;
  max-width: 645px;
  padding-right: 5px;
  padding-right: 5px;
  padding-left: 5px;
}
.ContainerPeersSlot::-webkit-scrollbar {
  display: none;
}
.MainContentPeersSlot {
  position: relative;
  width: 115px;
  height: 64px;
  margin-top: 8px;
  &:not(:last-child) {
    margin-right: 16px;
  }
}
.HideContent {
  display: none !important;
}
.Content {
  flex: 1;
  padding: 0px 40px 0px 44px;
}
.InsightEngineNote {
  display: flex;
  justify-content: center;
}
.TranscriptInsightEngine {
  border-right: 1px solid var(--border9);
}
.MediaController {
  width: 100%;
  border-top: $border5;
  font-size: 16px;
}
.ContentContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.ContentWrapper {
  display: flex;
  overflow: auto;
  width: 100%;
  height: 100%;
}
.IconLeft {
  position: relative;
  top: 20px;
  z-index: 101;
  z-index: 101;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin-right: 10px;
  border-radius: 50%;
  color: var(--icon-color1);
  cursor: pointer;
}
.IconRight {
  position: relative;
  top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin-left: 10px;
  border-radius: 50%;
  color: var(--icon-color1);
  cursor: pointer;
}
