@import '../../../styles/commonStyles.scss';
.GraphicListItems {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 -4px;
  padding: 0 24px;
}
.GraphicItemWrapper {
  position: relative;
  display: block;
  flex: 0 1 calc(100% / 3);
  width: 100%;
  height: 88px;
  margin: 0 0 8px 0;
  padding: 0 4px;
}
.graphicItemWrapperLarge {
  height: 76px;
}
.graphicItemContentLarge {
  width: 136px;
}
.graphicItemWrapperHide {
  display: none;
}

.GraphicItemContent {
  width: 88px;
  height: 100%;
  padding: 3px;
  border: 1px solid var(--border12);
  border-radius: 8px;
}
.GraphicItemUploadContent {
  padding: 0;
  border: none;

  composes: GraphicItemContent;
}
.graphicItemWrapperDropover {
  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: -1px;
    width: 2px;
    height: 100%;
    border-radius: 2px;
    background-color: var(--text-link);
  }
}
.graphicItemWrapperDropoverDropbottom {
  &::before {
    left: calc(100% - 1px);
  }
}
.graphicItemContentSelected {
  border: 2px solid $status-error;
}

.graphicItemContentDragging {
  position: relative;
  border: none;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 8px;
    background-color: var(--background8);
  }
  > div {
    opacity: 0;
  }
}
.LoadingSocial {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 20px;
  height: 20px;
  margin: -10px 0 0 -10px !important;
}
.ItemToggleButton {
  position: relative;
  height: 100%;
}
.ImageButtonGraphic {
  position: relative;
  display: flex;
  justify-content: center;
  width: auto;
  height: 100%;
  padding: 10px;
  border-radius: 6px;
  text-align: center;
  &:hover {
    cursor: pointer;
  }
  & img {
    display: block;
    object-fit: contain;
    max-width: 100%;
    height: auto;
  }
}
.imageButtonGraphicLarge {
  padding: 0;
  & img {
    // TODO: Overlay
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 6px;
  }
}
.BlockOnAir {
  position: absolute;
  top: 3px;
  left: 3px;
  padding: 2px 6px 2px 4px;
  border-radius: 20px;
  background: var(--red-contained);
  color: var(--red-on-contained);
}
.BlockAction {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 6px;
  background-color: var(--background17);
  opacity: 0;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
}
.blockActionSelected {
  background-color: var(--status-error1);
}
.blockActionDragging:hover {
  opacity: 0;
}
.ButtonToggle {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 8px;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: auto;
  padding: 0;
  border: none;
  background: transparent;
  color: #fff;
  box-shadow: none;
  text-align: center;
  cursor: pointer;
}
.BlockRemove {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px 12px;
  border-radius: 0 6px;
  background: var(--background18);
  color: var(--icon-color2);
  line-height: 1;
  cursor: pointer;
  :global(.ant-icon) {
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.3);
    color: var(--icon-color2);
    font-size: 18px;
  }
}
.BlockRemoveSelected {
  background: var(--status-error2);
}

.GraphicItemPreview {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 88px;
  height: 76px;
  pointer-events: none;
}
.graphicItemPreviewLarge {
  width: 136px;
  height: 76px;
}
.GraphicItemContentPreview {
  background: var(--background9) !important;
}
