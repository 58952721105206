.Wrapper {
  width: 560px;
}
.Title {
  margin-bottom: 24px;
}
.ContentWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;
}
.boldText {
  font-weight: 500;
}
.DescriptionContainer {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  margin-bottom: 8px;
}
.description {
  color: var(--text-secondary6);
}
.meetIcon {
  width: 16px;
  height: 16px;
}
.ModalAction {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
}
.confirmBtn {
  width: 120px;
}
.Toast {
  display: flex;
  flex-direction: column;
  gap: 4px;
  text-align: left;
}
.mainDescription {
  color: var(--text-secondary3) !important;
}
.subDescription {
  color: var(--text-primary) !important;
  font-weight: 400 !important;
}
.ModalFormBtnCancel {
  display: flex;
  align-items: center;
  margin-right: 8px;
  padding: 0px 16px;
  border-radius: 32px;
  color: var(--text-primary);
  font-weight: 500;
}
