@import '../../../styles/commonStyles.scss';

.Wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  padding: 12px 16px 12px 24px;
  background-color: #c6c2c28c;
  color: var(--text-primary);
}

.Header {
  position: relative;
  display: flex;
  align-items: center;
  padding-bottom: 8px;
}

.Body {
  overflow-y: scroll;
  height: 100%;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.Text {
  margin-right: 24px;
  // color: #0f0f0f;
  font-weight: 400;
  font-size: 16px;
}

.Search {
  width: 215px;
  height: 32px;
  margin-right: 8px;
  border: $border5;
  border-radius: 8px;
  background: #fff !important;

  &:hover {
    border: $border5 !important;
  }

  :global(.ant-input) {
    color: $icon-dark !important;

    &::placeholder {
      color: $text-body-600 !important;
    }
  }
}

.searchIcon {
  position: absolute;
  right: 52px;
  z-index: 100;
  display: inline-flex;
  color: #808080;
}

.LightIcon {
  composes: PlusIcon from './InsightMedia.module.scss';
}

.Spin {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.MoreTranscript {
  display: flex;
  justify-content: center;
  align-items: center;
}

.IconColor {
  color: #0f0f0f;
}

.iconDisabled {
  cursor: not-allowed;
  &:hover {
    opacity: 0.7;
  }
}
.Transcripts {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
