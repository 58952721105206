@import '../../../styles/commonStyles.scss';

.PaginationIcon {
  display: inline-flex;
  color: $text-level1;
}

.JumpButton {
  color: var(--grey20);
}

.PaginationWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  margin-right: 15px;
  margin-left: 64px;
  :global(.ant-pagination) {
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }

  :global(.ant-pagination-item) {
    border: none !important;
    background-color: transparent !important;
  }

  :global(.ant-pagination-item-active) {
    border: none;
  }

  :global(.ant-pagination-options) {
    display: flex;
    flex: 1;
    order: -1;
    margin-left: 8px;
    :global(.ant-select-dropdown) {
      padding: 0;
      border-radius: 8px;
      background: var(--background-modal);
    }
    :global(.ant-select-item) {
      background: var(--background-modal);
      color: $text-level1 !important;
    }
    :global(.ant-select-item-option) {
      &:hover {
        background: var(--grey8) !important ;
      }
    }
    :global(.ant-select-item-option-selected) {
      background: var(--grey8) !important ;
    }

    :global(.ant-pagination-options-size-changer) {
      display: flex;
      :global(.ant-select-selector) {
        position: relative;
        width: 80px;
        border: $border1;
        border-radius: 8px;
        background: $level-dark3;
        color: $text-level1;
        :global(.ant-select-selection-search) {
          &::after {
            content: '\e985';
            position: absolute;
            top: 50%;
            color: $dark-text-body700;
            font-size: 16px;
            font-family: 'icomoon';
            transform: translateY(-50%);
          }
        }
      }
      :global(.ant-select-arrow) {
        display: none;
      }
    }
    :global(.ant-pagination-options-quick-jumper) {
      position: relative;
      flex: 1;
      color: $text-level1;
      font-size: 13px;
      text-align: right;

      input {
        margin-right: 32px;
        border: $border1;
        border-radius: 8px;
        background: $level-dark3;
        color: $text-level1;
      }
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 16px;
        display: block;
        width: 1px;
        height: 32px;
        background-color: $level-lighter2;
      }
    }
  }
}

.disabled {
  opacity: 0.5;
}

.PageSizerText {
  display: flex;
  align-items: center;
  font-weight: 400;
}

.paginationItem {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 16px !important;
  background-color: var(--background12) !important;
}

.paginationItemSelected {
  border: 1px solid #4098ff;
}
