@import '../../../styles/commonStyles.scss';
.ContainerTitles {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  padding: 8px 0px;
}
.ContainerTitlesHeader {
  display: flex;
  margin: 16px 24px 12px 24px;
  composes: RightTabBarHeaderTitle from './RightTabBar.module.scss';
}
.Buttons {
  display: flex;
  flex: 1;
  gap: 8px;
  justify-content: flex-end;
}
.TitleAction {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 0px;
  border-radius: 50%;
  color: black;
}
.ContainerContent {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  margin-top: 12px;
  padding-bottom: 80px;
  border-top: 1px solid var(--border9);
}
.ListTitle {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 20px;
  padding: 0px 24px;
}
.FieldTitle {
  width: 100%;
  margin-bottom: 8px;
  padding: 10px 12px;
  border: none;
  border: 1px solid var(--border11) !important;
  border-radius: 8px;
  background: var(--background2);
  color: var(--text-primary1);
  box-shadow: none;
  &::placeholder,
  &::-webkit-input-placeholder {
    color: var(--text-secondary);
  }
  &:hover {
    border-bottom: 1px solid var(--border2);
  }
  &:focus {
    box-shadow: none;
  }
}

.FormAddTitleContent {
  padding-top: 20px;
  padding-right: 24px;
  padding-left: 24px;
}
.ContentFormAdd {
  position: relative;
  padding: 12px;
  border-radius: 8px;
  border-radius: 8px;
  background: var(--background5);
}
.FieldAddTitle {
  margin-bottom: 4px;
  composes: FieldTitle;

  &::placeholder {
    color: var(--text-secondary2);
  }
}

.FieldAddTitle {
  margin-bottom: 4px;
  composes: FieldTitle;
}
.FieldAddTitleError {
  border: 1px solid var(--status-error2) !important;
  background: var(--background2) !important;
}

.BtnFormTitle {
  width: 80px;
  margin-top: 6px;
  margin-right: 6px;
  padding: 6px 16px;
  border: none;
  border-radius: 32px;
  background: $text-primary;
  color: #fff;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  &:hover {
    background: $text-primary;
  }
}
.BtnAddFormTitle {
  margin-right: 0;
  composes: BtnFormTitle;
  &:hover {
    background: var(--background-hover-primary);
  }
  &:active {
    background: var(--background-active-primary);
  }
}

.FieldTitleBottomWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 8px;
  font-size: 12px;
}
.TextError {
  flex: 1;
  margin-left: 8px;
  color: var(--status-error1);
}

.NotitleText {
  color: var(--tab-panel-title);
}

//fix css

.FieldTitleItemCount {
  display: block;
  color: var(--text-secondary2);
  line-height: 150%;
  text-align: right;
}
.LayoutInfo {
  flex: 1;
  color: var(--text-primary);
}
.SubHeader {
  display: flex;
  align-items: center;
  margin: 8px 24px 0px 24px;
}
