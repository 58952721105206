.BlurItem {
  display: flex;
  min-height: 22px;
  padding: 0px 12px 0px 0px;
  border-bottom: 1px solid var(--background-layout-small-hover);
  cursor: pointer;
}
.LeftElement {
  width: 125px;
  border-right: 1px solid var(--background-layout-small-hover);
}
.BlurName {
  display: flex;
  justify-content: space-between;
  padding: 2px 12px 2px 8px;
  color: var(--text-secondary);
  font-size: 11px;
}
.IconDelete {
  color: #ea4335;
}
.RightElement {
  position: relative;
  flex: 1;
  width: 100%;
  padding: 4px;
}
.addBlurElement {
  min-height: 17px !important;
  padding-right: 0px !important;
}
.AddButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 2px 12px 2px 8px;
  color: var(--text-primary);
  cursor: pointer;
  composes: IconFont from '../../../styles/iconFont.module.scss';
  &::before {
    content: '\e95d';
  }
  &:hover {
    background: linear-gradient(
        0deg,
        rgba(8, 12, 49, 0.04),
        rgba(8, 12, 49, 0.04)
      ),
      #f3f2f2;
  }
}
.BaseBlurItemSelected {
  background: var(--background-layout-small-hover);
}
.ActionPopOverWrapper {
  display: flex;
  flex-direction: column;
  padding: 8px;
  border-radius: 8px;
  background: var(--background13);
  box-shadow:
    0px 1px 4px 0px rgba(1, 2, 11, 0.24),
    0px 4px 12px 0px rgba(1, 2, 11, 0.12);
}

.ActionPopOverItem {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  &:hover {
    background: var(--background-option-hover);
  }
  &:active {
    background: var(--background-option-pressed);
  }
}
