.WrapperPopoverContent {
  composes: WrapperPopoverContent from './BottomPopoverMedia.module.scss';
}
.PopoverItem {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  color: var(--text-primary);
  line-height: 19px;
  cursor: pointer;
}

.Recording {
  color: var(--status-error2);
  svg {
    fill: var(--status-error2) !important;
  }
}
