@import '../../../styles/commonStyles.scss';
.LayoutList {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
}
.LayoutIconEdit {
  position: absolute;
  top: 3px;
  right: 3px;
  z-index: 10;
  display: none;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--background3);
  color: var(--icon-color1);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.4);
  text-align: center;
  cursor: pointer;

  &:hover {
    background: var(--background20) !important;
  }
  &:active {
    background-color: var(--background19) !important;
  }
}
.LayoutListItem {
  position: relative;
  width: auto;
  // margin-right: 8px;
  text-align: center;
  cursor: pointer;
}
.layoutListItemPreview {
  margin-right: 0;
}
.LayoutListItem:hover .LayoutIconEdit {
  display: flex;
}
.layoutListItemOpenSetting .LayoutIconEdit {
  display: flex;
}

.PopoverListLayoutContent {
  width: 350px;
  height: 269px;
  ::-webkit-scrollbar-track {
    background-color: var(--scrollbar);
  }
  ::-webkit-scrollbar {
    display: flex;
    justify-items: center;
    width: 6px !important;
    height: 6px !important;
    background-color: var(--scrollbar);
  }
  ::-webkit-scrollbar-thumb {
    height: 50px;
    border-radius: 5px;
    background-color: var(--scrollbar-thumb);
  }
}
.divider {
  width: 100%;
  height: 1px;
  background: var(--border);
}
.PopoverHeader {
  display: flex;
  align-items: center;
  padding-right: 16px;
}
.PopoverTitle {
  flex: 1;
  margin-bottom: 16px;
  padding: 16px 16px 0px 16px;
  color: var(--text-primary1);
  font-size: 14px;
  line-height: 150%;
}
.PlusIcon {
  color: var(--text-primary);
  cursor: pointer;
}

.PopoverList {
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  margin: 0;
  padding: 8px 16px 16px 16px;
}
.PopoverLayoutItem {
  padding: 8px 8px;
}
.PopoverSetting {
  display: flex;
  justify-content: center;
  align-items: center;
  // margin-right: 8px;
  padding-right: 8px;
  border-right: 1px solid var(--grey12);
  text-align: center;
}
.PopoverSettingContent {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 100%;
  height: 49px;
  cursor: pointer;
  &:hover {
    border-radius: 2px;
    background: var(--background21);
    .PopoverIconSetting {
      color: var(--icon-color5);
    }
  }
}
.popoverSettingContentSelected {
  border-radius: 2px;
  background: var(--background22) !important;
  .PopoverIconSetting {
    color: var(--icon-color4) !important;
  }
}
.PopoverIconSetting {
  color: var(--icon-color3);
}

.PopoverWrapperSetting {
  width: 290px;
  border-radius: 8px;
  background: var(--background13);
}
.PopoverContainerSetting {
  padding: 16px;
  border-radius: 8px;
  background: var(--background-modal);
}
.PopoverSettingBlock {
  // margin-bottom: 24px;
  padding-bottom: 8px;
  // border-bottom: 1px solid var(--grey13);
}

.PopoverSettingTitle {
  margin-bottom: 16px;
  color: var(--text-primary1);
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}
.PopoverSettingItems {
  padding-left: 16px;
  font-size: 13px;
}
.PopoverSettingItem {
  display: flex;
  align-items: center;
}
.PopoverSettingSwitch {
  align-items: center;
  margin-bottom: 16px;
  font-size: 13px;
  line-height: 150%;
  composes: PopoverSettingItem;
}
.PopoverItemBeamSwitch {
  margin-right: 8px;
  color: #ffffff;
}
.LabelIconTooltip {
  align-self: center;
  margin-left: 5px;
  cursor: pointer;
}
.PopoverIconTooltipDark {
  color: $text-level4;
}
.PopoverIconTooltipLight {
  color: #b6b6b6;
}

.PopovercolorLight {
  color: #373737;
}
.PopovercolorDark {
  color: rgba(225, 225, 233, 0.75);
}
.layoutIcon {
  position: absolute;
  inset: 0.7px;
  border-radius: inherit;
  background: var(--background-slot-small);
}

.LayoutContainerSelected {
  background: var(--background-layout-small-active) !important;
  .layoutIcon {
    background: var(--background-slot-small-active) !important;
  }
}
.LayoutContainer {
  border-radius: 4px;
  background: var(--background-layout-small);
  &:hover {
    background: var(--background-layout-small-hover);
    .layoutIcon {
      background: var(--background-slot-small-hover);
    }
    .RemoveLayoutButton {
      display: flex;
    }
  }
}

.RemoveIcon {
  color: #ffffff;
}
.RemoveLayoutButton {
  position: absolute;
  right: 4px;
  z-index: 100;
  display: none;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.8) !important;
  &:hover {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.2),
        rgba(255, 255, 255, 0.2)
      ),
      rgba(0, 0, 0, 0.8) !important;
  }
  &:active {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.08),
        rgba(255, 255, 255, 0.08)
      ),
      rgba(0, 0, 0, 0.8) !important;
  }
}

// fix css

.liveStreamLayoutsh5Light {
  color: #1f1f1f;
}

.liveStreamLayoutsh5Dark {
  color: var(--text-primary1);
}

.liveStreamLayoutTemplateHeight {
  height: 216px;
}

.popOverSettingTitleLight {
  color: #1f1f1f;
}

.popOverSettingTitleDark {
  color: var(--text-primary1);
}

.PopoverSettingRatio {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.AspectRatioContainer {
  padding: 16px;
  background: var(--background-modal);
}

.AspectRatioList {
  padding-left: 16px;
}

.AspectRatioItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  color: var(--text-primary1);
  font-size: $font-size-body1;
  line-height: 19px;
  cursor: pointer;
  .IconCheck {
    min-width: 16px;
    height: 19px;
    margin-left: 8px;
    color: #2656c9;
    & > * {
      display: none !important;
    }
  }
  &.Dark {
    color: rgba(225, 225, 233, 0.75);
    .IconCheck {
      color: var(--text-primary1);
    }
  }
  &.AspectRatioItemSelected {
    cursor: default;
    .IconCheck > * {
      display: inline-block !important;
    }
  }
}

.DividerRatio {
  width: 100%;
  height: 1px;
  margin-bottom: 8px;
  background: var(--border);
}

.CustomRatio {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-top: 8px;
}
.CustomRatioTitle {
  display: flex;
  justify-content: space-between;
}
.CustomRatioInput {
  display: flex;
  justify-content: center;
  width: 47px;
  height: 32px;
  :global(.ant-input) {
    font-size: 13px;
  }
  input[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}
.Error {
  color: var(--status-error);
}
.RatioIcon {
  margin-right: 14px;
  color: #ccc;
}

.RatioCustomLeft {
  display: flex;
  align-items: center;
}
.RatioCustomLeftDescription {
  display: flex;
  flex-direction: column;
}
.RatioIconSelect {
  margin-right: 8px;
  color: #ccc;
}
.RatioName {
  font-weight: 500;
}
.RatioDescription {
  color: var(--grey17);
  font-size: 12px;
}
