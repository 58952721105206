.ModeButtons {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 44px;
  margin-bottom: 16px;
  padding: 2px;
  border-radius: 8px;
  background: var(--grey8);
}
.Item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  border-radius: 6px;
}
.ItemSelected {
  background: var(--icon-color2);
  .Icon {
    fill: #000000 !important;
  }
}
