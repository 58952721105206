@import '../../../styles/commonStyles.scss';
.ScheduleSessionWrapper {
  display: flex;
  overflow-y: auto;
  width: 770px;
  padding: 16px;
  border-radius: 8px;
}
.ModalContainer {
  padding: 0px !important;
}
.ScheduleSessionWrapperDark {
  background: #1d1e25;
}
.ScheduleSessionContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.Title {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  font-weight: 500;
}

.Warning {
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 8px;
}
.WarningLink {
  color: var(--text-link);
  font-weight: 500;
  font-size: $font-size-body1;
  text-decoration: underline;
  cursor: pointer;
}
// .Info {
//   display: flex;
//   align-items: center;
//   margin-bottom: 8px;
// }
// .InfoImg {
//   width: 14px;
//   height: 14px;
//   margin-right: 8px;
// }
// .UpgradePlan {
//   text-decoration: underline;
//   cursor: pointer;
// }

.CheckAutoStart {
  display: flex;
  align-items: center;
}
.Actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
}
.BtnDelete {
  display: flex;
  align-items: center;
  padding: 9px;
  padding-right: 20px;
  padding-left: 20px;
  border-color: transparent;
  border-radius: 30px;
}
.BtnSave {
  width: 140px;
  margin-left: 8px;
  border-color: transparent;
  border-radius: 30px;
}
.TimeIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin-right: 8px;
  border-radius: 50%;
  background: var(--background6);
  color: var(--text-link);
}

// fix css

.scheduleSessionBeamText {
  font-size: 16px;
}

.scheduleSessionBeamCheckbox {
  margin-right: 8px;
}

.scheduleSessionBeamTextAutoStartLight {
  color: #303030;
}

.scheduleSessionBeamTextAutoStartDark {
  color: var(--text-primary1);
}
