@import '../../../styles/commonStyles.scss';

.ActionBtn {
  display: flex;
  gap: 8px;
  align-items: center;
  padding-right: 15px;
  padding-left: 15px;
  border-radius: 20px;
  color: var(--text-primary1);
  font-weight: 500;
}

.ModalUserListHost {
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: scroll;
  max-height: 482px;
  margin-bottom: 16px;
  padding: 0 16px;
}

.MainRoom,
.ObserverRoom {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  border: 1px solid var(--border5);
  border-radius: 8px;
  background-color: var(--background8);
}

.UserListTitle {
  color: var(--text-primary);
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
}

.ModalFieldEmailHost {
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
}

.ModalFormItemHost {
  flex-grow: 1;
  height: 40px;
  margin-bottom: 0;
}
