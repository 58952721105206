.optionTransparent {
  display: flex;
  gap: 8px;
  padding: 17px;
}
.ColorPickerContentDisabled {
  pointer-events: none;
}
.ColorPickerItemInput {
  padding: 0 2px;
}
.InputColor {
  max-width: 50px;
}
.ColorPickerContent {
  composes: ColorPickerContent from './GraphicColorCommon.module.scss';
}
.ColorPickerInput {
  composes: ColorPickerInput from './GraphicColorCommon.module.scss';
}
.ColorPickerHexInput {
  composes: ColorPickerHexInput from './GraphicColorCommon.module.scss';
}
.LabelPicker {
  composes: LabelPicker from './GraphicColorCommon.module.scss';
}
.LabelPickerLight {
  composes: LabelPickerLight from './GraphicColorCommon.module.scss';
}
