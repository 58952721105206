.RecordVideosWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: auto;
  width: 665px;
  padding: 16px;
  border-radius: 8px;
}
.ModalContainer {
  padding: 0px !important;
}
.RecordVideosWrapperDark {
  background: #1d1e25;
}
.RecordActions {
  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
}
.CancelButton {
  padding: 9px;
  padding-right: 15px;
  padding-left: 15px;
  border-radius: 20px;
}
.ModalHeader {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}
.IconRecord {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin-right: 8px;
  border-radius: 50%;
  background: var(--background6);
  color: var(--text-link);
}
.MainContentPeersSlot {
  position: relative;
  width: 200px;
  height: 112px;
}
.VideoInputsList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  min-height: 200px;
  max-height: 350px;
}
.NotData {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  color: var(--text-primary);
}
