@import '../../../styles/commonStyles.scss';
.ColorPickerHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}
.ColorPickerTab {
  display: flex;
  padding: 2px;
  border-radius: 8px;
  background: var(--background7);
}
.ColorPickerTabLight {
  background: linear-gradient(
      0deg,
      rgba(8, 12, 49, 0.04),
      rgba(8, 12, 49, 0.04)
    ),
    #f3f2f2;
}
.ColorPickerType {
  padding: 6px 12px;
  border-radius: 8px;
  background-color: 'transparent';
  color: var(--text-secondary1);
  cursor: pointer;
}

.colorPickerTypeActive {
  background-color: $level-lighter2;
  color: var(--text-primary1) !important;

  cursor: default;
}
.colorPickerTypeActiveLight {
  background-color: #ffffff;
  color: #0f0f0f !important;
  font-weight: 500;
}
.ColorPickerAdd {
  color: $text-level1;
  cursor: pointer;
}
.ColorPickerAddLight {
  color: #0f0f0f;
}
