@import '../../../styles/commonStyles.scss';

.PopoverBuyMore {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  border-radius: 8px;
  background: $background-buy-more-price-popover;
}

.PopoverBuyMoreItem {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-self: start;
  width: 100%;
}

.ItemTitle {
  color: $dark-text-body700;
  font-weight: 500;
}

.ValueWrapper {
  display: flex;
  gap: 10px;
  align-items: flex-start;
  align-self: stretch;
  padding: 8px 16px;
  border-radius: 8px;
  background: $level-dark3;
}

.ValueText {
  color: $text-level1;
  font-weight: 500;
}

.OverlayWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

:global(.ant-popover-placement-left) {
  .ArrowLefContainer {
    order: 1;
  }

  .ArrowLeft {
    border-right: solid 12px transparent;
    border-left: solid 12px #000;
  }

  .ArrowLeftIcon {
    border-right: solid 12px transparent;
    border-left: solid 12px rgba(255, 255, 255, 0.16);
  }
}

.ArrowLefContainer {
  display: flex;
  align-items: center;
  height: 100%;
}

.ArrowLeft {
  position: relative;
  width: 24px;
  height: 18px;
  border-top: solid 12px transparent;
  border-right: solid 12px #000;
  border-bottom: solid 12px transparent;
  border-left: solid 12px transparent;
  background-color: transparent;
}

.ArrowLeftIcon {
  position: absolute;
  top: -12px;
  left: -12px;
  display: block;
  width: 24px;
  height: 18px;
  border-top: solid 12px transparent;
  border-right: solid 12px rgba(255, 255, 255, 0.16);
  border-bottom: solid 12px transparent;
  border-left: solid 12px transparent;
  background-color: transparent;
}
