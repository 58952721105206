.MeasureLayoutBox {
  display: flex;
  justify-content: center;
  min-width: 65px;
  padding: 5px 12px;
  border-radius: 32px;
  background: #ff8ff4;
}
.MeasureLayoutSizeText {
  color: #621e80;
  font-size: 12px;
}
