.Button {
  display: flex;
  gap: 2px;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 8px;
  background: var(--background30);
  cursor: pointer;
}

.Radius {
  border-radius: 8px;
}

.Circle {
  border-radius: 50%;
}

.Primary {
  background: var(--background18);
  color: #ffffff;

  &:hover {
    border: var(--background-hover-primary);
    background: var(--background-hover-primary);
    color: #ffffff;
  }

  &:active {
    border: var(--background-active-primary);
    background: var(--background-active-primary);
    color: #ffffff;
  }

  &:focus {
    border: var(--background18);
    background: var(--background18);
    color: #ffffff;
  }

  &:disabled {
    border: var(--background-active-primary);
    background: var(--background-active-primary) !important;
    color: #ffffff;
  }
}

.Dark {
  background: var(--button-dark);
  color: var(--text-primary);

  &:hover {
    border: var(--button-dark-hover) !important;
    background: var(--button-dark--hover) !important;
  }

  &:active {
    border: var(--button-dark--active);
    background: var(--button-dark--active);
  }

  &:focus {
    border: var(--button-dark);
    background: var(--button-dark);
  }

  &:disabled {
    border: var(--button-dark);
    background: var(--button-dark) !important;
  }

  &:focus:not(:active) {
    border: var(--button-grey);
    background: var(--button-grey);
  }
}

.Grey {
  border: var(--button-grey);
  background: var(--button-grey);
  color: var(--text-primary);

  &:hover {
    border: var(--button-grey-hover);
    background: var(--button-grey-hover);
  }

  &:active {
    border: var(--button-grey-active);
    background: var(--button-grey-active);
  }

  &:disabled {
    border: var(--button-grey-disabled);
    background: var(--button-grey-disabled) !important;
    color: var(--text-primary1);
  }

  &:focus:not(:active) {
    border: var(--button-grey);
    background: var(--button-grey);
  }

  &:focus {
    color: var(--text-primary);
  }
}
