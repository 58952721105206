.Wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  video {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

.Loading {
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #c6c2c28c;
}

.LoadingNoVideo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #c6c2c28c;
  color: var(--text-primary);
}
