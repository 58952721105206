@import '../../../styles/commonStyles.scss';

.ItemChatActionContent {
  padding: 16px;
  border-radius: 8px;
}
.ItemChatActionDelete {
  display: flex;
  gap: 14px;
  align-items: center;
  cursor: pointer;
}
.ItemAttachOtherButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--grey11);
  box-shadow: var(--shadow);
  cursor: pointer;
}
.ItemAttachOtherDel {
  margin-right: 8px;
  color: var(--text-primary1);
  composes: ItemAttachOtherButton;
}
.ItemAttachOtherDownload {
  color: var(--text-primary1);
  composes: ItemAttachOtherButton;
}

.ItemAttachOtherAction {
  position: absolute;
  top: 50%;
  right: 12px;
  display: none;
  transform: translateY(-50%);
}
.itemAttachOtherActionShow {
  display: flex;
}
.ItemAttachmentDetail {
  flex: 0 1 calc(100% / 3);
  margin-bottom: 14px;
  padding: 0 2px;
}
.ItemAttachmentDetailImage {
  position: relative;
  composes: ItemAttachmentDetail;
  &:hover {
    cursor: pointer;
  }
}
.ItemAttachmentDetailImage .ItemAttachOtherAction {
  top: 2px;
  right: 4px;
  flex-direction: row-reverse;
  transform: translateY(0);
}
.ItemAttachmentDetailImage .ItemAttachOtherDownload {
  background: var(--grey11);
}
.ItemAttachmentDetailImage .ItemAttachOtherDel {
  margin-right: 0;
  margin-left: 4px;
  background: var(--grey11);
}
.ItemAttachmentDetailImage:hover > .ItemAttachOtherAction {
  display: flex;
}
.ItemAttachmentContent {
  display: flex;
  flex-direction: column;
  width: calc(100% - 35px);
  margin-top: 2px;
  margin-left: 35px;
  padding: 12px;
  padding-bottom: 0;
  border-radius: 16px;
  background-color: var(--background5);
  font-size: $font-size-body1;
  line-height: 150%;

  img {
    object-fit: cover;
    width: 100%;
    max-width: 100%;
    height: 71px;
    border-radius: 8px;
  }
}
.ItemAttachmentFileContent {
  margin-left: 0;
  padding: 8px 68px 8px 36px;
  composes: ItemAttachmentContent;
  .ItemAttachmentIcon {
    position: absolute;
    top: 50%;
    left: 12px;
    color: $text-level1;
    transform: translateY(-50%);
  }
}
.ItemAttachmentFileContent .ItemAttachmentDetail {
  margin-bottom: 0;
}
.ItemAttachmentFileContent:hover > .ItemAttachOtherAction {
  display: flex;
}
.ItemAttachOtherName {
  display: block;
  margin-bottom: 2px;
  color: $text-level1;
  white-space: nowrap;
}
.ItemAttachOtherSize {
  display: block;
  color: $text-level3;
  font-size: 12px;
  line-height: 150%;
}
.ItemAttachmentList {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -2px;
}
.itemAttachmentListLarge ~ .ItemAttachmentDetailImage {
  flex: 0 1 100%;
  img {
    height: 100%;
    cursor: pointer;
  }
}
.itemAttachmentOnlyImage ~ .ItemAttachmentDetail {
  flex: 0 1 100%;
}

.WapperModalAttachment {
  padding-bottom: 0;
  :global(.ant-modal-body) {
    padding: 0;
  }

  :global(.ant-modal-content) {
    border-radius: 0;
    background-color: transparent;
    box-shadow: none;
  }
}
.ContainerModalAttachment {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 16px;
}
.AttachmentModalHeader {
  position: absolute;
  top: 16px;
  right: 16px;
}
.AttachmentModalButton {
  display: inline-flex;
  align-items: center;
  margin-left: 8px;
  padding: 6px 12px;
  border-radius: 24px;
  background: rgba(255, 255, 255, 0.1);
  color: #e1e1e1;
  cursor: pointer;
  & > svg {
    margin-right: 11px;
  }
  &:hover {
    background: #1b1b1c;
  }
  &:active {
    background: #393939;
  }
}
.AttachmentDownload {
  composes: AttachmentModalButton;
}
.AttachmentDelete {
  composes: AttachmentModalButton;
}
.AttachmentClose {
  padding: 10px 12px;
  composes: AttachmentModalButton;
  & > svg {
    margin-right: 0;
  }
}
.AttachmentModalContent {
  position: relative;
  width: auto;
  max-width: 1920px;
  max-height: calc(100vh - 220px);
  margin: auto;
  text-align: center;
  &::before {
    content: '';
    display: inline-block;
    width: 1px;
    height: 50%;
    margin-right: -1px;
  }
  img {
    vertical-align: middle;
    max-width: 100%;
    max-height: 100%;
  }
  span {
    display: block;
    margin-top: 24px;
    color: $text-level1;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
  }
}
.WrapperAttachmentClickable {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

// fix css

.chatAttachmentsWrapper {
  margin-left: 0;
  padding: 0;
  background: transparent;
}
