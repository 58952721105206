@import '../../../styles/commonStyles.scss';

.Dropdown {
  composes: CustomDropdown from '../../../styles/customDropdown.module.scss';
}
.Prefix {
  composes: Prefix from '../../../styles/customDropdown.module.scss';
}
.IconArrow {
  composes: IconArrow from '../../../styles/customDropdown.module.scss';
}

.Menu {
  background: var(--background41);
  box-shadow: var(--dropdown-box-shadow);
}
.MenuItem {
  :global(.ant-dropdown-menu-title-content) {
    color: var(--text-primary1);
  }
}
