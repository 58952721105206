@import '../../../styles/commonStyles.scss';
.MediaView {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 2px solid transparent;
  border-radius: 8px;
  cursor: pointer;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: none;
    background: rgba(0, 0, 0, 0.8);
  }
}
.mediaScreenshareLayoutStyle {
  video {
    border-radius: 12px;
  }
}
.CheckedVideo {
  border: 2px solid var(--text-link);
}
.MediaMask {
  position: relative;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  opacity: 0;
}
.MediaViewPeerName {
  position: absolute;
  bottom: 2px;
  left: 5px;
  z-index: 500;
  overflow: hidden;
  width: calc(100% - 5px);
  color: $text-level1;
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
  text-shadow:
    0px 0px 2px rgba(0, 0, 0, 0.25),
    0px 0px 8px #000000;
}
.RecordCheckbox {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 500;
  padding: 6px;
  border-radius: 8px 0 8px 0;
  background-color: #efeff0;
  :global(.ant-checkbox-inner) {
    border: $border5 !important;
  }
}
.Indicator {
  position: absolute;
  top: 4px;
  right: 4px;
  z-index: 2;
  display: flex;
}
