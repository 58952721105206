@import '../../../styles/commonStyles.scss';
.Wrapper {
  position: fixed;
  bottom: 0;
  z-index: 993;
  width: 100%;
  min-height: 68px;
  box-shadow: var(--box-shadow-room-bottom);
}
.Container {
  width: 100%;
  height: 100%;
  background: var(--background);
}
.Content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 68px;
  padding: 0px 17px;
}
.LeftControl {
  position: absolute;
  left: 24px;
  display: flex;
}
.IconAudioMain {
  color: var(--green);
}
.IconCameraMain {
  color: var(--green);
}
.MainControl {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.IconShareScreen {
  color: var(--icon-color1);
}
.iconShareScreenActive {
  color: $status-success;
}
.IconChat {
  color: var(--icon-color1);
}
.ItemSchedule {
  position: absolute;
  right: 24px;
  display: flex;
  align-items: center;
}
.ItemScheduleButton {
  display: flex;
  align-items: center;
  height: 40px;
  margin-left: 24px;
  padding: 6px 12px 6px 24px;
  border-radius: 24px 0px 0px 24px;
  background: var(--blue-contained);
  color: #fff;
  font-weight: 500;
  font-size: $font-size-body1;
  line-height: 20px;
  cursor: pointer;
  &:hover {
    background: var(--background-hover-primary);
  }
  &:active {
    background: var(--background-active-primary);
  }
}
.ItemScheduleButtonStop {
  background: var(--status-error) !important;
  &:hover {
    background: var(--status-error) !important;
  }
  &:active {
    background: var(--status-error) !important;
  }
}
.ItemScheduleDropdown {
  position: relative;
  width: 32px;
  height: 40px;
  margin-left: 1px;
  border-radius: 0px 24px 24px 0px;
  background: var(--blue-contained);
  cursor: pointer;
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    height: auto;
    border-top: 5px solid #fff;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    transform: translate(-50%, -50%);
  }
  &:hover {
    background: var(--background-hover-primary);
  }
  &:active {
    background: var(--background-active-primary);
  }
}
.iconBottomFontActive {
  color: var(--green);
}
