@import '../../../styles/commonStyles.scss';

.Wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 122px 0;
  :global(.ql-toolbar) {
    display: flex;
    gap: 4px;
    width: 100%;
    margin-bottom: 8px;
    padding: 0px 0px 12px 0px !important;
    border: none !important;
    font-family: 'Poppins', sans-serif !important;
    :global(.ql-active) {
      background: var(--background-menu) !important;
    }
  }
}

.Header {
  display: flex;
  gap: 4px;
  align-items: center;
  height: 36px;
  margin-bottom: 16px;
  // color: var(--text-primary);
  color: $icon-dark;
  font-weight: 400;
  font-size: 16px;
}

.NoteEditor {
  display: flex;
  flex: 1;
  flex-direction: row;
  overflow: hidden;
}

.QuillEditor {
  display: flex;
  flex: 1;
  height: 100%;
  :global(.ql-editor.ql-blank::before) {
    // color: var(--text-secondary4) !important;
    color: $icon-dark;
  }
  :global(.ql-container) {
    flex: 1;
    width: 342px;
    border: none !important;
    // color: var(--text-primary);
    color: $icon-dark;
    font-size: 13px;
    font-family: 'Poppins', sans-serif !important;
    :global(.ql-snow) {
      border: none !important;
    }
    :global(.ql-editor) {
      padding: 0 !important;
    }
    :global(.ql-editor.ql-blank::before) {
      left: 0;
      color: $text-body-600;
      font-style: normal;
    }
  }
}
.EditorContent {
  position: relative;
  display: flex;
  flex: 1;
  overflow: hidden;
}
.EditorContentOverlay {
  position: absolute;
  z-index: 1;
  display: none;
  width: 100%;
  height: 100%;
  border: 4px solid #4098ff;
  border-radius: 0px 0px 0px 16px;
  background: rgba(38, 86, 201, 0.6);
}

.IconDisable {
  opacity: 0.7;
  cursor: not-allowed;
}
