.TabBar {
  width: 100%;
  color: var(--text-secondary9);
  text-transform: capitalize;
  :global(.ant-tabs-nav) {
    margin: 0;
    :global(.ant-tabs-nav-wrap .ant-tabs-nav-list) {
      flex: 1;
    }
    &::before {
      display: none;
    }
  }
}

.TabBarLine {
  :global(.ant-tabs-nav-wrap .ant-tabs-nav-list) {
    justify-content: space-between;
  }

  :global(.ant-tabs-tab) {
    font-weight: 400;
    font-size: 13px;
  }

  :global(.ant-tabs-tab-active .ant-tabs-tab-btn) {
    color: var(--text-link);
    font-weight: 600;
  }

  :global(.ant-tabs-ink-bar) {
    height: 4px !important;
    background-color: transparent;
    &::before {
      content: '';
      position: absolute;
      left: 50%;
      z-index: 100;
      width: 38px;
      height: 4px;
      border-radius: 4px 4px 0 0;
      background: var(--blue-contained);
      transform: translateX(-50%);
    }
  }
}

.TabBarCard {
  padding: 2px;
  border-radius: 8px;
  background: var(--background-tabbar-card);
  :global(.ant-tabs-nav-wrap .ant-tabs-nav-list) {
    gap: 4px;
    justify-content: flex-start;
  }
  :global(.ant-tabs-nav-operations) {
    display: none !important;
  }
  :global(.ant-tabs-tab) {
    z-index: 100;
    border-radius: 6px;
    :global(.ant-tabs-tab-btn:active) {
      color: var(--text-primary1) !important;
    }
    :global(.ant-tabs-tab-btn:focus) {
      color: var(--text-primary1) !important;
    }
  }
  :global(.ant-tabs-tab:not(.ant-tabs-tab-active):hover) {
    background: var(--background-tabitem-card-active);
    color: var(--text-primary1);
  }
  :global(.ant-tabs-tab-active .ant-tabs-tab-btn) {
    color: var(--text-primary1);
    font-weight: 500;
  }
  :global(.ant-tabs-ink-bar) {
    height: 100% !important;
    border-radius: 6px;
    background: var(--background-tabitem-card-active);
  }
  &.TabBarCardS {
    :global(.ant-tabs-tab) {
      padding: 6px 12px;
      :global(.ant-tabs-tab-btn) {
        font-size: 13px;
      }
    }
  }
  &.TabBarCardM13 {
    :global(.ant-tabs-tab) {
      padding: 10px 16px;
      :global(.ant-tabs-tab-btn) {
        font-size: 13px;
      }
    }
  }
  &.TabBarCardM16 {
    :global(.ant-tabs-tab) {
      padding: 8px 16px;
      :global(.ant-tabs-tab-btn) {
        font-size: 16px;
      }
    }
  }
}
