@import '../../../styles/commonStyles.scss';
.MediaView {
  position: absolute;
  inset: -1px;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: none;
    background: rgba(0, 0, 0, 0.8);
  }
  &:hover {
    cursor: pointer;
    .MediaMask {
      border-radius: inherit;
      opacity: 1;
    }
  }
}
.MediaMask {
  // position: absolute;
  // z-index: 999;
  position: relative;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  opacity: 0;
}
.MediaMaskNotSmall {
  background: transparent !important;
}
.mediaViewMouseOver {
  display: block;
}
.mediaScreenshare {
  inset: 0;
  video {
    border-radius: 0;
  }
}
.mediaScreenshareLayoutStyle {
  video {
    border-radius: 12px;
  }
}
.MediaViewPopoverKickOut {
  position: absolute;
  bottom: 0;
  left: 50%;
}
.MediaViewPeerName {
  position: absolute;
  z-index: 500;
  overflow: hidden;
  width: calc(100% - 5px);
  color: $text-level1;
  font-weight: 500;
  line-height: 150%;
  text-shadow:
    0px 0px 2px rgba(0, 0, 0, 0.25),
    0px 0px 8px #000000;
}
.mediaViewPeerNameSmall {
  bottom: 2px !important;
  left: 5px !important;
  font-size: 12px !important;
}
.Loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.CopyInput {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  margin: auto;
  border-radius: 58px;
  background: #ffffff;
}
