@import '../../Studio/components/LiveStreamCustomSlot.module.scss';

.BlurBlock {
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background-size: cover;
  backdrop-filter: blur(20px);
}
.SlotTopIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #e1e1e9;
  font-size: 16px;
  //  font-family: 'icomoon';
  composes: IconFont from '../../../styles/iconFont.module.scss';
}
.BlurText {
  @extend .SlotTopIcon;
  padding: 0px 8px;
  font-size: 13px;
  font-family: 'Poppins', sans-serif;
  white-space: nowrap;
}
.AddMoreSlot {
  @extend .SlotTopIcon;
  &:after {
    content: '\e95d';
  }
}
.RemoveMoreSlot {
  @extend .SlotTopIcon;
  &:after {
    content: '\e909';
    font-size: 11px;
  }
}
