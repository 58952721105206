.MediaControlContainer {
  position: relative;
  z-index: -1;
  display: flex;
  justify-content: flex-end;

  width: 100%;
  height: 100%;
  opacity: 0;
}
.mediaControlContainerShow {
  z-index: 10;
  opacity: 1;
}
