@import '~react-quill/dist/quill.snow.css';

.Container {
  overflow: hidden;
  // max-height: 100px;
  width: 100%;
}
.MentionInput {
  flex: 1;
  // width: 200;
  :global(.ql-container) {
    overflow: hidden;
    width: 100%;
    border: none !important;

    border-radius: 8px;
    background: var(--background4);
    color: var(--text-primary);
    font-size: 13px;
    font-family: 'Poppins', sans-serif !important;
    :global(.ql-editor) {
      overflow: auto;

      max-height: 100px;
      padding: 10px 24px 10px 12px;
    }
    :global(.ql-editor.ql-blank::before) {
      color: var(--text-secondary) !important;
      font-style: normal;
    }

    :global(.ql-clipboard) {
      display: none;
    }
  }
}
