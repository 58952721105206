@import '../../../styles/commonStyles.scss';
.ContainerWaterMark {
  position: absolute;
  z-index: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 9px;
  border-radius: 8px;
  // background-color: rgba(0, 0, 0, 0.7);
  text-align: center;
  cursor: pointer;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    border: 3px solid $status-success;
  }
  &:hover {
    &::before {
      display: block;
    }
  }
}
.ContainerWaterMarkBeforeHide {
  &:hover {
    &::before {
      display: none;
    }
  }
}
.ContainerWaterMark:hover .ItemIconDelete {
  z-index: 100;
  display: block;
  cursor: pointer;
}
.ImageWatermark {
  flex: 0 1 auto;
  width: 100%;
  height: 100%;
}
