.BuyMore {
  width: 400px;
  // height: 400px;
  padding: 16px 24px 24px 24px !important;
}

.Main {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.Header {
  display: flex;
  flex-direction: row;
  align-items: center;

  .Title {
    flex: 1;
    color: var(--text-primary1);
    font-weight: 500;
    font-size: 16px;
  }
}

.Decs {
  margin-bottom: 8px;
  color: var(--text-primary1);
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
}

.BuyTo {
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  .Value {
    width: 104px;
  }

  .FormBuy {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.Summary {
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  .Items {
    position: relative;
    padding: 12px;
    border-radius: 8px;
    background: var(--background8);

    .Spin {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 999;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      background: var(--background5);
      backdrop-filter: blur(80px);
    }

    .PriceItem {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 8px;

      &:last-child {
        margin-top: 12px;
      }

      .Text {
        color: var(--text-secondary6);
        font-weight: 400;
        font-size: 13px;
        line-height: 150%;

        .Multi {
          color: var(--text-primary1);
        }
      }

      .Title {
        flex: 1;
      }

      .Minute {
        width: 48px;
        height: 20px;
        color: var(--green);
        text-align: right;
      }

      .Discount {
        color: var(--green);

        .Prefix {
          margin-right: 4px;
        }
      }

      .Total {
        color: var(--text-primary1);
        font-weight: 500;
        font-size: 18px;
        font-size: 18px;
        line-height: 150%;
      }
    }

    .Divider {
      height: 1px;
      margin: 0;
      margin-bottom: 8px;
      padding: 0;
      background-image: linear-gradient(
        to right,
        var(--border) 50%,
        rgba(255, 255, 255, 0) 50%
      );
      background-position: -2px;
      background-size: 8px 1px;
      background-repeat: repeat-x;
    }
  }
}

.Actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 24px;

  .ButtonCheckOut {
    width: 264px;
    margin-left: 8px;
  }
}

.TotalCount {
  margin-top: 8px;
  color: var(--green);
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
}

.BillingCircle {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 72px;
  margin-top: 8px;
  padding: 16px;
  background: var(--background8);

  .PayBilling {
    flex: 1;
    max-width: 216px;
    margin-left: 15px;
    color: var(--text-secondary6);
    font-weight: 400;
    font-size: 13px;

    .Value {
      color: var(--text-primary1);
    }
  }
}

.BillingIcon {
  color: var(--text-secondary6);
}

.SumTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;

  .Title {
    flex: 1;
    color: var(--text-primary1);
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
  }

  .CheckPrice {
    padding-right: 12px;
    color: #4098ff;
    font-weight: 500;
    font-size: 13px;
    cursor: pointer;
  }
}

.CloseIcon {
  display: inline-flex;
  color: var(--icon-color-8);
  cursor: pointer;
}

.DisabledCheckout {
  pointer-events: none;
}

.PlusIcon {
  color: var(--icon-color-8);
}

.MinusIcon {
  color: var(--icon-color-8);
}

.MarginAction {
  margin-left: 8px;
}

.InputValue {
  height: 40px;
}

.InputPostfix {
  padding: 8px 16px;
}
.TextWarning {
  display: flex;
  gap: 4px;
  margin-top: 8px;
  color: var(--status-warning);
  font-size: 13px;
}
