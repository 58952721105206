@import '../../../styles/commonStyles.scss';
.CountDownWrapper {
  background: var(--background13);
}
.CountDownContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 368px;
  height: 271px;
  padding: 24px;
  background: var(--background-modal);
  p {
    margin: 0;
    padding: 0;
  }
}
.count-down-label {
  color: var(--text-secondary1);
  font-size: 120px;
  line-height: 94px;
}
.desc {
  color: var(--text-secondary1);
  font-size: $font-size-body4;
}
.button {
  width: 240px;
  border-radius: 32px !important;
}
