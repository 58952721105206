.ContainerOverlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  background-position: center;
  // TODO: Overlay
  // background-size: 100% 100%;
  background-size: cover;
  background-repeat: no-repeat;
  transition: right 0.25s cubic-bezier(0.16, 1, 0.3, 1);
}
