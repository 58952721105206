@import '../../../styles/commonStyles.scss';
.WrapperModalEdit {
  padding-bottom: 0px;
  :global(.ant-modal-content) {
    border-radius: 8px !important;
    background: var(--background13) !important;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.4) !important;
  }
  :global(.ant-modal-header) {
    //padding: 24px !important;
    border-bottom-color: var(--border10) !important;
    border-radius: 16px 16px 0 0 !important;
    border-top-left-radius: 16px !important;
    border-top-right-radius: 16px !important;
    background: var(--background-modal);
  }
  :global(.ant-modal-title) {
    color: var(--text-primary1) !important;
  }
}
.ModalOutClose {
  color: var(--icon-color6);
}
.ModalFormContentContainer {
  border-radius: 8px;
  background: var(--background13);
}
.ModalFormContent {
  padding: 20px 25px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  background: var(--background-modal);
}
.ModalFormItem {
  margin-bottom: 24px;
}
.ModalFormItemLabel {
  margin-bottom: 8px;
  color: var(--text-primary1) !important;
}
.ModalFormItemInput {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid var(--border11) !important;
  border-radius: 8px;
  background: var(--background2);
  color: var(--text-primary1);
}
.ModalFormItemCount {
  color: var(--text-secondary);
  text-align: right;
}
.ModalFormAction {
  display: flex;
  justify-content: flex-end;
}
.modalFormBtnSubmit {
  width: 160px;
  padding: 10px 16px;
  border-radius: 32px;
}
.ModalFormBtnCancel {
  display: flex;
  align-items: center;
  margin-right: 8px;
  padding: 0px 16px;
  border-radius: 32px;
  color: var(--text-primary);
  font-weight: 500;
}
