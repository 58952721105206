@import '../../styles/commonStyles.scss';

.DropdownSelectWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  :global(.ant-space-item) {
    width: 100%;
  }
}

.Select {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  padding: 12px;
  border: 1px solid var(--border2);
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  &:focus {
    color: red;
  }
}

.selectLight {
  border: 1px solid rgba(4, 16, 27, 0.16);
  background: #ffffff;
}

.Label {
  color: rgba(225, 225, 233, 0.5);
  font-size: $font-size-body1;
}

.LabelLight {
  color: #0f0f0f;
  font-weight: 500;
}

.Input {
  flex: 1;
  width: 100%;
  border: none;
  color: #ffffff !important;
  font-size: $font-size-body1 !important;
}

.InputLight,
.IconLight {
  color: var(--text-secondary4) !important;
}

.IconWrapper {
  display: inline-flex;
  color: #ffffff;
}

.ErrorIcon {
  margin-right: 7px;
  color: $status-error;
}

.TextError {
  position: absolute;
  bottom: -30px;
  color: $status-error;
  font-size: 13px;
}
