.SlotActionsOutSide {
  position: absolute;
  top: -32px;
  z-index: 1000;
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
  height: 20px;
}

.MenuList {
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: flex-start;
  width: 120px;
  padding: 8px;

  border-radius: 8px;
  background: var(--light-background-level-1, #fff);

  box-shadow:
    0px 1px 4px 0px rgba(1, 2, 11, 0.24),
    0px 4px 12px 0px rgba(1, 2, 11, 0.12);
}

.MenuItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100px;
  padding: 8px;
  cursor: pointer;
  .MenuItemIcon {
    color: #2656c9;
  }
}

.DropdownIcon {
  color: var(--text-secondary6);
}

.ViewModeButtonWrapper {
  position: absolute;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}

.ViewModeButton {
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  padding: 6px 16px 6px 16px !important;
  text-transform: capitalize;
}

.SlotActionsOutSideLeft {
  position: absolute;
  left: -32px;
  z-index: 1200;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 100%;
}
.SlotTopButton {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 32px;
  min-height: 32px;
  border-radius: 58px;
  background: rgba(0, 0, 0, 0.8);
  cursor: pointer;
  &:hover {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.1),
        rgba(255, 255, 255, 0.1)
      ),
      rgba(0, 0, 0, 0.8);
  }
  &:active {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.2),
        rgba(255, 255, 255, 0.2)
      ),
      rgba(0, 0, 0, 0.8);
  }
}

.SlotActionsBlur {
  @extend .SlotActionsOutSide;
  gap: 8px;
}
.SlotTopInput {
  padding: 0 16px;
  cursor: auto;
  &:hover,
  &:active {
    background: rgba(0, 0, 0, 0.8);
  }
}
