.Wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 500px;
  padding: 8px;
}
.Content {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.Title {
  font-weight: 500;
}
.Action {
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-left: auto;
}
