@import '../../styles/commonStyles.scss';
.DatePickerContainer {
  :global(.ant-picker-input) {
    &::before {
      content: var(--dataBefore);
      margin-right: 4px;
      color: var(--text-primary);
      font-size: $font-size-body1;
      white-space: nowrap;
    }
  }

  :global(.ant-picker) {
    height: 40px;
    border: 1px solid var(--border2);
    border-radius: 8px;
    background: var(--background31);

    input {
      color: var(--text-secondary12);
    }
  }

  :global(.ant-picker-status-error) {
    border: $border-input-error;
  }

  :global(.ant-picker-disabled) {
    background: $background-dark-disabled;
  }
}
.datePickerContainerDisableInput {
  :global(.ant-calendar-input-wrap) {
    display: none;
  }
}
.IconColor {
  color: var(--icon-color-9);
}

.CustomDatePickerPanel {
  z-index: 1200;

  :global(.ant-picker-panel-container) {
    border-radius: 8px;
    background: var(--background3);
    box-shadow: var(--box-shadow-datepicker);
  }
  :global(.ant-picker-panel-container) > div {
    width: 364px !important;
    padding: 16px;
    background: #ffffff;
  }
  :global(.date-item) {
    justify-content: flex-start !important;
    width: 44px !important;
    height: 36px !important;
    padding: 4px 10px 0px 0px !important;
  }
  :global(.ant-picker-cell .date-item) {
    background: var(--background36);
  }
  :global(.ant-picker-cell-disabled::before) {
    background: transparent !important;
  }
  :global(.ant-picker-cell .date-item.not-in-month) {
    border: 1px solid var(--border-not-in-month-cell);
    background: var(--background35) !important;
  }
  :global(.date-item .date-value-selected::before) {
    bottom: -6px !important;
    left: 60% !important;
  }
  :global(.date-item-seleted) {
    border: 2px solid rgba(255, 255, 255, 0.24);
  }
  :global(.date-item.past-days) span {
    color: var(--text-secondary4);
  }
  :global(.month-style) {
    margin-right: 4px;
    color: var(--text-primary1) !important;
    font-size: 14px !important;
  }
  :global(.year-style) {
    color: var(--text-primary1) !important;
    font-weight: 500 !important;
    font-size: 14px !important;
  }
  button[ant-click-animating-without-extra-node='true']::after {
    display: none;
  }
  :global(.ant-picker-content) th {
    color: var(--text-secondary10) !important;
    font-weight: 600;
    font-size: 13px;
  }
  :global(.ant-picker-content) tr th:nth-child(1)::after {
    content: 'u';
  }
  :global(.ant-picker-content) tr th:nth-child(2)::after {
    content: 'o';
  }
  :global(.ant-picker-content) tr th:nth-child(3)::after {
    content: 'u';
  }
  :global(.ant-picker-content) tr th:nth-child(4)::after {
    content: 'e';
  }
  :global(.ant-picker-content) tr th:nth-child(5)::after {
    content: 'h';
  }
  :global(.ant-picker-content) tr th:nth-child(6)::after {
    content: 'r';
  }
  :global(.ant-picker-content) tr th:nth-child(7)::after {
    content: 'a';
  }
}
.ComponentCalendar {
  background: var(--background33) !important;
}
