@import '../../../styles/commonStyles.scss';
.RadioContainer {
  :global(.ant-radio-checked .ant-radio-inner) {
    border-color: $text-primary !important ;
  }
  :global(.ant-radio-checked .ant-radio-inner::after) {
    background-color: var(--text-link) !important;
    transform: scale(1);
  }
  :global(.ant-radio-checked::after) {
    top: 4px;
  }
  :global(.ant-radio:hover:not(.ant-radio-disabled) .ant-radio-inner) {
    border-color: $text-primary !important;
  }
  :global(.ant-radio-checked .ant-radio-inner:focus) {
    border-color: $text-primary !important;
  }
  :global(.ant-radio-inner) {
    top: 4px !important;
    width: var(--wAntRadioInner) !important;
    height: var(--hAntRadioInner) !important;
    border: 1px solid var(--border7) !important;
    background-color: transparent !important;
  }
  :global(.ant-radio-inner::after) {
    top: var(--tAntRadioInnerAfter) !important;
    left: var(--lAntRadioInnerAfter) !important;
    width: var(--wAntRadioInnerAfter) !important;
    height: var(--hAntRadioInnerAfter) !important;
  }
  :global(.ant-radio-disabled .ant-radio-inner) {
    background-color: var(--border6) !important;
  }
}
