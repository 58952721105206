.CreateEvent {
  display: flex;
  flex-direction: column;
  width: 701px;
  border-radius: 8px;
  background: var(--background-modal);
  :global(.ant-form-item-required) {
    &::before {
      display: none !important;
    }
  }
}
.Title {
  font-weight: 500;
  font-size: 18px;
}
.Content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  textarea {
    width: 100%;
    height: 120px;
    padding: 8px;
    border-color: var(--border7);
    border-radius: 8px;
    background: var(--background2);
    color: var(--text-primary);
  }
}
.TitleItem {
  flex: 1;
  font-weight: 500;
  font-size: 13px;
}
//.TitleRequire {
//  &::after {
//    content: '*';
//    display: inline-block;
//    margin-right: 4px;
//    color: #ff4d4f;
//    font-size: 14px;
//    font-family: SimSun, sans-serif;
//    line-height: 1;
//  }
//}
.DestinationItem {
  width: fit-content;
}
.Label {
  display: flex;
  width: 100%;
}
.SelectedContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.MaxLength {
  color: var(--text-secondary4);
}
.SelectThumbnail {
  display: flex;
  gap: 16px;
  align-items: center;
}
.SelectThumbnailLeft {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  width: 240px;
  height: 125px;
  border-radius: 8px;
  background: var(--button-grey);
  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}
.Icon {
  fill: rgba(161, 161, 161, 1) !important;
}
.Text {
  color: var(--text-secondary11);
}
.SelectThumbnailRight {
  display: flex;
  flex-direction: column;
  gap: 8px;
  input {
    display: none !important;
  }
}
.BtnUpload {
  display: flex;
  gap: 8px;
  align-items: center;
  width: 104px;
  padding: 10px 16px;
  border-radius: 32px;
  color: var(--text-primary);
  font-weight: 500;
}
.Bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
