.SingleUserContent {
  display: flex;
  gap: 7px;
}

.Avatar {
  width: 28px;
  height: 28px;
  margin-top: 2px;
  border-radius: 14px;
}

.Content {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 302px;
  padding: 12px;
  border-radius: 16px;
  background-color: var(--background5);
}

.Title {
  display: flex;
  justify-content: space-between;
  color: #808080;
  font-weight: 400;
  font-size: 13px;
}

.TimeStamp {
  font-size: 10px;
}

.IconCopy {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: var(--border6);
  color: var(--text-primary1);
  cursor: pointer;
}

.Text {
  color: var(--text-primary1);
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
}

.CopyPopup {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 72px;
  height: 36px;
  border-radius: 8px;
  background: var(--background4);
}

.PopoverWrapper {
  :global(.ant-popover-inner) {
    border-radius: 8px;
  }
}

.CursorTyping {
  display: inline-block;
  width: 1ch;
  margin-bottom: 4px;
  animation: flicker 0.5s infinite;
}

@keyframes flicker {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
