@import '../../../styles/commonStyles.scss';

.container {
  width: 560px;
  height: auto;
  padding: 16px;
}
.ModalContainer {
  padding: 0px !important;
}
.TitleBox {
  display: flex;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
}

.IconWifi {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  /* Light/Level/Darker-Level4 */
  background: rgba(4, 16, 27, 0.06);
  color: var(--blue-contained);
}

.Title {
  margin: 0;
  //  color: #0f0f0f;
  color: var(--text-secondary1);
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
}
.Mt24 {
  margin-top: 24px;
}

.FormLabel {
  margin-bottom: 0 !important;
  //  color: #0f0f0f;
  color: var(--text-secondary1);
  font-weight: 500;
  font-size: 13px;
}

.Action {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  align-items: center;
}
.BtnClose {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0px 16px;
  border: none;
  border-radius: 32px;
  color: var(--text-primary);
  font-weight: 500;
  //  color: #0f0f0f !important;
}

.FormItem {
  position: relative;
  margin-bottom: 24px !important;
  color: var(--text-secondary1);
  :global(.ant-form-item-label) {
    padding-bottom: 4px;
    label {
      //  color: var(--text-primary1);
      //  font-size: $font-size-body1;
      &::after {
        content: '*';
        display: inline-block;
        margin-right: 4px;
        color: #ff4d4f;
        font-size: 14px;
        font-family: SimSun, sans-serif;
        line-height: 1;
      }
    }
  }
  .Input {
    height: 40px;
    border: 1px solid rgba(4, 16, 27, 0.12);
    //position: relative;
    border-radius: 8px;
    background: var(--background2);
    color: var(--text-primary) !important;
  }
}

.formSelect {
  width: 100%;
  height: 40px;
  :global(.ant-select-selection-item) {
    line-height: 40px !important;
  }
  :global(.ant-select-selector) {
    background: var(--background2) !important;
    //color: var(--text-secondary1);
    color: var(--text-primary) !important;
  }
  :global(.ant-select-arrow) {
    //color: var(--text-secondary1);
    color: var(--text-primary) !important;
  }
}

.FormItem {
  :global(.ant-select-selector) {
    height: 40px !important;
    border: 1px solid rgba(4, 16, 27, 0.12) !important;
    border-radius: 8px !important;
  }
  :global(.ant-input-status-error) {
    height: 40px !important;
    border: 1px solid rgba(4, 16, 27, 0.12) !important;
    border-radius: 8px !important;
  }
}

.formOption {
  background: var(--background40) !important;
  color: var(--text-primary) !important;

  //color: red !important;
  //color: var(--green) !important;
  &:hover {
    background: var(--background39) !important;
    //color: red !important;
    //color: var(--green) !important;
    color: var(--text-primary) !important;
  }
}
