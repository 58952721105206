@import '../../../styles/commonStyles.scss';
@value ItemAttachmentContent from "./ChatAttachments.module.scss";
@value ItemAttachmentList from "./ChatAttachments.module.scss";
@value ItemAttachmentDetailImage from "./ChatAttachments.module.scss";
.ItemChatAvatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  user-select: none;
}

.ItemChatContent {
  display: flex;
  flex-direction: column;
  width: calc(100% - 35px);
  margin-left: 7px;
  padding: 12px;
  border-radius: 16px;
  background-color: var(--background5);
  font-size: $font-size-body1;
  line-height: 150%;
  @media (max-width: 1000px) {
    border-radius: 12px;
  }
  @media (max-width: 700px) {
    border-radius: 8px;
  }
}

.ItemChatPreviewContainer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  z-index: 1000;
  width: 294px;
  height: auto;
  pointer-events: none;
}
.itemChatSpanIsOnLiveStream {
  color: rgba(225, 225, 233, 0.54);
}
.ItemChatMessageIsOnLiveStream {
  color: var(--text-primary1);
}
.ItemChatContainer {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 12px;
}
.itemChatContainerDragging {
  border-radius: 16px;
  background-color: $level-dark4;
  > * {
    opacity: 0;
  }
}
.itemChatContainerOnliveStream {
  margin-bottom: var(--mbItemChatContainerOnLiveStream);
}
.itemChatContainerOnliveStream .ItemChatAvatar {
  width: var(--wIChatAvatar);
  height: var(--hIChatAvatar);
  font-size: var(--fIChatAvatar);
}
.itemChatContainerOnliveStream .ItemChatContent {
  width: var(--wItemChatContent);
  margin-left: var(--mlItemChatContent);
  padding: var(--pItemChatContent);
  font-size: var(--fItemChatContent);
}
.itemChatContainerOnliveStream .ItemAttachmentContent {
  margin-left: var(--mlItemAttachmentContent);
}
.itemChatContainerOnliveStream .ItemChatTime {
  font-size: var(--fItemChatTime);
}
.itemChatContainerOnliveStream .ItemChatOnAir {
  margin-top: var(--mtItemChatOnAir);
  padding: var(--pItemChatOnAir);
}
.itemChatContainerOnliveStream .ItemAttachmentList {
  margin: var(--mItemAttachmentList);
}
.itemChatContainerOnliveStream .ItemAttachmentDetailImage {
  margin-bottom: var(--mbItemAttachmentDetailImage);
  padding: 0 var(--pxItemAttachmentDetailImage);
  img {
    height: var(--hItemAttachmentDetailImage);
    cursor: pointer;
  }
}

.itemChatContentOnAir {
  border: 1px solid var(--status-error);
}
.ItemChatActionIcon {
  position: absolute;
  top: 12px;
  right: 12px;
  display: none;
  padding: 4px;
  border-radius: 50%;
  background: var(--background6);
  color: var(--icon-color1);
  cursor: pointer;
  &:hover {
    background: var(--background-hover-dark);
  }
  &:active {
    background: var(--background-active-dark) !important;
  }
}
.itemChatContentShowAction > .ItemChatActionIcon {
  display: flex;
}
.ItemChatInfo {
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
  color: var(--text-secondary);
}

.ItemChatName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
  word-break: break-all;
  cursor: pointer;
}

.ItemChatTime {
  font-size: 10px;
}
.ItemChatMessage {
  overflow-x: auto;
  color: var(--text-primary1);
  white-space: pre-line;
}
.ItemChatOnAir {
  display: inline-block;
  width: fit-content;
  margin-top: 10px;
  padding: 2px 8px;
  border-radius: 47px;
  background: var(--red-contained);
  color: var(--red-on-contained);
  font-weight: 500;
  cursor: pointer;
}
.ItemChatActionContent {
  padding: 16px;
  border-radius: 8px;
}
.ItemChatActionDetail {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  span {
    position: absolute;
    top: 1px;
    left: 0;
  }
}
.ItemChatActionSession {
  margin-bottom: 20px;
  composes: ItemChatActionDetail;
}
.ItemChatActionDelete {
  composes: ItemChatActionDetail;
}
.ItemChatAvatarImage {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.ItemChatAvatarLabel {
  color: #ffffff;
  text-transform: uppercase;
}
.ItemChatContent:hover > .ItemChatActionIcon {
  display: flex;
}
.ItemChatActionDelete {
  color: var(--text-primary);
}

.ItemChatActionDeleteLight {
  color: var(--text-primary);
}
