.VideoPlayerVolume {
  display: flex;
  align-items: center;
  width: 88px;
}

.VideoPlayerMute {
  z-index: 10;
  display: inline-flex;
  color: var(--icon-color1);
  cursor: pointer;
}

.VideoPlayerVolumeSwitch {
  display: none;
  width: 64px;
  margin: 6px 0 0 8px;
  padding: 0;
  :global(.ant-slider-rail) {
    background-color: var(--slider);
  }
  :global(.ant-slider-handle) {
    box-shadow:
      0px 1px 4px 0px rgba(1, 2, 11, 0.24),
      0px 4px 12px 0px rgba(1, 2, 11, 0.08),
      0px 4px 8px 0px rgba(1, 2, 11, 0.12);
  }
}

.VideoPlayerVolumeSwitchShow {
  display: block;
}

.VideoPlayerIconPlay {
  display: flex;
  margin-left: 32px;
  color: var(--icon-color1);
  font-size: 24px;
  cursor: pointer;
}
.VideoPlayRepeat {
  display: inline-flex;
  margin-left: 5px;
  padding: 4px 6px;
  border-radius: 4px;
  background-color: transparent;
  color: var(--text-secondary3);
  font-size: 19px;
  cursor: pointer;

  &:hover {
    border-radius: 50%;
    background-color: var(--background23);
    cursor: pointer;
  }
}
.videoPlayIsRepeat {
  border-radius: 50%;
  background-color: var(--green-contained) !important;
  color: var(--green-on-contained) !important;
}

.VideoPlayerInfo {
  display: flex;
  flex: 1;
  flex-direction: row-reverse;
  align-items: center;
}

.VideoPreviewContainer {
  position: relative;
  display: none;
  width: 100%;
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 8px;
  background-color: var(--background-input-disabled1);
  user-select: none;
}

.VideoPreviewContainerShow {
  display: block;
}
.VideoPayerSliceContainer {
  padding: 8px 0px;
}
.VideoPlayerSlider {
  top: 0;
  margin: -12px 0 0 0;
  padding: 0;
  :global(.ant-slider-rail) {
    background-color: var(--slider);
  }

  :global(.ant-slider-handle) {
    box-shadow:
      0px 1px 4px 0px rgba(1, 2, 11, 0.24),
      0px 4px 12px 0px rgba(1, 2, 11, 0.08),
      0px 4px 8px 0px rgba(1, 2, 11, 0.12);
  }
}

.VideoPlayerController {
  display: flex;
  align-items: center;
  margin-top: 4px;
}

.VideoPlayerName {
  display: -webkit-box;
  overflow: hidden;
  margin-top: 8px;
  color: var(--text-primary);
  font-weight: 500;
  font-size: 13px;
  white-space: pre-wrap;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.VideoPlayerOtherInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
}

.VideoPlayerDuration {
  color: var(--text-secondary4);
  font-weight: 400;
  font-size: 12px;
}

.VideoPlayerOnAir {
  padding: 1px 8px;
  border-radius: 20px;
  background-color: var(--red-contained);
  color: var(--red-on-contained);
  cursor: pointer;
}

.VideoPlayerControllLeftOver {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  background: var(--grey14);
}

.Loading {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 264px;
  height: 150px;
  background-color: white;
  opacity: 0.6;
}

.PopoverActionWrapper {
  padding: 8px;
}

.PopoverActionItem {
  display: flex;
  align-items: center;
  padding: 8px 4px;
  border-radius: 4px;
  cursor: pointer;
  .PopOverActionIcon {
    margin-right: 8px;
    margin-left: 8px;
  }
  &:hover {
    background-color: rgba(4, 16, 27, 0.04);
  }
}

.TickedIcon {
  margin-right: 8px;
  margin-left: 8px;
  color: var(--blue-contained);
  visibility: hidden;
}

.TickedIconShow {
  visibility: visible;
}
