@import '../../../styles/commonStyles.scss';
.CheckboxContainer {
  :global(.ant-checkbox-checked) :global(.ant-checkbox-inner) {
    top: -3px !important;
    border-color: $text-primary !important;
    background-color: var(--background18) !important;
  }
  :global(.ant-checkbox-inner) {
    top: -3px;
    width: var(--wAntCheckboxInner) !important;
    height: var(--hAntCheckboxInner) !important;
    border: 1px solid var(--border7) !important;
    border-radius: 4px;
    background-color: transparent !important;
  }
  :global(.ant-checkbox-checked)::after {
    top: -2px;
  }

  :global(.ant-checkbox-input) {
    top: -3px !important;
  }
  :global(.ant-checkbox-checked) :global(.ant-checkbox-inner)::after {
    left: var(--lAntCheckboxInnerAfter) !important;
    width: 7px !important;
    height: 11px !important;
  }
  :global(.ant-checkbox-wrapper) {
    display: flex;
    align-items: center;
  }
  :global(.ant-checkbox-disabled) :global(.ant-checkbox-inner) {
    background-color: var(--border6) !important;
  }
  :global(.ant-checkbox) + span {
    color: var(--text-primary1);
  }
}
.CheckboxContainerLight {
  :global(.ant-checkbox-inner) {
    border: 1px solid rgba(4, 16, 27, 0.12) !important;
  }
}
