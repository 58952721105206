@import '../../../styles/commonStyles.scss';

.InsightMediaWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 136px;
  max-height: 136px;
  padding: 12px 16px;
  padding: 12px 16px;
  background-color: #c6c2c28c;
  color: var(--text-primary);
  input {
    display: none;
  }
}

.InsightMediaHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.TitleWrapper {
  flex: 1;
}
.Title {
  color: var(--text-primary);
}

.VideoList {
  display: flex;
  flex-direction: column;
  gap: 2px;
  overflow-y: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.PlusIcon {
  display: inline-flex;
  padding: 8px;
  border-radius: 50%;
  background-color: $background-light-button-ternary;
  color: $icon-dark;
  cursor: pointer;
}
.AddMediaMenus {
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-width: 288px;
  padding: 16px;
  box-shadow: none;
}
.AddMenu {
  display: flex;
  flex-direction: column;
  color: #0f0f0f;
  font-weight: 400;
  font-size: 13px;
}
.MenuTitle {
  color: #808080;
  font-size: 12px;
}
.MenuItem {
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background-color: #f1f2f4;
  }
  &:active {
    background-color: #808080;
  }
}

.Uploading {
  margin-right: 16px;
  color: #808080;
  font-size: 13px;
}

.IconColor {
  color: #0f0f0f;
}

.NoMedia {
  display: flex;
  justify-content: center;
  color: var(--text-primary);
}
