@import '../../../styles/commonStyles.scss';
.ColorPickerRange {
  padding: 0 28px 16px;
  :global(.react-colorful) {
    width: auto;
    height: auto;
  }
  :global(.react-colorful__saturation) {
    display: none;
  }
  :global(.react-colorful__hue) {
    background: linear-gradient(90deg, #1c4bd6 0%, #ff92fb 100%);
  }
}

.colorPickerHexInputGradient {
  flex: 0 1 50%;
  input {
    max-width: 132px;
  }
}

.ColorPickerRotateInput {
  position: relative;
  flex: 0 1 50%;
  width: 100%;
  padding: 0 2px;
}
.GrandientPickerPalette {
  height: 16px;
}
.GrandientListPickers {
  position: relative;
  width: 100%;
  height: 40px;
  cursor: crosshair;
}

.GrandientPickerOverlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: crosshair;
}
.GradientPickerItemContent {
  width: 16px;
  height: 16px;
}
.gradientPickerItemActive {
  border: 2px solid $status-success;
  &::before {
    display: block;
  }
}
.GradientPickerItemContent {
  width: 16px;
  height: 16px;
}
.InputColorRotate {
  max-width: 132px;
}
.InputCircleRotate {
  position: absolute;
  right: 9px;
  bottom: 7px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
  cursor: ew-resize;
}
.inputCircleRotateLight {
  background: #f3f2f2;
}
.LabelCircleRotate {
  position: absolute;
  top: 4px;
  left: 50%;
  width: 2px;
  height: 12px;
  margin-left: -1px;
  background-color: #c4c4c4;
  transform-origin: bottom center;
}
.labelCircleRotateLight {
  background-color: #0f0f0f;
}
.GradientPickerItem {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  border: 2px solid #fff;
  border-radius: 2px;
  background: #fff;
  cursor: pointer;
  &::before {
    content: '';
    position: absolute;
    bottom: -11px;
    display: none;
    width: auto;
    height: auto;
    border-top: 10px solid $status-success;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
  }
  &::after {
    content: '';
    position: absolute;
    bottom: -8px;
    width: auto;
    height: auto;
    border-top: 8px solid #fff;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
  }
}
.ColorPickerContent {
  composes: ColorPickerContent from './GraphicColorCommon.module.scss';
}
.ColorPickerInput {
  composes: ColorPickerInput from './GraphicColorCommon.module.scss';
}
.ColorPickerHexInput {
  composes: ColorPickerHexInput from './GraphicColorCommon.module.scss';
}
.LabelPicker {
  composes: LabelPicker from './GraphicColorCommon.module.scss';
}
.LabelPickerLight {
  composes: LabelPickerLight from './GraphicColorCommon.module.scss';
}
