@import '../../../styles/commonStyles.scss';
.ant-message {
  position: absolute;

  top: unset !important;
  bottom: 20px !important;
}
.custom-toast {
  .ant-message-notice-content {
    padding: 10px, 8px, 10px, 8px;
    border-radius: 8px !important;

    background: #303035 !important;
    box-shadow:
      0px 2px 2px rgba(0, 0, 0, 0.25),
      inset 0px -1px 1px rgba(255, 255, 255, 0.1) !important;
    img {
      /* margin-right: 8px; */
      margin-bottom: 2px;
    }
  }
  .ant-message-success {
    display: flex !important;
    align-items: center;
    color: $status-color-success;
  }
  .ant-message-error {
    display: flex !important;
    align-items: center;
    color: $status-color-danger;
  }
  .ant-message-warning {
    display: flex !important;
    align-items: center;
    color: $status-color-warning;
  }
}
.custom-toast-info {
  @extend .custom-toast;
  color: var(--text-primary1) !important;
}
.custom-toastLight {
  .ant-message-notice-content {
    padding: 10px, 8px, 10px, 8px;
    border-radius: 8px !important;
    background: #ffffff !important;
    box-shadow:
      0px 4px 12px rgba(1, 2, 11, 0.08),
      0px 1px 4px rgba(1, 2, 11, 0.24) !important;
    img {
      /* margin-right: 8px; */
      margin-bottom: 2px;
    }
  }

  .ant-message-success {
    display: flex !important;
    align-items: center;
    color: var(--green);
  }
  .ant-message-error {
    display: flex !important;
    align-items: center;
    color: var(--status-error);
  }
  .ant-message-warning {
    display: flex !important;
    align-items: center;
    color: var(--status-warning);
  }
}
.custom-toastLight-info {
  @extend .custom-toastLight;
  color: #0f0f0f;
}
.popover-toast {
  background-color: transparent !important;
  .ant-popover-content {
    background-color: transparent !important;
    .ant-popover-inner {
      background-color: transparent !important;
      box-shadow: none;
    }
    .ant-popover-inner-content {
      padding: 0;
    }
  }
  .ant-popover-arrow-content {
    display: none !important;
  }
}
.toast-icon {
  width: 14px;
  height: 14px;
}
.toast-icon-success {
  width: 20px;
  height: 20px;
  background-image: url('../../../assets/icons/ic_checked.svg');
}
.toast-icon-error {
  background-image: url('../../../assets/icons/error.svg');
}
.toast-icon-warning {
  background-image: url('../../../assets/icons/icon_warning.svg');
}
