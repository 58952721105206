.dropPlaylistContainerHover {
  border: 1px dashed var(--text-link) !important;
  background-color: rgba(117, 137, 255, 0.2) !important;
  color: var(--text-link) !important;
}

.DropPlaylistContainer {
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  height: 35px;
  margin: 0px 24px;
  border: 1px dashed var(--border4);
  border-radius: 8px;
  background-color: transparent;
  color: var(--text-secondary);
  font-size: 13px;
  line-height: 150%;
}
