.RightTabBarMain {
  --background: var(--right-tab-background);
  z-index: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
  width: var(--right-tab-width);
  height: calc(100% - 68px);
  background: var(--background);
}
.rightTabBarList {
  flex: 1;
  width: 100%;
}
.IconSidebar {
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 47px;
  height: 28.28px;
  margin-bottom: 4px;
  border-radius: 118px;
  color: var(--text-secondary);
  font-size: 18px;
}
.ChatBadge {
  composes: ChatBadge from './RightSiderCommon.module.scss';
}
.RightTabBarTitle {
  composes: RightTabBarTitle from './RightSiderCommon.module.scss';
  &:hover {
    color: var(--active-menu);
  }
  &.Active {
    color: var(--active-menu);
  }
}
.IconTab {
  fill: var(--text-secondary);

  &.iconTabActive {
    color: var(--active-menu) !important;
  }
}
.RightTabBarItem {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 18px 0;

  &:hover {
    border-color: transparent;
    cursor: pointer;

    .ChatBadge {
      color: var(--text-primary);
    }

    .RightTabBarTitle {
      color: var(--active-menu);
    }
    .IconTab {
      fill: var(--active-menu) !important;
    }
    .IconSidebar {
      background: var(--background-menu-hover);
      color: var(--icon-color1);
    }
  }

  &.Active {
    border-color: transparent;
    color: var(--active-menu);

    .IconSidebar {
      background: var(--background-menu);
      color: var(--active-menu);
    }
  }
}
.SwitchModeList {
  --background: var(--background);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px 0px;
}
.SwitchModeButton {
  display: flex;
  align-items: center;
  padding: 4px 12px;
  border-radius: 75px;
  color: var(--text-secondary);
  cursor: pointer;

  &:hover {
    background: var(--background-menu);
    color: var(--active-menu);
  }
}
.ChatBot {
  --background: var(--background);
  position: relative !important;
  right: 0px !important;
  width: 100% !important;
}
