.Wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.LightIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  border-radius: 16px;
  color: #2656c9;
  animation: shake 0.7s ease-in-out infinite;
}

@keyframes shake {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.Description {
  margin-bottom: 8px;
  color: #0f0f0f;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
}

.TextWaiting {
  color: #808080;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
}
