@import '../../../styles/commonStyles.scss';
.ContentPopoverKickOut {
  width: 280px;
  padding: 16px;
}
.TitlePopover {
  margin-bottom: 8px;
  color: $text-level1;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
}
.TextPopover {
  margin-bottom: 24px;
  color: $text-level2;
}
.ActionPopover {
  display: flex;
}
.ButtonPopover {
  display: block;
  height: auto;
  padding: 6px 30px;
  border: none;
  border-radius: 8px;
  box-shadow: none;
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
  cursor: pointer;
}
.CancelPopover {
  margin-right: 8px;
  composes: ButtonPopover;
}
.OkPopover {
  width: 120px;
  padding: 6px 16px;
  composes: ButtonPopover;
}
