@import '../../../styles/commonStyles.scss';
.ItemAccordionContainer {
  flex-wrap: wrap;
  margin-top: 24px;
  padding-bottom: 24px;
  :global(.p0) {
    padding: 0px;
  }
}
.ItemAccordionHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
  padding: 0 24px;
}
.ItemAccordionHeaderLabel {
  display: flex;
  align-items: center;
  color: var(--text-secondary3);
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
}
.LabelIconTooltip {
  margin-left: 10px;
  color: var(--text-secondary4);
  cursor: pointer;
}
.ItemAccordionHeaderIcon {
  color: var(--text-secondary);
  cursor: pointer;
  transition-duration: 0.4s;
  transition-property: transform;
  transform: rotate(90deg);
}
.itemAccordionHeaderIconToggle {
  transform: rotate(-90deg);
}
.ItemAccordionContents {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-y: hidden;
  max-height: 100%;
  transition: max-height 0.2s ease-in-out;
}
.itemAccordionContentsToggle {
  max-height: 0;
}
