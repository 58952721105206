@import '../../../styles/commonStyles.scss';

.Wrapper {
  padding: 16px;
  padding-bottom: 0px;

  :global(.ant-table-tbody > tr.ant-table-row > .ant-table-cell-row-hover) {
    background: transparent !important;
  }

  :global(.ant-table-sticky-holder) {
    background: transparent !important;
  }

  :global(.ant-table-cell-scrollbar) {
    display: none;
  }

  .title {
    margin-bottom: 16px;
    font-size: $font-size-h4;
  }

  .header {
    display: flex;
    margin-top: 16px;
    margin-bottom: 25px;

    .leftElement {
      flex: 1;
    }

    .search {
      width: 320px;
      height: 40px;
      //  margin-right: 24px;
      padding-left: 40px;
    }

    .searchIcon {
      position: absolute;
      left: 16px;
      z-index: 10;
    }

    .rightElement {
      position: relative;
      display: flex;
      gap: 16px;
      align-items: center;
    }
  }

  .btnRefresh {
    height: 32px;
    //margin-left: 16px;
    padding: 0 11px;
    border-radius: 6px;

    :global(.anticon) {
      width: 12px;
      height: 12px;
      font-size: 12px;
      line-height: 12px;
    }
  }
}

.ContentWrapper {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  height: 100%;
  padding-bottom: 54px;
}

.tableContainer {
  overflow-y: scroll;
  width: 100%;
  padding: 0 16px;
}
