@import '../../../styles/commonStyles.scss';

.PublicLayoutContainer {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  flex-direction: column;
  height: 100vh;
  background: var(--background3);
  background-position: center;
  background-size: cover;
  color: $text-level1;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: var(--background);
  }
}
.Wrapper {
  margin: auto;
}
.PublicLayoutContentContainer {
  z-index: 10;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  width: 100%;
  height: 100%;
  background: var(--background);
}

.PublicLayoutToolbarContainer {
  position: sticky;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  width: 100%;
  background: var(--background);
  box-shadow: var(--box-shadow-navbar);
}
.ToolbarContainer {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 18px 24px;
}
.LeftContent {
  display: flex;
  flex: 1;
  gap: 20px;
  align-items: center;
}
.logoIcon {
  display: block;
  width: 24px;
}

.RightContent {
  display: flex;
  align-items: center;
}
.RightItem {
  margin-right: 28px !important;
  cursor: pointer !important;
}
.BtnLogin {
  height: 32px;
  padding: 6px 12px;
  border-color: transparent;
  color: white !important;
  font-size: $font-size-body1;
}
.BtnActive {
  border-radius: 8px;
  background: transparent;
}
.BtnActive:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: rgb(117, 137, 255) !important;
}
