@import '../../../styles/commonStyles.scss';
.GraphicColorNote {
  margin-bottom: 24px;
  padding: 0 24px;
  color: var(--text-secondary);
  font-size: 12px;
  line-height: 150%;
  span {
    display: inline-block;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
  }
}
.GraphicColorList {
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px;
}
.GraphicColorItem {
  position: relative;
  flex: 0 1 calc(100% / 7);
  margin-bottom: 8px;
  padding: 0 4px;
}
.GraphicColorIconPicker {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33px;
  height: 33px;
  border: none;
  border-radius: 4px;
  background-color: var(--grey8);
  cursor: pointer;
}
.GraphicColorPreview {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 33px;
  height: 33px;
  pointer-events: none;
}
.GraphicColorPopoverContainer {
  padding: 0 8px;
}
.GraphicColorPopoverItem {
  display: flex;
  align-items: center;
  padding: 14px 16px;
  color: $text-level1;
  cursor: pointer;
  &:nth-child(2) {
    border-bottom: 1px solid $level-lighter3;
  }
}
.GraphicColorPopoverIcon {
  margin-right: 12px;
}
.GraphicColorWrapper {
  position: relative;
  width: 33px;
  height: 33px;
  padding: 3px;
  border: 1px solid var(--border12);
  border-radius: 4px;
  cursor: pointer;
}
.graphicColorWrapperSelected {
  border: 2px solid $status-error;
}
.graphicColorWrapperDragging {
  position: relative;
  border: none;
  background: $level-dark4;
}
.graphicColorWrapperDropover {
  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: -6px;
    width: 2px;
    height: 100%;
    border-radius: 2px;
    background-color: var(--text-link);
  }
}
.graphicColorWrapperDropbottom {
  &::before {
    left: calc(100% + 5px);
  }
}
.GraphicColorContent {
  width: 100%;
  height: 100%;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 2px;
}
.graphicColorContentDragging {
  border: none;
}
.WrapperPopoverEdit {
  position: absolute;
  top: 15px;
  left: 10px;
}
.WrapperPopoverAction {
  position: absolute;
  right: 0;
  bottom: 0;
}
