@import '../../../styles/commonStyles.scss';
.WrapperModal {
  padding-bottom: 0;
  border: none;
  border-radius: 0;
  background: var(--background13);
  background-color: transparent;
  :global(.ant-modal-content) {
    width: 560px;
    border-radius: 0;
    background-color: transparent;
    box-shadow: none;
  }
  :global(.ant-modal-title) {
    color: #fff;
  }
  :global(.ant-modal-close) {
    color: #fff;
  }
  :global(.ant-modal-body) {
    padding: 0;
    color: var(--text-primary1);
  }
  :global(.ant-modal-header) {
    border: none;
    border-radius: 0;
    background-color: transparent;
  }
}
.ModalContainer {
  border-radius: 8px;
  //  background: var(--background13);
}
.ModalInviteClose {
  color: var(--text-secondary1);
}

.ModalTitle {
  display: flex;
  align-items: center;
  padding: 16px;
}
.IconTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: var(--background16);
  line-height: 32px;
}

.iconTitleUser {
  color: var(--text-link);
}
.Title {
  margin-left: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.ModalFooter {
  border-radius: 8px;
}

.ModalFooterDarkMode {
  background: var(--background-modal);
}

.ModalSubmit {
  height: 64px;
  padding-top: 12px;
  border-top: 1px solid var(--slider);
}
.ModalSubmitContent {
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
}

.BtnInvite {
  border-radius: 32px !important;
  color: var(--text-primary1);
  composes: ButtonPrimary from '../../../styles/styleForm.module.scss';
}
