.ModalContainer {
  overflow-y: auto;
  width: 352px;
}
.ConfirmTitle {
  margin-top: 24px;
  color: var(--text-primary);
  font-weight: 500;
  font-size: 16px;
  text-align: center;
}
.ConfirmContent {
  margin-top: 16px;
  color: var(--text-secondary3);
  font-weight: 400;
  font-size: 13px;
  text-align: center;
}
.BtnClose {
  display: flex;
  justify-content: center;
  width: 160px;
  width: 260px;
  margin-top: 32px;
  margin-left: 8px;
  padding: 9px;
  border-color: transparent;
  border-radius: 32px;
}
.BtnSubmit {
  width: 160px;
  width: 260px;
  margin-top: 32px;
  margin-left: 8px;
  border-color: transparent;
  border-radius: 32px;
  color: white;
}
.BtnList {
  display: flex;
  flex-direction: column;
  align-items: center;
}
