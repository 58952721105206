@import '../../../styles/commonStyles.scss';
.TitleItemContainer {
  position: relative;
  padding: 16px;
  border-radius: 8px;
  background: var(--background5);
  cursor: pointer;
}
.TitleItemContainer:hover .BtnEdit {
  display: flex;
}
.TitleItemContainer .BtnEdit {
  display: none;
}
.TitleItemContainer:hover .ContentClick {
  display: flex;
}
.TitleItemContainer:hover {
  border-radius: 11px !important;
}
.titleItemContainerOpeningMenu .BtnEdit {
  display: flex;
}
.titleItemContainerOnAir {
  border: 2px solid $status-error;
}
.titleItemContainerDragging {
  border: none;
  background: var(--background5);
  box-shadow: none;
  > * {
    opacity: 0;
  }
}
.titleItemContainerDropover {
  &::before {
    content: '';
    position: absolute;
    top: -8px;
    right: 0;
    left: 0;
    width: 100%;
    height: 2px;
    border-radius: 2px;
    background-color: var(--text-link);
  }
}
.titleItemContainerDropover:hover .BtnEdit {
  display: none;
}
.titleItemContainerDropbottom {
  &::before {
    top: calc(100% + 5px);
  }
}
.TitleItemContent {
  display: block;
  overflow-x: hidden;
  width: calc(100% - 35px);
  color: var(--text-secondary);
  font-size: 13px;
  line-height: 150%;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.TitleButtonOnAir {
  margin-top: 10px;
}
.TitleButtonOnAirLabel {
  display: inline-block;
  padding: 2px 6px 2px 8px;
  border-radius: 20px;
  background: var(--red-contained);
  color: var(--red-on-contained);
  font-weight: 500;
}

.DropdownMenuAction {
  padding: 10px 18px;
  border-radius: 8px;
}

.DropdownMenuActionItem {
  padding: 10px 25px 10px 5px;
  color: var(--text-primary1);
  font-size: 13px;
  line-height: 20px;
  &:hover {
    background-color: transparent;
    cursor: pointer;
  }
}
.titleItemDropdownMenuAction {
  color: var(--status-error);
}
.TitlePreviewContainer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 294px;
  height: auto;
  pointer-events: none;
}
.TitleItem {
  position: relative;
}
.titleItemOnChangeLight {
  color: #1f1f1f;
}

.titleItemOnChangeDark {
  color: var(--text-primary1);
}
.DropdownMenuIcon {
  margin-right: 10px;
}

.BtnEdit {
  position: absolute;
  top: 50%;
  right: 10px;
  z-index: 11;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  padding: 0;
  border: none;
  border-radius: 16px;
  background: none;
  color: var(--text-primary1);
  box-shadow: none;
  transform: translateY(-50%);
  &:hover {
    background: var(--grey2);
    color: var(--text-primary1);
  }
  &:focus {
    background: var(--grey2);
  }
}
.ContentClick {
  position: absolute;
  inset: 0;
  z-index: 10;
  display: none;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: #fff;
  font-weight: 500;
}
.ContentClickOffAir {
  background: rgba(234, 67, 53, 0.95);
}
.ContentClickOnAir {
  background: rgba(38, 86, 201, 0.95);
}
