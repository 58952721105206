$font-size-body1: 13px;
$font-size-body2: 12px;
$font-size-body3: 10px;
$font-size-body4: 14px;
$font-size-h1: 44px;
$font-size-h2: 36px;
$font-size-h3: 24px;
$font-size-h4: 20px;
$font-size-h5: 16px;
$font-size-h6: 14px;
$text-primary: #2656c9;
$text-level1: #e1e1e9;
$text-level2: rgba(225, 225, 233, 0.75);
$text-level3: rgba(225, 225, 233, 0.5);
$text-level4: rgba(225, 225, 233, 0.25);
$text-light-700: #606060;
$text-body-600: #808080;
$text-link: #7589ff;
$status-error: #ff6565;
$status-warning: #ff915a;
$status-success: #34a853;
$background: transparent;
$background-image: url(./../../assets/images/bg_studio.jpg);
$background-level1: rgba(23, 23, 29, 0.85);
$background-level2: rgba(28, 28, 36, 1);
$background-level3: rgba(28, 28, 36, 1);
$background-level4: rgba(255, 255, 255, 0.02);
$background-popup: rgba(0, 4, 12, 0.9);
$background-popupMask: rgba(23, 23, 29, 0.8);
$background-darkerLevel1: rgba(0, 0, 0, 0.72);
$background-dropdown: rgba(23, 23, 29, 1);
$background-blur-level1: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.02),
    rgba(255, 255, 255, 0.02)
  ),
  rgba(28, 28, 36, 0.96);
$background-light-material-blue: #c9e6fd;
$card-level1: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.24),
    rgba(255, 255, 255, 0.24)
  ),
  rgba(23, 23, 29, 0.88);
$card-level2: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.12),
    rgba(255, 255, 255, 0.12)
  ),
  rgba(23, 23, 29, 0.88);
$level-lighter1: rgba(255, 255, 255, 0.24);
$level-lighter2: rgba(255, 255, 255, 0.16);
$level-lighter3: rgba(255, 255, 255, 0.08);
$level-lighter4: rgba(255, 255, 255, 0.04);
$level-dark1: rgba(0, 0, 0, 0.72);
$level-dark3: rgba(0, 0, 0, 0.32);
$level-dark4: rgba(0, 0, 0, 0.12);
$level-dark5: rgba(0, 0, 0, 0.08);
$level-dark6: rgba(0, 0, 0, 0.08);
$level-dark7: rgba(5, 6, 18, 0.32);
$basic-color-white: #ffffff;
$basic-color-gray1: rgba(255, 255, 255, 0.08);
$basic-color-gray2: rgba(225, 225, 233, 0.1);
$basic-color-gray3: rgba(225, 225, 233, 0.5);
$basic-color-gray4: rgba(225, 225, 233, 0.75);
$basic-color-gray5: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.1)
  ),
  rgba(255, 255, 255, 0.1);
$basic-color-gray6: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.1)
  ),
  rgba(23, 23, 29, 0.85);
$basic-color-gray7: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.1)
  ),
  rgba(23, 23, 29, 0.85);
$basic-color-gray8: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.1)
  ),
  rgb(23 23 29);
$basic-color-gray9: rgba(255, 255, 255, 0.2);
$basic-color-black: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.7)
  ),
  rgba(255, 255, 255, 0.1);
$basic-color-sidebar: rgba(23, 23, 29, 0.85);
$basic-color-background-main: rgba(23, 23, 29, 0.85);
$basic-color-danger1: rgba(255, 101, 101, 1);
$basic-color-danger2: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.3)
  ),
  #ff6565;
$basic-color-modal-background: #1c1d24;
$border1: 1px solid rgba(255, 255, 255, 0.16);
$border2: 1px solid rgba(255, 255, 255, 0.1);
$border3: 1px solid rgba(255, 255, 255, 0.25);
$border4: 1px solid rgba(255, 255, 255, 0.08);
$border5: 1px solid rgba(4, 16, 27, 0.12);
$light-material-blue: #0e4077;
$status-color-success: rgba(47, 216, 124, 1);
$status-color-warning: rgba(255, 145, 90, 1);
$status-color-danger: rgba(255, 101, 101, 1);
$status-color-danger1: #ff625b;

$text-white: #ffffff;
$icon-white: #ffffff;
$icon-dark: #0f0f0f;

$background-record: #ea4335;

$blue-pagination: #085ae1;
$dark-text-body700: rgba(225, 225, 233, 0.54);

$blue-clearsearch: #4098ff;
$text-grey: rgba(225, 225, 233, 0.54);
$background-dropdown-darker: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.1)
  ),
  rgba(23, 23, 29, 0.96);
$background-nav: rgba(8, 90, 225, 0.16);

$dark-green: #a8d486;
$text-grey2: rgba(225, 225, 233, 0.88);
$background-blur-level1: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.7)
  ),
  rgba(255, 255, 255, 0.08);
$background-calendar-dark: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.1)
  ),
  rgba(23, 23, 29, 0.85);
$background-dropdown-dark: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.1)
  ),
  rgba(23, 23, 29, 0.96);
$background-submenu-toggle-button: rgba(255, 255, 255, 0.08);
$button-ternary-hover: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.1)
  ),
  rgba(255, 255, 255, 0.08);
$backdrop-filter: blur(50px);

$border-input-error: 1px solid #ff625b;
$background-dark-disabled: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.05),
    rgba(255, 255, 255, 0.05)
  ),
  rgba(0, 0, 0, 0.2);

$background-buy-more-price-popover: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.16) 0%,
    rgba(255, 255, 255, 0.16) 100%
  ),
  #000;

$input-label: rgba(225, 225, 233, 0.4);

$background-light-button-ternary-hover: linear-gradient(
    0deg,
    rgba(8, 12, 49, 0.04) 0%,
    rgba(8, 12, 49, 0.04) 100%
  ),
  #f3f2f2;
$background-light-button-ternary: #f3f2f2;
$background-light-darker5: rgba(4, 16, 27, 0.04);
$border-light-darker4: rgba(4, 16, 27, 0.06);
