.LiveInfo {
  position: absolute;
  top: 10px;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 20px;
  margin-left: 20px;
  pointer-events: none;
}
.Status {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
}

.Count {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
  padding: 5px 0;
}
.CountText {
  margin-left: 5px;
  padding: 0px;
  font-weight: 500;
  font-size: 10px;
}
.Quality {
  display: flex;
  flex-direction: row;
  align-items: center;
  pointer-events: none;
}
.QualityText {
  margin-left: 10px;
  padding: 2px 5px;
  border: solid 2px #ffffff;
  border-radius: 5px;
  color: #ffffff;
}
