@import '../../../styles/commonStyles.scss';
.ComposerContainer {
  right: 0;
  bottom: 0px;
  left: 0;
  display: flex;
  flex-direction: column;
  padding: 12px 12px;
  border-top: 1px solid var(--border);
  border-bottom-left-radius: 8px;
}
.ComposerContainerInput {
  position: relative;
  display: flex;
  align-items: center;
}

.ComposerInputFile {
  display: none;
}
.ComposerInputContainer {
  flex: 1;
  width: 200px;
}
.ComposerContainerInputRight {
  display: flex;
  flex: 1;
  align-items: center;
  max-height: 100px;

  padding: 10px 12px;

  border-radius: 8px;
  color: var(--text-primary1);
}
.ComposerAttachmentIcon {
  position: absolute;
  right: 45px;
  z-index: 10;
  margin-top: 0;
  color: var(--text-secondary3);
  font-size: 18px;
  line-height: 1;
  cursor: pointer;
}
.ComposerSendIcon {
  color: var(--text-link);
  font-size: 18px;
  line-height: 1;
  &:hover {
    cursor: pointer;
  }
}
.ComposerAttachments {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -2px 8px;
}
.ComposerAttachmentItem {
  position: relative;
  display: flex;
  flex: 0 1 25%;
  justify-content: center;
  margin-bottom: 4px;
  padding: 0 2px;
  border-radius: 8px;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 2px;
    bottom: 0;
    left: 2px;
    display: none;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.8);
  }
  &:hover {
    .ComposerDeleteAttachment {
      display: flex;
    }
  }
}
.composerAttachmentItemLoading {
  &::before {
    display: block;
  }
}
.AttachmentTxtError {
  margin-bottom: 12px;
  color: var(--status-error);
}
.ComposerAttachmentImg {
  object-fit: cover;
  width: 100%;
  height: 71px;
  border-radius: 8px;
}
@keyframes keyframeLoading {
  0% {
    transform: rotate(0);
  }
  10% {
    transform: rotate(0.1turn);
  }
  20% {
    transform: rotate(0.2turn);
  }
  30% {
    transform: rotate(0.3turn);
  }
  40% {
    transform: rotate(0.4turn);
  }
  50% {
    transform: rotate(0.5turn);
  }
  60% {
    transform: rotate(0.6turn);
  }
  70% {
    transform: rotate(0.7turn);
  }
  80% {
    transform: rotate(0.8turn);
  }
  90% {
    transform: rotate(0.9turn);
  }
  100% {
    transform: rotate(1turn);
  }
}
.ComposerLoadingAttachment {
  position: absolute;
  align-self: center;
  width: 16px;
  height: 16px;
  animation: keyframeLoading 1.2s infinite;
}
.ComposerRetry {
  position: absolute;
  align-self: center;
  color: var(--text-link);
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
}
.ComposerErrorAttachment {
  position: absolute;
  top: 5px;
  left: 5px;
}
.ComposerDeleteAttachment {
  position: absolute;
  top: 2px;
  right: 4px;
  display: none;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--grey11) !important;
  color: var(--text-primary1);
  cursor: pointer;
}
.ComposerAttachmentFile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 71px;
  border-radius: 8px;
  background: var(--background21);
  color: $text-level3;
}
.ComposerAttachmentFileExt {
  color: $text-level3;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  text-transform: uppercase;
}
.WrapperModalOutFile {
  :global(.ant-modal-content) {
    background-color: transparent;
  }
}
.ModalContainer {
  background: var(--background13);
}
.ModalOutContent {
  padding: 32px 16px;
  border-radius: 8px;
  background: var(--background-modal);
  color: var(--text-primary1);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.4);
}
.ModalOutClose {
  color: var(--icon-color6);
}
.ModalOutTitle {
  margin: 8px 0 16px;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
}
.ModalOutText {
  margin-bottom: 32px;
  color: var(--text-secondary1);
  font-size: 13px;
  line-height: 150%;
  text-align: center;
  strong {
    color: var(--text-primary1);
  }
}
.ModalOutBtn {
  display: block;
  width: calc(100% - 60px);
  margin: 0 auto;
  border-radius: 24px;
  composes: ButtonPrimary from '../../../styles/styleForm.module.scss';
}
