@import '../../../styles/commonStyles.scss';
.FormItemStyled {
  margin-bottom: 0px !important;
  :global(.ant-form-item-label) {
    /* display: flex; */
    max-width: 100% !important;
    label {
      width: 100%;
      color: var(--text-primary1);
      font-size: $font-size-body1;
    }
  }
  :global(.ant-form-item-explain-error) {
    color: var(--status-error2) !important;
    font-size: $font-size-body1;
  }
  :global(.ant-form-item-explain > div) {
    margin: 16px 0;
  }
  :global(.ant-form-item-explain > div:empty) {
    margin: 0 !important;
  }
}
