@import '../../../styles/commonStyles.scss';

.SeekBarSliderContainer {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}
.SeekBarSlider {
  width: 100%;
  height: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-left: 125px;
}

.SeekBarSliderCursorMode {
  height: 12px;
  margin-right: 24px;
  margin-left: 24px;
}
.FakeTrack {
  position: absolute;
  top: 14px;
  left: 0;
  width: 125px;
  height: 4px;
  border-radius: 2px;
  background-color: #f5f5f5;
  transition: background-color 0.3s;
}
