@import '../../../styles/commonStyles.scss';
.MediaPlaylistItem {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 8px;
  background: var(--grey8);
  composes: MediaItemVerticalContainer from './MediaCommon.module.scss';
  &::after {
    z-index: -1;
  }
}
.MediaPlaylistItem:hover {
  .MediaPlaylistIconPopover {
    display: flex;
  }
}
.mediaPlaylistItemDropbottom {
  &::before {
    top: calc(100% + 3px);
  }
}

.MediaPlaylistNote {
  composes: MediaItemNote from './MediaCommon.module.scss';
}
.MediaPlaylistContainer {
  width: 100%;
  padding: 0 4px;
}
.MediaPlaylistIconPopover {
  position: absolute;
  top: 50%;
  right: 8px;
  display: none;
  align-items: center;
  width: 24px;
  height: 24px;
  padding: 4px;
  border-radius: 16px;
  background: var(--grey19);
  color: var(--text-primary1);
  font-size: 4px;
  transform: translateY(-50%);

  &:hover {
    background: var(--background-hover-dark);
  }
  &:active {
    background: var(--background-active-dark);
  }
}

.mediaPlaylistIconPopoverSelected {
  background: var(--background-active-dark);
}
.mediaPlaylistIconPopoverSelected:hover {
  background: var(--background-hover-dark);
}

.mediaPlaylistItemOnAir {
  border: 1px solid $status-error;
}
.MediaPlaylistDragging {
  border-radius: 8px;
  background: var(--grey14) !important;
  composes: MediaItemDragging from './MediaCommon.module.scss';
}
.mediaItemVerticalContainerDragSelected {
  composes: mediaItemVerticalContainerDragSelected from './MediaCommon.module.scss';
}
.mediaItemVerticalContainerDrophover {
  composes: mediaItemVerticalContainerDrophover from './MediaCommon.module.scss';
}
.MediaPlayItemWrapperClick {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.MediaPlaylistItemLeft {
  position: relative;
  width: 48px;
  height: 48px;
}
.MediaPlaylistImage {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 6px;
}
.MediaPlaylistIconGIF {
  position: absolute;
  top: 6px;
  right: 2px;
}
.MediaPlaylistIconPlay {
  position: absolute;
  top: 3px;
  right: 3px;
}
.MediaPlaylistIconAudio {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: var(--text-primary1);
}
.MediaPlaylistTagVideo {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 6px;
}
.MediaPlaylistItemRight {
  width: calc(100% - 48px);
  padding-left: 8px;
  color: var(--text-primary1);
  font-size: 13px;
  line-height: 150%;
}
.MediaPlayListItemInfo {
  display: flex;
  align-items: center;
  margin-top: 2px;
}
.MediaPlaylistItemDuration {
  color: var(--text-secondary);
}
.MediaPlaylistDDContainer {
  background: var(--background13);
  color: var(--text-primary1);
}
.MediaPlaylistDDContent {
  padding: 16px 8px;
  border-radius: 8px;
  background: var(--background13);
  box-shadow: var(--box-shadow-preview);
}
.MediaPlaylistDDItem {
  position: relative;
  margin-bottom: 16px;
  padding: 0 8px 16px 34px;
  cursor: pointer;
}
.MediaPlaylistDDIcon {
  position: absolute;
  top: 12px;
  left: 7px;
}
.MediaPlaylistDDItemClone {
  composes: MediaPlaylistDDItem;
}
.MediaPlaylistDLItemClone {
  border-bottom: 1px solid $level-lighter3;
  composes: MediaPlaylistDDItem;
  .MediaPlaylistDDIcon {
    top: 3px;
  }
}
.MediaPlaylistDDItemRemove {
  margin-bottom: 0;
  padding-bottom: 0;
  composes: MediaPlaylistDDItem;
}
.MediaPlaylistDDName {
  margin-bottom: 2px;
  line-height: 150%;
}
.MediaPlaylistDDItemRemoveAll {
  composes: MediaPlaylistDDItem;
}
.MediaPlaylistDDSub {
  color: var(--text-secondary);
  font-size: 12px;
}
.MediaPlaylistImgButtonOnAir {
  display: inline-block;
  margin-top: 2px;
  composes: MediaButtonOnAir from './MediaCommon.module.scss';
  &::before {
    display: none;
  }
}

.MediaPlaylistPreview {
  width: 294px;
  composes: MediaItemPreview from './MediaCommon.module.scss';
}
.MediaPlaylistVideoButtonOnAir {
  margin-right: 16px;
  composes: MediaButtonOnAir from './MediaCommon.module.scss';
}
.MediaPreviewTotalItem {
  composes: MediaPreviewTotalItem from './MediaCommon.module.scss';
}
