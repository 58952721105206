@import '../../../styles/commonStyles.scss';
.SeekBarFooter {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  padding: 0px 24px;
  color: var(--text-primary1);
}
.VideoTimerWrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
}
.volumeIcon {
  cursor: pointer;
  composes: IconFont from '../../../styles/iconFont.module.scss';
  &::before {
    content: '\e931';
  }
}
.muteIcon {
  &::before {
    content: '\e933';
  }
}
.videoTimer {
  color: var(--text-primary1);
  font-weight: 500;
  font-size: 13px;
}

.PlayIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 20px;
  cursor: pointer;
  transform: translate(-50%, -50%);
  composes: IconFont from '../../../styles/iconFont.module.scss';
  &::before {
    content: '\e9b5';
  }
}

.PauseIcon {
  &::before {
    content: '\e92e';
  }
}

.ActionWrapper {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.IconDefault {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
}

.loadingIcon {
  @extend .IconDefault;
}
.downloadIcon {
  @extend .IconDefault;
  font-size: 16px;
  cursor: pointer;
  composes: IconFont from '../../../styles/iconFont.module.scss';
  &::before {
    content: '\e9b1';
  }
}

.iconDisabled {
  opacity: 0.7;
  cursor: not-allowed;
}
