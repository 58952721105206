@import '../../../../styles/commonStyles.scss';

.MenuWrapper {
  width: 200px;
  border-radius: 8px;
  background: var(--background13);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.4);
}
.MenuContainer {
  padding: 16px 0px;
  background: var(--background-modal);
}
.MenuText {
  color: var(--text-primary1);
}
.ColorRed {
  color: var(--status-error);
}
.MenuItem {
  margin-bottom: 8px;
}

.AddRecent {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  padding: 8px, 16px, 24px, 16px;
  border: 1px dashed var(--text-link);
  border-radius: 8px;
  cursor: pointer;
  img {
    width: 14px;
    height: 14px;
  }
}
.AddRecent:hover {
  background: var(--background25);
}
.AddRecent:active {
  background: var(--background24);
}
.IconPlus {
  color: var(--text-link);
}
.IconStop {
  fill: var(--status-error) !important;
}
.SelectDestinationPopoverWrapper {
  :global(.ant-popover-inner) {
    box-shadow: none;
  }
}
