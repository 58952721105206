.UserRole {
  color: var(--text-secondary3);
  font-size: 13px;
}
.IconDropdown {
  color: var(--text-secondary3);
}

.UserRoleButton {
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  border-radius: 32px;
  background-color: transparent;
  cursor: pointer;
  &:active {
    border-radius: 32px;
    background: var(--background6);
  }
}

.Dropdown {
  :global(.ant-dropdown-menu) {
    padding: 0;
    border-radius: 8px;
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.1),
        rgba(255, 255, 255, 0.1)
      ),
      rgba(23, 23, 29, 0.96);

    box-shadow: 0px 0px 8px rgba(1, 2, 11, 0.56);
    backdrop-filter: blur(60px);
  }
}

.ActionMenu {
  padding: 8px;
  background-color: var(--background-modal);
  :global(.ant-dropdown-menu-item) {
    padding: 8px;
    border-radius: 4px;
    :global(.ant-dropdown-menu-title-content) {
      display: flex;
      gap: 8px;
      align-items: center;
    }
  }
}

.ActionMenuItem {
  color: var(--text-primary) !important;
}

.ActionRemove {
  color: var(--status-error2);
}
