@import '../../../styles/commonStyles.scss';
.BtnUpload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  :global(.display-none) {
    display: none;
  }
}

.IconLoading {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0;
  background-color: rgba(255, 255, 255, 0.05);
}
.UploadIcon {
  color: var(--grey);
}
