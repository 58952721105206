.ContainerBackgroundDefault {
  position: absolute;
  inset: 0px;
  z-index: 0;
}
.ContainerBackgroundColor {
  composes: ContainerBackgroundDefault;
}
.ContainerBackgroundImage {
  background-image: none;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  composes: ContainerBackgroundDefault;
}
