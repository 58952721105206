.TextContainer {
  :global(.ant-typography-success) {
    color: var(--clTypographySuccess) !important;
  }
  :global(.ant-typography-warning) {
    color: var(--status-warning) !important;
  }

  :global(.ant-typography-danger) {
    color: var(--clTypographyDanger) !important;
  }
  :global(.ant-typography-secondary) {
    color: var(--clTypographySecondary) !important;
  }
}
.TextStyled {
  color: var(--text-primary1);
  font-size: var(--fTextStyled);

  br {
    content: '';
    display: block;
    margin-bottom: 8px;
  }
}
