@import '../../../styles/commonStyles.scss';
.LiveStreamWrapper {
  position: relative;
  inset: unset;
  margin: 0 auto;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 200;
    display: none;
    border: 4px solid transparent;
    border-radius: 8px;
    background-color: transparent;
  }
}
.liveStreamMaxWidthFullScreen {
  max-width: 100%;
}
.liveStreamFullScreen {
  position: relative;
  inset: unset;

  display: flex;
  justify-content: center;
  align-items: center;
}
.liveStreamFullScreenHoverDrop {
  &::after {
    display: block;
    border-color: var(--text-link);
    background-color: rgba(117, 137, 255, 0.6);
  }
}
.liveStreamParticipant {
  pointer-events: none;
}
.LiveStreamOverlayMulti {
  position: absolute;
  inset: 0;
  z-index: 600;
  &::after {
    content: '';
    position: absolute;
    inset: 0;
    display: none;
    border: 4px solid $status-error;
    border-color: $status-error;
    border-radius: 8px;
    background-color: rgba(255, 101, 101, 0.6);
  }
}
.liveStreamOverlayMultiOver {
  display: block;
}
.LiveStreamWrapperDrop {
  position: absolute;
  inset: 0;
  z-index: 999999;
}
.LiveStreamContainer {
  position: relative;
  box-shadow: var(--box-shadow-live);
}
.ItemIconDelete {
  position: absolute;
  top: -12px;
  right: -12px;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--background);
  color: var(--icon-color1);
  text-align: center;
  cursor: pointer;
}
.showDeleteBackground {
  position: absolute;
  inset: -1px;
  z-index: 999;
  border: 4px solid $status-success;
  cursor: pointer;
}
.containerRoundedTheme {
  border-radius: 12px;
}
.containerRoundedThemeHidden {
  overflow: hidden;
}

.liveStreamContainerNotProducing {
  border-radius: 0;
}

.LiveStreamNotAllowDropTxt {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 300;
  padding: 12px;
  border-radius: 8px;
  background: var(--background);
  color: $text-level1;
  transform: translate(-50%, -50%);
}
