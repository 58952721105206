@import '../../../styles/commonStyles.scss';

.TextAreaContainer {
  border: 1px solid var(--border2);
  border-radius: 8px;
  background: var(--background2) !important;
  color: $text-level1;
  resize: none;
  &:focus {
    border: 1px solid $text-primary;
    box-shadow: 0px 0px 4px $text-primary;
  }
  &:hover {
    border: 1px solid $text-primary;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $text-level3;
    font-size: $font-size-body1 !important;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $text-level3;
    font-size: $font-size-body1;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $text-level3;
    font-size: $font-size-body1;
  }
}
